import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import { useHistory } from "react-router-dom";
import CryptoNOT from "../cryptoNOT/index";
import firebase from "../../firebase";
import axios from "axios";
import UrlConfig from "../../utils/ApiConfig";
import { ethers } from "ethers";
import { MainContext } from "../../context";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import { Translate } from "react-auto-translate";
import confirm from "../../assets/animation/confirm.json";
import blockc from "../../assets/animation/block.json";
import MinimumBet from '../table-poker/minbet';
import { cookieSetter } from '../../dbFetchFunctions';

const confirmLottie = {
  loop: true,
  autoplay: true,
  animationData: confirm,
};

const blockLottie = {
  loop: true,
  autoplay: true,
  animationData: blockc,
};


    const Join = (props) => {
        const { token, firebaseUser: user, baseStats } = useContext(MainContext);
        const { uid } = user || {}
        const [show, setShow] = useState(true);
        const [renderSignout, setrenderSignout] = useState(false);
        const history = useHistory();
        const { account, provider } = useWeb3React();
        const [loading, setloading] = useState(false);
        const [confirmationText, setconfirmationText] = useState('Please Confirm Transaction');
        const [idToken, setidToken] = useState(localStorage.getItem("idtoken"));
        const [balance, setBalance] = useState('');
        const [buyIn, setBuyIn] = useState(Number(props.buyin));
        useEffect(() => {
        const getBalance = async() => {
          if(account){
            const value = await provider?.getBalance(account);
              if(value)
              setBalance(Number(ethers.utils.formatEther(value)).toFixed(5));
              localStorage.setItem('isWalletConnected', true);
              } else {
              localStorage.setItem('isWalletConnected', false);
            }
        }
        getBalance()
      }, [account, provider])

      useEffect(() => {
        const getIdToken = async () => {
          const token = await firebase.auth().currentUser.getIdToken();
          setidToken(token);
        };
        getIdToken();
      }, []);

        function validate() {
          if (balance < buyIn) {
            toast.error(
              <div>
                <Translate>You are missing</Translate>{" "}
                <b>{(buyIn - balance).toFixed(2)} Matic</b>{" "}
                <Translate>to join</Translate>
              </div>,
              {
                duration: 4000,
                style: {
                  maxWidth: 800,
                  fontSize: "14px",
                  borderRadius: "15px",
                },
              }
            );
            return false;
          } else {
            return true;
          }
        };

        async function HandleJoinTable(e) {
            if (e && e.preventDefault) {
              e.preventDefault();
            }  
            const isValid = validate();
            if (isValid) {
              let d = props.link.split("=");
              let tabId = d[1].split("&")[0];
              let gameColl = d[2];
              setloading(true);
              const res = await axios.post(`${UrlConfig.setInGameAPI}`,
              {
                gameColl,
                ballance: 1000000000.1,
                userCurrency: 'Matic',
                tableCurrency: 'Matic',
                buyIn: 0, // float or tournament for ,
                betMin: props.minBet,
                wid: account
              },
              {
              headers: { 
                device: localStorage.getItem("deviceId"),
                tabId: tabId,
                wid: account
             },
             withCredentials: true,
              credentials: "include",
            });
                if (res.data.success) {
                  const rec = await joinGame(tabId, props.link);
                  if(rec){
                  window.location.href = props.link;
                  }else{
                    await axios.get(UrlConfig.resetSessionAPI, {
                      headers:{
                        uid: uid || user.uid
                      }
                    })
                  }
              }
              if (res.data.error === "table not exists") {
                window.location.reload()
              }
               if (res.data.error === "User is already lobby") {
                let pokerTableLink = res.data.link;
                window.location.href = pokerTableLink;
              } if (res.data.error === "cookie veri failed" ||
                   res.data.error === "wallet mismatch" || 
                   res.data.error === "env mismatch" ||
                   res.data.error === "user logged in other device" ) {
                  toast.error(<span><Translate>No active session found, you will be automatically signed out</Translate></span>,{
                      style: {
                          maxWidth: 800,
                          fontSize: "14px",
                          borderRadius: "15px",
                      },
                  });
                  firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    renderSignout(true);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                axios
                  .get(
                    UrlConfig.cookieSetterAPI,
                    {
                      params: {
                        isLogout: "yes",
                        uid: uid || user.uid,
                      },
                      headers: {
                        idtoken: localStorage.getItem("idtoken") || token,
                        wid: localStorage.getItem("walletid"),
                      },
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.error === "no error") {
                      firebase
                        .auth()
                        .signOut()
                        .then(() => {
                          setrenderSignout(true);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                  })
                  .catch((error) => {
                    console.log("Error req", error);
                  });
                  localStorage.removeItem("idtoken");
                  localStorage.removeItem("walletid");
                  localStorage.removeItem("provider");
                  history.push("/");
                }
                if (res.data.error == "missing auth token") {
                  
                  await cookieSetter(account , HandleJoinTable, setidToken);
                  console.log("missing auth token")
                  } else if(res.data.error !== "no error") {
                    props.setshowTable(false);
                    setloading(false);
                    toast.error(<Translate>{res.data.error}</Translate>, {
                      style: {
                          maxWidth: 800,
                          fontSize: "14px",
                          borderRadius: "15px",
                      },
                    });
                  }          
            }
        }


        const joinGame = async (gameId, link) => {
            let tx;
            try {
              if (props.contract) {
                const depositAmountInMatic = buyIn;
                const valueToSend = ethers.utils.parseUnits(depositAmountInMatic.toString(), 18);
                // Check if depositAmount >= minBet before sending the transaction
                const game = await props.contract.getGameById(gameId);
                if (!game.gameId) {
                  toast.error(<span><Translate>Game not found</Translate></span>, {
                    duration: 4000,
                    style: {
                        maxWidth: 800,
                        fontSize: "14px",
                        borderRadius: "15px",
                      },
                    });
                  return;
                }
                if (valueToSend.lt(game.minBet)) {
                  console.error("Insufficient balance. Deposit amount is less than minBet.");
                  toast.error(<span><Translate>Insufficient funds: the deposit amount is below the minimum bet</Translate></span>, {
                    duration: 4000,
                    style: {
                        maxWidth: 800,
                        fontSize: "14px",
                        borderRadius: "15px",
                      },
                    });
                    setloading(false);
                    props.setshowTable(false);
                  return;
                }
                // const { data: { result: { FastGasPrice } = {}} = {}} = await axios.get(`${UrlConfig.gasTrackerUrl}${process.env.REACT_APP_API_TOKEN}`);
                const estimatedGasLimit = await props.contract.estimateGas.joinGame(gameId, [uid, valueToSend, account, user.photoURL, user.displayName], {
                  value: valueToSend,
                  gasPrice: await provider.getGasPrice() || ethers.utils.parseUnits('35', 'gwei'),
                  nonce: await provider.getTransactionCount(account),
                });
               console.log("limit", Math.floor(Number(estimatedGasLimit) * 1.2));
                tx = await props.contract.joinGame(gameId, [uid, valueToSend, account, user.photoURL, user.displayName], {
                  value: valueToSend,
                  gasPrice: await provider.getGasPrice() || ethers.utils.parseUnits('35', 'gwei'),
                  nonce: await provider.getTransactionCount(account),
                  gasLimit: ethers.BigNumber.from(Math.floor(Number(estimatedGasLimit) * 1.2)),
                });
                setconfirmationText('Waiting For Block Confirmation');
                const receipt = await tx.wait();
               return receipt;
              }
            } catch (e) {
              if (e.message.includes("execution reverted")) {
                const revertReason = await props.contract.provider.getTransactionReceipt(tx.hash)
                  .then((receipt) => receipt ? receipt.reason : "No revert reason");
                  toast.error(
                    <span>
                      <Translate>Reason: </Translate> <Translate>{revertReason}</Translate>
                    </span>,
                    {
                      duration: 4000,
                      id: "toast-error",
                      style: {
                        maxWidth: 800,
                        fontSize: "14px",
                        borderRadius: "15px",
                      },
                    }
                  );
                  setloading(false);
                  props.setshowTable(false);
                return false;
              } else {
                if(e.message.includes("transaction was replaced") || e.message.includes("Player already joined")){
                  window.location.href = link;
                  return;
                }
                if (e.message.includes("insufficient funds")) {
                  toast.error(<span><Translate>You might not have enough funds to play</Translate></span>, {
                    duration: 4000,
                    style: {
                        maxWidth: 800,
                        fontSize: "14px",
                        borderRadius: "15px",
                      },
                    }); 
                    setShow(false);
                  }
                  if (e.message.includes("transaction failed")) {
                    toast.error(<span><Translate>Transaction error: failed</Translate></span>, {
                      duration: 4000,
                      style: {
                          maxWidth: 800,
                          fontSize: "14px",
                          borderRadius: "15px",
                        },
                      });
                      setShow(false);
                  }
                  if (e.message.includes("user rejected transaction")) {
                      setShow(false);
                  }
                  if (e.message.includes("Internal JSON-RPC error")) {
                    toast.error(<span><Translate>You might not have enough funds to play</Translate></span>, {
                      duration: 4000,
                      style: {
                          maxWidth: 800,
                          fontSize: "14px",
                          borderRadius: "15px",
                        },
                      });
                    setShow(false);
                }
                  props.setshowTable(false);
                  setloading(false);
                return false;
              }
            }
        };
        function handleShow() {
            setShow(!show);
        }

        
    return (
        <>
        {!props.walletConnected ? (
            <CryptoNOT />
            ) : (
            <>
                <Modal
                    show={show}
                    onHide={handleShow}
                    centered
                    className="friends-popup join-popup" >
                    <Modal.Header closeButton>
                    <Modal.Title>{props.game.toUpperCase()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form onSubmit={HandleJoinTable}>
                        <div className="join-block">
                        <Form.Label>
                            <img src={props.photo} alt="sdfs" className="join-img-popup" />
                        </Form.Label>
                        <div className="join-text">
                            <div>
                                <Form.Label>
                                    <span className="join-span">Small blind :</span> {props.minBet}
                                </Form.Label>
                            </div>
                            <div>
                                <Form.Label>
                                    <span className="join-span"><Translate>Total players :</Translate></span> {props.length}
                                </Form.Label>
                            </div>
                        </div>
                        <div className="join-buyin">
                        <Form.Label>
                        <MinimumBet 
                            smallBlind={props.minBet} 
                            setSmallBlind={() => {}} 
                            buyIn={buyIn} 
                            setbuyIn={setBuyIn}  
                            isShowSmallBlind={false}
                            game={props.gameType} />
                        </Form.Label>
                        </div>
                        <Button type="submit" className="btn-gold btn-join-popup">
                            <Translate>Join</Translate>
                        </Button>
                        </div>
                    </Form>
                    </Modal.Body>
                </Modal>

                {loading ? (
                    <>
                        <Modal
                            show={true}
                            centered
                            className="friends-popup pending-popup">
                            <Modal.Header >
                              <Modal.Title>
                              <Spinner animation="border" />
                              <small className='title-pending'>
                                {confirmationText === "Waiting For Block Confirmation" ? (
                                  <span style={{ color: '#8461dc' }}>
                                    <Translate>{confirmationText}</Translate>
                                  </span>
                                ) : (
                                  <Translate>{confirmationText}</Translate>
                                )}
                              </small>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="feed-body">
                                { confirmationText === "Please Confirm Transaction" ? (
                                  <Lottie options={confirmLottie} width={250} height={250} />
                                ) : <Lottie options={blockLottie} width={300} height={250} /> }
                                </div>
                            </Modal.Body>
                        </Modal>
                    </>
                    ) : null}
                </>
            )}
        </>
      );
    };

export default Join;