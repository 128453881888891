import React from "react";
import { Web3ReactProvider } from '@web3-react/core';
import { hooks as metaMaskHooks, metaMask } from './metamask';
import { coinbaseWallet, hooks as coinbaseWalletHooks } from './coinbaseWallet'

const connectors= [
  [metaMask, metaMaskHooks],
  [coinbaseWallet, coinbaseWalletHooks],
]

function Web3Provider({children}) {
  return (
    <Web3ReactProvider connectors={connectors}>
      {children}
    </Web3ReactProvider>
  );
}

export default Web3Provider;