import React from "react";
import loadericon from "../../assets/animation/main_loader.json";
import Lottie from "react-lottie";
import './loader.css';

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loadericon,
  };
  
const Loader = () => {
    return (
        <div className="loader">
            <div className="loader-box">
                <Lottie options={loadingLottie} height={300} width={300} />
            </div>
        </div>
    );
};

export default Loader;