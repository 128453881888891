import React, { useContext, useEffect, useMemo, useState } from "react";
import Layout from "../layout/layout";
import { useWeb3React } from "@web3-react/core";
import { Form, Modal, Button, Spinner, Accordion, Tab, Row, Col, Nav, InputGroup, Overlay, Tooltip } from "react-bootstrap";
import Share from "./share"
import { isMobile } from "react-device-detect";
import CryptoNOT from "../cryptoNOT/index";
import Lottie from "react-lottie";
import walletIcon from '../../assets/images/header/coin.png';
import { ethers } from "ethers";
import toast from 'react-hot-toast';
import './join-bet.css';
import { Link, useParams } from "react-router-dom";
import SportsAbi from "../../ABI/SportsAbi.json";
import confirm from "../../assets/animation/confirm.json";
import blockc from "../../assets/animation/block.json";
import { simplifyTicket } from "../../utils";
import Loader from "../loader/loader";
import { Translate } from "react-auto-translate";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copyicon from "../../assets/images/profile/copyicon.svg";
import { MainContext } from "../../context";
import axios from "axios";
import UrlConfig from "../../utils/ApiConfig";

const confirmLottie = {
  loop: true,
  autoplay: true,
  animationData: confirm,
}

const blockLottie = {
  loop: true,
  autoplay: true,
  animationData: blockc,
};

const BetDetails = () => {
  const [walletConnected, setWalletConnected] = useState(true);
  const { firebaseUser } = useContext(MainContext);
  const [balance, setBalance] = useState('');
  const { account, provider } = useWeb3React();
  const { betId } = useParams();
  const [contract, setContract] = useState();
  const [ticket, setTicket] = useState();
  const [loading, setLoading] = useState(true);
  const [ticketError, setTicketError] = useState("");
  const [selections, setSelections] = useState([]);
  const [confirmationText, setconfirmationText] = useState("Please confirm the transaction");
  const [ConfirmationShow, setConfirmationShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [shareTicket, setShareTicket] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  


  const {userSelection, isJoined} = useMemo(() => {
    if (ticket) {
      const selection = ticket.users.find(user => user.userId === firebaseUser.uid)?.selections || []
      return  {
        userSelection: selection,
        isJoined: !!selection.length
      };
    }
    return {
      userSelection: [],
      isJoined: false
    };
  }, [ticket]);

  const winners = useMemo(()=>{
    if(ticket && ticket.status === 2){
      const userWins = {};

      ticket.users.forEach(user => {
          const uid = user.userId;
          let correctSelections = 0;
  
          user.selections.forEach(selection => {
              const result = ticket.results.find(r => r.gameId === selection.gameId);
              if (result && result.choice === selection.choice) {
                  correctSelections++;
              }
          });
  
          userWins[uid] = correctSelections;
      });
  
      // Step 2: Find the maximum number of correct selections
      const maxWins = Math.max(...Object.values(userWins));
  
      console.log("maxWins", maxWins, userWins);
  
      // Step 3: Find all users who have the maximum number of correct selections
      const winners = Object.keys(userWins).filter(uid => userWins[uid] === maxWins);
      return winners || [];
    }
    return [];
  },[ticket])

  console.log("winners", winners);

  useEffect(() => {
    const getBalance = async () => {
      if (account) {
        try {
          const value = await provider?.getBalance(account);
          if (value) {
            const formattedBalance = Number(ethers.utils.formatEther(value)).toFixed(5);
            setBalance(formattedBalance);
            setWalletConnected(true);
          }
        } catch (error) {
          console.error('Error fetching balance:', error);
          setWalletConnected(false);
        }
      } else {
        setWalletConnected(false);
      }
    };
    getBalance();

    if (account && provider) {
      const contract = new ethers.Contract(process.env.REACT_APP_SPORTS_CONTRACT_ADDRESS, SportsAbi, provider.getSigner());
      setContract(contract);
      fetchTicket(contract);
    }
  }, [account, provider]);

  useEffect(() => {
    // Get the current URL path and extract the last part
    const pathParts = window.location.pathname.split("/");
    const ticket = pathParts[pathParts.length - 1];

    if (ticket) {
      setShareTicket(ticket);
    }
  }, []); 


  const handleCopy = () => {
    setShowCopyTooltip(true);
    setTimeout(() => setShowCopyTooltip(false), 2000); // Hide tooltip after 2 seconds
    toast.success(<span><Translate>Copied</Translate></span>, {
      icon: "✔️",
      position: "top-right",
      style: {
        maxWidth: 800,
        fontSize: "14px",
        borderRadius: "15px",
      },
    });
  };

  const handleCloseShare = () => {
    setIsModalOpen(false);
  };

  const fetchTicket = async (contract) => {
    if (contract) {
      try {
        setLoading(true);
        const ticket = await contract.getTicketByUserId(betId);
        setTicket(simplifyTicket(ticket));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setTicketError("Ticket not found, You will be redirected back in 3 seconds..");
        setTimeout(() => {
          window.location.href = '/sports-tickets';
        }, 3000);;
      }
    }
  }

  const validate = (formBetAmount) => {
    if (Number(balance) < formBetAmount) {
      const deficit = (formBetAmount - Number(balance)).toFixed(2);
      toast.error(
        <span>
          <Translate>
          You are missing <b>{deficit} POL</b> to join the bet
        </Translate>
        </span>, 
        {
          duration: 4000,
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        }
      );      
      return false;
    }
    if (selections.length !== ticket?.matches?.length) {
      toast.error(
        <span><Translate>Please select all games</Translate></span>, {
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      }
      );
      return false;
    }
    if (!firebaseUser.uid || !firebaseUser.displayName) {
      toast.error(
        <span><Translate>User not found error</Translate></span>, {
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      }
      );
      return false;
    }
    return true;
  };

  const resetSelection = (homeElement, tieElement, awayElement) => {
    homeElement.classList.remove("selected");
    awayElement.classList.remove("selected");
    if (tieElement) {
      tieElement.classList.remove("selected");
    }
  };

  const select = (event, game, choice) => {
    if (ticket.status == 0) {
      console.log("ticket.status::::" + ticket.status);
      if (userSelection.length) return;
      const { gameId, isDrawable } = game;
      const homeElement = document.querySelector(`.game-${gameId}home`);
      const awayElement = document.querySelector(`.game-${gameId}away`);
      let tieElement;
      if (isDrawable) {
        tieElement = document.querySelector(`.game-${gameId}tie`);
      }
      resetSelection(homeElement, tieElement, awayElement);
      event.target.classList.add("selected");
      const index = selections.findIndex((game) => game.gameId === gameId);
      const updatedMySelection = [...selections];
      if (index !== -1) {
        updatedMySelection[index].choice = choice;
      } else {
        updatedMySelection.push({
          gameId,
          choice,
        });
      }
      setSelections(updatedMySelection);
    }
    mobileVisibility();
  };

  const mobileVisibility = () => {
    if (isMobile) {
      if (selections.length == 0 && isVisible) {
        toggleVisibility();
      } else if (selections.length == ticket?.matches?.length -1 && !isVisible) {
        toggleVisibility();
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate(ticket.amount);
    if (isValid) {
      setIsLoading(true);
      setconfirmationText('Please Confirm Transaction');
      let selection = selections.map((game) => [game.gameId, game.choice]);
      console.log("user", firebaseUser.displayName)
      const user = [firebaseUser.uid, firebaseUser.displayName, firebaseUser.photoURL, account];
      const receipt = await joinTicket(selection, user);
      if (receipt) {
        const shortenedBetId = betId.toString().slice(-5);
        toast.success(<span><Translate>You have joined with ticket number: </Translate>
         {shortenedBetId}
          </span>, {
          duration: 4000,
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        });
        setTimeout(() => {
          window.location.href = `/sports-tickets?ticket=${betId}`;
        }, 3000);
      }
    }
  };

  const joinTicket = async (selections, user) => {
    let tx;
    console.log("calling create tickegt")
    try {
      if (contract) {
        const depositAmountInMatic = ticket.amount;
        const valueToSend = ethers.utils.parseUnits(depositAmountInMatic.toString(), 18);
        console.log("checking call")
        // const { data: { result: { FastGasPrice } = {} } = {} } = await axios.get(`${UrlConfig.gasTrackerUrl}${process.env.REACT_APP_API_TOKEN}`);
        // estimeate gasPrice using estimateGas functions
        const estimatedGasLimit = await contract.estimateGas.joinBet(
          betId, selections, user, {
          value: valueToSend,
          gasPrice: await provider.getGasPrice() || ethers.utils.parseUnits('35', 'gwei'),
          nonce: await provider.getTransactionCount(account),
        });
        console.log("limit", ethers.BigNumber.from(Math.floor(Number(estimatedGasLimit) * 1.2)), Math.floor(Number(estimatedGasLimit) * 1.2));
        tx = await contract.joinBet(
          betId, selections, user, {
          value: valueToSend,
          gasPrice:await provider.getGasPrice() || ethers.utils.parseUnits('35', 'gwei'),
          nonce: await provider.getTransactionCount(account),
          gasLimit: ethers.BigNumber.from(Math.floor(Number(estimatedGasLimit) * 1.2)),
        });
        setconfirmationText('Waiting For Block Confirmation');
        const receipt = await tx.wait();
        return receipt;
      }
    } catch (e) {
      console.log("error in createTicket", e);
      if (e.message.includes("execution reverted")) {
        const revertReason = await contract.provider.getTransactionReceipt(tx.hash)
          .then((receipt) => receipt ? receipt.reason : "No revert reason");
          toast.error(
            <span>
              <Translate>Reason: </Translate> <Translate>{revertReason}</Translate>
            </span>,
            {
              duration: 4000,
              id: "toast-error",
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            }
          );
        setConfirmationShow(true);
        setIsLoading(false);
        setconfirmationText('Waiting For Block Confirmation');
        return false;
      } else {
        if (e.message.includes("insufficient funds")) {
          toast.error(<span><Translate>Not enough founds to create a game</Translate></span>, {
            duration: 4000,
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
        }
        setConfirmationShow(true);
        setIsLoading(false);
        return false;
      }
    }
  };

  const confirmationClose = () => {
    setConfirmationShow(!ConfirmationShow);
  };

  const toggleVisibility = () => {
    setIsVisible(prevState => !prevState);
  };

  if (!walletConnected) {
    return <CryptoNOT />
  }

  if (loading) {
    return <Loader />
  }

  if (ticketError) {
    return <div>{ticketError}</div>
  }

  return (
    <Layout>
      <div className="filter-page">
        <div className="container">
          <div className="filter-box">
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-8">
                  <div className="ticket-matches">
                    <p><span style={{ color: "#8461dc" }}>@{ticket?.users && ticket.users[0]?.name}</span> <Translate>Ticket</Translate></p>
                    <div className="game-data">
                      <small className="time">
                        {ticket.status === 2 ? <b>Result:  <span style={{color: "red"}}>Finished</span></b> : <>
                          <b><Translate>Ticket will close at</Translate> -</b>
                          {new Date(ticket.startDate).toLocaleDateString() + ", " + new Date(ticket.startDate).toLocaleTimeString()}
                        </>}
                        <br/><span className="earn-back">Earn back a 2.5% commission as the ticket creator</span>
                      </small>
                    </div>
                    <ul>
                      {ticket?.matches && ticket.matches.map((match, i) => <MatchRenderer
                        match={match}
                        result={ticket.results.find(result => result.gameId === match.gameId)}
                        userSelection={userSelection.find(selection => selection.gameId === match.gameId)}
                        select={select}
                        key={match.gameId}
                        isJoined={isJoined}
                      />)}
                    </ul>
                  </div>
                </div>

                {isVisible && (

                <div className="col-md-3">
                  <div className="filter-card filter-sport join-bet-info">
                  <div className="back-btn">
                      <Link to="/sports-tickets">
                        <i className="fa fa-reply fa-2x" aria-hidden="true"></i>
                      </Link>
                    </div>

                    <div className="player-info">
                      <p className="your-wallet-balance" style={{ color: "#8461dc" }}>
                        <Translate>Payment: </Translate>
                        <span style={{ color: "white", fontSize: "1em" }}>{ticket.amount}</span>
                        <img src={walletIcon} alt="wallet icon" className="polygon-coins" />
                      </p>
                      <p style={{ color: "#8461dc" }}>
                        <Translate>Total players:</Translate>
                        <span style={{ color: "white", fontSize: "1em" }}>{ticket.users.length}</span>
                      </p>
                      <p style={{ color: "#8461dc" }}>
                        <Translate>Winning pool:</Translate>
                        <span style={{ color: "white", fontSize: "1em" }}>{ticket.pool}</span>
                      </p>
                      <p style={{ color: "#8461dc" }}>
                        <Translate>Starting time:</Translate>
                        <span style={{ color: "white" }}>
                          {new Date(ticket.startDate).toLocaleDateString()},{" "}
                          {new Date(ticket.startDate).toLocaleTimeString()}
                        </span>
                      </p>
                    </div>

                    <div className="filter-form">
                      <div className="card-footer text-center">
                        {ticket.status > 0 ? (
                          <Button
                            id="btn-join-id"
                            type="button"
                            className="btn-gold btn-join"
                            onClick={() => setShow(true)} >
                              <Translate>Statistics</Translate>
                          </Button>
                          ) : ticket?.users && ticket.users?.find(user => user.userId === firebaseUser.uid) ?
                          <small>
                          <Translate>This is your ticket
                          <Button
                          id="share-ticket"
                          className="btn btn-light btn-sm share-your-ticket"
                          onClick={() => setIsModalOpen(true)} >
                          <Translate>Share</Translate>
                        </Button>
                        </Translate></small> :
                          ticket?.status == 0 && (
                            <Button
                              id="btn-join-id"
                              type="submit"
                              className="btn-gold btn-join"
                              disabled={selections.length !== ticket?.matches?.length} >
                              <Translate>Join</Translate>
                            </Button>
                          )
                        }
                      </div>
                    </div>
                    <div className="bet-user-photo">
                      {ticket.users.slice(0, 5).map(user => (<img className="user-photo" src={user.photoUrl} alt="user-img" key={user.userId} />))}
                      {ticket.users.length > 5 && <span style={{ color: "#8461dc" }}>+ {ticket.users.length - 5} <Translate>more users</Translate></span>}
                    </div>
                  </div>
                </div>
                )}
                {isMobile > 0 && (
                     <button type="button" className="floating-button" onClick={toggleVisibility}>
                        {isVisible ? (
                            <i className="fa fa-minus" aria-hidden="true"></i>
                          ) : (
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        )}
                   </button>
                )}
              </div>
            </Form>
          </div>
        </div>
        {isLoading ? (
          <>
            <Modal
              show={true}
              onHide={() => confirmationClose()}
              centered
              className="sports-bet-pending friends-popup pending-popup">
              <Modal.Header >
                <Modal.Title>
                  <Spinner animation="border" />
                  <small className='title-pending'>
                    {confirmationText === "Waiting For Block Confirmation" ? (
                      <span style={{ color: '#8461dc' }}><Translate>{confirmationText}</Translate></span>
                    ) : (
                      <Translate>{confirmationText}</Translate>
                    )}
                  </small>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="feed-body">
                  {confirmationText === "Please Confirm Transaction" ? (
                    <Lottie options={confirmLottie} width={250} height={250} />
                  ) : <Lottie options={blockLottie} width={300} height={250} />}
                </div>
              </Modal.Body>
            </Modal>
          </>
          ) : null}
            <Modal
                  show={show}
                  onHide={() => setShow(false)}
                  centered
                  className="overview-modal friends-popup" >
                <Modal.Body>
                <Modal.Header closeButton>
                  </Modal.Header>
                  <Tab.Container defaultActiveKey={ticket?.users[0]?.userId || ''}>
                    <Row>
                      <Col sm={2} className="user-list-col">
                        <Nav variant="pills" className="user-nav">
                          {ticket.users.sort((a, b) => {
                            const aIsWinner = winners.includes(a.userId);
                            const bIsWinner = winners.includes(b.userId);
                        
                            // If 'a' is a winner and 'b' is not, place 'a' first
                            if (aIsWinner && !bIsWinner) return -1;
                        
                            // If 'b' is a winner and 'a' is not, place 'b' first
                            if (!aIsWinner && bIsWinner) return 1;
                        
                            // If both are winners or both are not winners, maintain original order
                            return 0;
                          }).map(user => (
                            <Nav.Item key={user.userId} className="user-nav-item" >
                              <Nav.Link eventKey={user.userId} className="user-nav-link">
                                <img className="nav-user-photo" src={user.photoUrl} alt={`Photo of ${user.name}`} />
                                <span className="nav-user-name">{user.name} {winners.includes(user.userId) && <b>🏆</b>}</span>
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </Col>
                      <Col sm={10} className="matches-col">
                        <Tab.Content>
                          {ticket.users.map(user => (
                            <Tab.Pane eventKey={user.userId} key={user.userId}>
                              <div className="ticket-matches">
                                {ticket.matches?.map((match, i) => 
                                {
                                  return( 
                                  <MatchRenderer
                                    match={match}
                                    result={ticket.results.find(result => result.gameId === match.gameId)}
                                    userSelection={user.selections.find(selection => selection.gameId === match.gameId)}
                                    select={select}
                                    key={match.gameId}
                                    isJoined={user.selections.length >0}
                                  />
                                )})}
                              </div>
                            </Tab.Pane>
                          ))}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Modal.Body>
            </Modal>

            <Modal
              show={isModalOpen}
              onHide={handleCloseShare}
              centered
              className="invite-friends-popup bet-popup" >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4 className="help-h4 bet-h4">
                    <img src={walletIcon} alt="info" className="share-matic" />
                    <Translate>Raise the money pool!</Translate>
                  </h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="invite-friends-type invite-friends-bet">
                      <Share shareuserId={shareTicket} />
                    </div>
                <div className="invite-bet-text">
                <p>
                  <span style={{ fontSize: "16px" }}>
                    <Translate>Spread the word and challenge your friends</Translate>
                  </span> 
                </p>
                </div>
                <div className="share-copy-link">
                  <InputGroup>
                    <Form.Control
                      defaultValue={`https://weje.com/sports-tickets/${shareTicket}`} />
                      <CopyToClipboard
                        text={`https://weje.com/sports-tickets/${shareTicket}`}
                        onCopy={handleCopy} >
                        <Button type="button">
                          <img src={copyicon} alt="copy icon" />
                        </Button>
                      </CopyToClipboard>
                  </InputGroup>
                  <Overlay show={showCopyTooltip} placement="top">
                    {(props) => (
                      <Tooltip id="overlay-example" {...props}>
                          <Translate>Copied</Translate>
                      </Tooltip>
                    )}
                  </Overlay>
                </div>
              </Modal.Body>
            </Modal>
        </div>
    </Layout>
  );
};

export default BetDetails;

const MatchRenderer = ({ match, result, userSelection, select }) => {
  // Helper function to determine the correct class
const getResultClass = (userChoice, result, teamId, isDrawable = false) => {
  // Early return for when the result hasn't been announced yet (null or undefined)
  if (result === undefined || result === null) {
    return "";
  }
  if (userChoice === teamId && result === teamId) {
    return "result-win";  // User choice matches the result
  }
  if (userChoice === teamId && result !== teamId) {
    return "result-lose";  // User choice is incorrect
  }
  if (result === teamId && userChoice !== teamId) {
    return "result-correct-border";  // Correct result but user chose wrong
  }
  return "";  // No special style
};

  const matchResult = result?.choice;
  const userMatchChoice = userSelection?.choice;
  // Determine the correct classes for home, tie, and away using the helper function
  const homeStyle = getResultClass(userMatchChoice, matchResult, match.home.id);
  const tieStyle = match.isDrawable ? getResultClass(userMatchChoice, matchResult, 0) : "";
  const awayStyle = getResultClass(userMatchChoice, matchResult, match.away.id);

  return (
    <li key={match.gameId} className={`game-${match.gameId}`}>
      <div className="filter-game close-bet-stats" id={match.gameId}>
      <div className={`game-box ${!matchResult ? "not-started" : ""}`}>
          <img className="img-raised1" src={match.home.logo} alt="home_team" />
          <span
            id={match.home.id}
            onClick={(event) => select(event, match, match.home.id)}
            role="button"
            tabIndex="1"
            className={`game-${match.gameId}home ${userSelection?.choice === match.home.id ? "selected" : ""} ${homeStyle}`} >
            {match.home.name}
          </span>
          <small className="home_score">Home team</small>
        </div>
        <div className={`game-vs ${!matchResult ? "not-started" : ""}`}>
          {match.isDrawable ? <span
            id={match.gameId}
            onClick={(event) => select(event, match, 0)}
            role="button"
            tabIndex="0"
            className={`game-${match.gameId}tie tie-game-class ${userSelection?.choice === 0 ? "selected" : ""} ${tieStyle}`} >
            X
          </span> : <span>vs</span>}

        </div>
        <div className={`game-box ${!matchResult ? "not-started" : ""}`}>
          <img className="img-raised1" src={match.away.logo} alt="away_team" />
          <span
            id={match.away.id}
            onClick={(event) => select(event, match, match.away.id)}
            role="button"
            tabIndex="2"
            className={`game-${match.gameId}away ${userSelection?.choice === match.away.id ? "selected" : ""} ${awayStyle}`} >
            {match.away.name}
          </span>
          <small className="away_score">Away team</small>
        </div>
      </div>
    </li>
  )
}
