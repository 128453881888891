import { React, useContext, useState } from "react";
import Layout from "../layout/layout";
import "./userprofile.css";
import { useParams, useHistory } from "react-router-dom";
import { useEffect } from "react";
import {
  getDoc,
  getImage,
  getPost,
  getTimeline,
  timeDifference,
} from "../../dbFetchFunctions";
import firebase from "../../firebase";
import axios from "axios";
import {
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Pagination,
  Spinner,
} from "react-bootstrap";
import toast from "react-hot-toast";
import OriginalPost from "../feed/post/originalPost";
import ReportPostPopup from "../reportpopup/reportpost";
import like from "../../assets/images/profile/like.svg";
import share from "../../assets/images/profile/share.svg";
import comments from "../../assets/images/profile/comments.svg";
import { MainContext } from "../../context";
import Lottie from "react-lottie";
import loading from "../../assets/animation/loading.json";
import Loader from "../loader/loader";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };

const UserProfile = () => {
  const { setPostData } = useContext(MainContext);
  const history = useHistory();
  const { id: userId } = useParams();
  const [userImage, setUserImage] = useState(
    "https://storage.googleapis.com/mycool-net-app.appspot.com/default-avatar/yelloPhantom.png"
  );
  const [userDetails, setUserDetails] = useState();
  const [posts, setPosts] = useState([]);
  const [postLike, setPostLike] = useState("");
  const commentLike = useState("");
  const [replyLike, setReplyLike] = useState("");
  const [replyDislike, setreplyDislike] = useState("");
  const commentDislike = useState("");
  const [countValue, setCountValue] = useState(0);
  const [newcomment, setNewComment] = useState("");
  const [paginate] = useState(10);
  const [page, setPage] = useState(1);
  const [uid, setUid] = useState();
  const [idToken, setIdToken] = useState();
  const [myImg, setmyImg] = useState("");
  const [show, setShow] = useState("");
  const [replyShow, setReplyShow] = useState("");
  const [rotating, setRotating] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [closeCreatePost, setCloseCreatePost] = useState("");
  const [privacyShow, setPrivacyShow] = useState(false);
  const [privacy, setPrivacy] = useState({ type: "all", label: "Anyone" });
  const [newpost, setNewPost] = useState("");
  const [allowCom, setAllowCom] = useState(true);
  const [nick, setNick] = useState();
  const [selectedPost, setSelectedPost] = useState("");
  const [newReply, setNewReply] = useState("");
  const [view, setView] = useState(false);
  const [reportFeedId, setReportFeedId] = useState("");
  const [reportOwnerId, setReportOwnerId] = useState("");
  const [postLoading,setPostLoading] = useState(true);
  const [disableBtn, setDisableBtn] = useState('')
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const us = firebase.auth().currentUser;
          const token = await firebase.auth().currentUser.getIdToken(true);
          setUid(us.uid);
          setIdToken(token);
          setNick(us.displayName);
          const hdd = firebase.storage();
          const gsRef = hdd.refFromURL(us.photoURL);
          gsRef.getDownloadURL().then((image) => {
            setmyImg(image);
          });
        }
      });
    };
    if (localStorage.getItem("xtkn") && localStorage.getItem("deviceId"))
      checkIsLoggedIn();
  }, []);
  useEffect(() => {
    const dd = async () => {
      const basestats = await getDoc(userId);
      setUserDetails({ ...basestats.userDoc, stats: basestats });
	  setPending(false);
      setUserImage(getImage(basestats.userDoc.photoURI));
      const { post, count } = await getTimeline(basestats.uid, uid, paginate, page);
      setPostLoading(false)
      if (post) {
        setPosts(post);
      }
      setTotalCount(count);
    };
    if (uid && userId) dd();
  }, [userId, page, paginate, uid]);

  //  useEffect(() => {
  //    if (userDetails) {
  //      const hdd = firebase.storage();
  //      const gsRef = hdd.refFromURL(userDetails.photoURI);
  //      gsRef.getDownloadURL().then((image) => {
  //        setUserImage(image);
  //      });

  //    }
  //  }, [userDetails, myImg]);

  const handleComment = async (id, text, commentId, action = "com") => {
    if (text.length && text.length < 151) {
      if (commentId) setRotating("reply");
      else setRotating("comment");
      const res = await axios.get(
        "https://create-comment-reply-t3e66zpola-uc.a.run.app/",
        {
          params: {
            action,
            text,
            comId: commentId,
            feedUid: userId,
          },
          headers: {
            Idtoken: idToken,
            Postid: id,
          },
        }
      );
      if (res.data.error === "no error") {
        const { post, count } = await getTimeline(userId, uid, paginate, page);
        if (post) {
          setPosts(post);
        }
        setTotalCount(count);
        setReplyShow("");
        setNewComment("");
        setNewReply("");
      } else {
        toast.error(res.data.error, {
            id: "toast-error",
            style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
        });
      }
      setRotating(false);
      return true;
    } else {
      toast.error(
        action === "com"
          ? "Comment text is required and length must less than 150"
          : "Reply text is required and length must less than 150",
        {
          id: action,
          style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
        }
      );
    }
  };

  const handleLikeCommentReply = async (
    action,
    id,
    commentId,
    replyId,
    postUserId
  ) => {
    const res = await axios.get(
      "https://like-setter-t3e66zpola-ue.a.run.app/",
      {
        params: {
          action,
          comId: commentId,
          replyId,
          feedUid: postUserId,
        },
        headers: {
          Idtoken: idToken,
          Postid: id,
        },
      }
    );
    if (res.data.error === "no error") {
      const { post, count } = await getTimeline(userId, uid, paginate, page);
      if (post) {
        setPosts(post);
      }
      setTotalCount(count);
    } else {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
    }
    return true;
  };

  const handleCommentShow = (value) => {
    if (value === show) {
      setShow("");
    } else setShow(value);
  };

  const handlePostLike = async (value, likers, postUserId) => {
	setDisableBtn(value)
    if (likers.find((el) => el.uid === localStorage.getItem("uid"))) {
      setCountValue(-1);
    } else {
      setCountValue(0);
    }
    if (postLike === value) {
      setPostLike("");
    } else {
      setPostLike(value);
    }
    const result = await handleLikeCommentReply(
      "like",
      value,
      undefined,
      undefined,
      postUserId
    );
    if (result) {
      setPostLike("");
	  setDisableBtn('')
    }
  };

  const handleCommentLike = async (postId, commentId, likers, postUserId) => {
	setDisableBtn(commentId)
    if (likers.find((el) => el.uid === localStorage.getItem("uid"))) {
      setCountValue(-1);
    } else {
      setCountValue(1);
    }
    // if (commentLike === commentId) {
    //   setCommentLike("");
    // } else {
    //   setCommentLike(commentId);
    // }
    //await handleLikeCommentReply(
    //  "like",
    //  postId,
    //  commentId,
    //  undefined,
    //  postUserId
    //);
    const result = await handleLikeCommentReply("like", postId, commentId,undefined,postUserId);
     if (result) {
		setDisableBtn('')
     }
  };

  const handleCommentDislike = async (
    postId,
    commentId,
    dislikers,
    postUserId
  ) => {
	setDisableBtn(commentId)
    if (dislikers.find((el) => el.uid === localStorage.getItem("uid"))) {
      setCountValue(-1);
    } else {
      setCountValue(1);
    }
    // if (commentDislike === commentId) {
    //   setcommentDislike("");
    // } else {
    //   setcommentDislike(commentId);
    // }
    //await handleLikeCommentReply(
    //  "dislike",
    //  postId,
    //  commentId,
    //  undefined,
    //  postUserId
    //);
     const result = await handleLikeCommentReply(
       "dislike",
       postId,
       commentId,
       undefined,
       postUserId
     );
     if (result) {
		setDisableBtn("");
     }
  };

  // const handleCommentLike = async (postId, commentId, likers) => {
  //   if (likers.find((el) => el.uid === localStorage.getItem("uid"))) {
  //     setCountValue(-1);
  //   } else {
  //     setCountValue(1);
  //   }
  //   if (commentLike === commentId) {
  //     setCommentLike("");
  //   } else {
  //     setCommentLike(commentId);
  //   }
  //   const result = await handleLikeCommentReply("like", postId, commentId);
  //   if (result) {
  //     setCommentLike("");
  //   }
  // };
  // const handleCommentDislike = async (postId, commentId, dislikers) => {
  //   if (dislikers.find((el) => el.uid === localStorage.getItem("uid"))) {
  //     setCountValue(-1);
  //   } else {
  //     setCountValue(1);
  //   }
  //   if (commentDislike === commentId) {
  //     setcommentDislike("");
  //   } else {
  //     setcommentDislike(commentId);
  //   }
  //   const result = await handleLikeCommentReply("dislike", postId, commentId);
  //   if (result) {
  //     setcommentDislike("");
  //   }
  // };
  const handleReplyLike = async (
    postId,
    commentId,
    replyId,
    likers,
    postUserId
  ) => {
	setDisableBtn(replyId)
    if (likers.find((el) => el.uid === localStorage.getItem("uid"))) {
      setCountValue(-1);
    } else {
      setCountValue(1);
    }
    if (replyLike === replyId) {
      setReplyLike("");
    } else {
      setReplyLike(replyId);
    }
    const result = await handleLikeCommentReply(
      "like",
      postId,
      commentId,
      replyId,
      postUserId
    );
    if (result) {
      setReplyLike("");
	  setDisableBtn('')
    }
  };
  const handleReplyDislike = async (postId, commentId, replyId, dislikers,postUserId) => {
	setDisableBtn(replyId)
    if (dislikers.find((el) => el.uid === localStorage.getItem("uid"))) {
      setCountValue(-1);
    } else {
      setCountValue(1);
    }
    if (replyDislike === replyId) {
      setreplyDislike("");
    } else {
      setreplyDislike(replyId);
      const result = await handleLikeCommentReply(
        "dislike",
        postId,
        commentId,
        replyId,
        postUserId
      );
      if (result) {
        setreplyDislike("");
		setDisableBtn('')
      }
    }
  };

  const handleReplyShow = (value) => {
    if (value === show) {
      setReplyShow("");
    } else setReplyShow(value);
  };

  const handleClose = () => {
    setView(false);
  };

  const handleShow = (feedId, ownerId) => {
    setView(true);
    setReportFeedId(feedId);
    setReportOwnerId(ownerId);
  };

  const handleShare = async () => {
    setRotating(true);
    const res = await axios.get(
      "https://create-post-t3e66zpola-uc.a.run.app/share",
      {
        params: {
          text: newpost,
          postId: selectedPost,
        //  shareLink: `/feed-detail/${userId}/${selectedPost}`,
		  shareOri: `/feed-detail/${selectedPost}`,
          privacy: privacy.type,
          allowCom: allowCom,
          feedUid: userId,
        },
        headers: {
          Idtoken: idToken,
        },
      }
    );
    setRotating(false);
	setNewPost('');
    if (res.data.error === "no error") {
      const post = await getPost(uid, "noupdate", paginate, page);

      if (post) {
        setPostData(post);
        setPosts(post);
        setCloseCreatePost("");
        setPrivacyShow(false);
        setNewPost("");
      }
    } else {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
    }
    return true;
  };

  const handleNewPost = async () => {
    if (newpost === "" && newpost.length < 50) {
      return toast.error("Posts must be at least 50 characters long.", {
        id: "toast-error",
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
    }
    setRotating(true);
    const res = await axios.get(
      "https://create-post-t3e66zpola-uc.a.run.app/",
      {
        params: {
          text: newpost,
          privacy: privacy.type,
          allowCom: allowCom ? "yes" : "no",
          action: "newPost",
          feedUid: userId,
        },
        headers: {
          Idtoken: idToken,
        },
      }
    );
    setRotating(false);
	setNewPost('');
    if (res.data.error === "no error") {
        toast.success("Thank you for sharing.", {
            id: "toast-success",
            style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
          });
      setCloseCreatePost(false);
      setPrivacyShow(false);
    } else {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
    }
  };

  const selectPostType = (event) => {
    setPrivacy(JSON.parse(event.target.value));
  };

  const hidePostComntReply = async (
    postId,
    commentId,
    replyId,
    postInd,
    commentInd,
    replyInd
  ) => {
    let copyPost = JSON.parse(JSON.stringify(posts));
    let parmsObj = { postId };

    // for hiding the post after response success.
    if (!commentId && !replyId) {
      copyPost[postInd].isHide = true;
    }
    // for hiding the comment- query and state manage
    if (commentId && !replyId) {
      parmsObj.comId = commentId;
      copyPost[postInd].commentsArr[commentInd].isHide = true;
    }
    // for hiding the reply - query and state manage
    if (commentId && replyId) {
      parmsObj.comId = commentId;
      parmsObj.repId = replyId;
      copyPost[postInd].commentsArr[commentInd].repliesArr[
        replyInd
      ].isHide = true;
    }
    const successMsg =
      commentId && !replyId
        ? "Comment"
        : commentId && replyId
        ? "Reply"
        : "Post";
        toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
            id: "toast-loading",
            duration: 6000,
            style: {
              borderRadius: "5px",
              background: "#333",
              color: "#fff",
            },
        });
    const res = await axios.get(
      "https://del-user-feed-t3e66zpola-uc.a.run.app/hideContent",
      {
        params: parmsObj,
        headers: {
          idtoken: idToken,
        },
      }
    );
    if (res.data.success) {
      setPosts(copyPost);
      toast.success(`${successMsg} hide`, {
        id: "toast-success",
        style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
      });
    } else if (res.data.error !== "no error") {
      toast.error(res.data.error, {
        id: "toast-error",
        style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
        },
      });
    }
  };

  const handleDeletePost = async (postId) => {
    try {
        toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
            id: "toast-loading",
            duration: 6000,
            style: {
              borderRadius: "5px",
              background: "#333",
              color: "#fff",
            },
        });
      const res = await axios.get(
        `https://del-user-feed-t3e66zpola-uc.a.run.app/deleteSinglePost`,
        { headers: { idToken: idToken }, params: { postId } }
      );
      if (res.data.error === "no error") {
        toast.success("Deleted.", {
            id: "toast-success",
            style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
        });
        setPostLoading(true);
        const { post } = await getTimeline(userId, uid, paginate, page);
        setPostLoading(false);
        if (post) {
          setPosts(post);
        }
      } else {
        toast.error(res.data.error, {
            id: "toast-error",
            style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
        });
      }
      return true;
    } catch (error) {
      console.log("Error in handleDeletePost api =>", error);
    }
  };

  return (
	<>
    {pending ? (
		<Loader />
	) : (
		<Layout>
      <div className='user-profile'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='user-profile-box'>
                <div className='card'>
                  <div className='card-body'>
                    <img
                      src={userImage}
                      alt=''
                      className='rounded-circle'
                      style={{ height: "100px" }}
                    />
                    <h4>{userDetails?.nickname}</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-8'>
              <div className='user-profile-stats'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='user-stats setting-info'>
                      <ul>
                        <li>
                          <h6 className='mb-0'>
                            <i
                              className='fa fa-bullseye'
                              style={{ color: "#8461dc" }}
                            />
                            &nbsp;&nbsp;LV Coins
                          </h6>
                          <span className='text-secondary'>
                            {userDetails?.stats?.total?.coins}
                          </span>
                        </li>

                        <li>
                          <h6 className='mb-0'>
                            <i
                              className='fa fa-gamepad'
                              style={{ color: "#8461dc" }}
                            />
                            &nbsp;&nbsp;Games
                          </h6>
                          <span className='text-secondary'>
                            {userDetails?.stats?.total?.games} L /{" "}
                            {userDetails?.stats?.total?.win} W
                          </span>
                        </li>

                        <li>
                          <h6 className='mb-0'>
                            <i
                              className='fa fa-arrow-circle-up'
                              style={{ color: "#8461dc" }}
                            />
                            <b>&nbsp;&nbsp;Level</b>
                          </h6>
                          <span className='text-target'>
                            <b style={{ color: "#ffffff" }}>
                              {userDetails?.stats?.Level}
                            </b>
                          </span>
                        </li>

                        <li>
                          <h6 className='mb-0'>
                            <i
                              className='fa fa-user'
                              style={{ color: "#8461dc" }}
                            />
                            &nbsp;&nbsp;Friends
                          </h6>
                          <span className='text-secondary'>
                            {userDetails?.stats?.social?.friends}
                          </span>
                        </li>

                        <li>
                          <h6 className='mb-0'>
                            <i
                              className='fa fa-check-circle'
                              style={{ color: "#8461dc" }}
                            />
                            &nbsp;&nbsp;Followers
                          </h6>
                          <span className='text-secondary'>
                            {userDetails?.stats?.social?.followers}
                          </span>
                        </li>

                        <li>
                          <h6 className='mb-0'>
                            <i
                              className='fa fa-check'
                              style={{ color: "#8461dc" }}
                            />
                            &nbsp;&nbsp;Following
                          </h6>
                          <span className='text-secondary'>
                            {userDetails?.stats?.social?.following}
                          </span>
                        </li>

                        <li>
                          <h6 className='mb-0'>
                            <i
                              className='fa fa-trophy'
                              style={{ color: "#8461dc" }}
                            />
                            &nbsp;&nbsp;Wins
                          </h6>
                          <span className='text-secondary'>
                            {userDetails?.stats?.total?.win}
                          </span>
                        </li>

                        <li>
                          <h6 className='mb-0'>
                            <i
                              className='fa fa-thumbs-down'
                              style={{ color: "#8461dc" }}
                            />
                            &nbsp;&nbsp;Losses
                          </h6>
                          <span className='text-secondary'>
                            {userDetails?.stats?.total?.loose}
                          </span>
                        </li>

                        <li>
                          <h6 className='mb-0'>
                            <i
                              className='fa fa-clock-o'
                              style={{ color: "#8461dc" }}
                            />
                            &nbsp;&nbsp;Playing time
                          </h6>
                          <span className='text-secondary'>
                            {userDetails?.stats?.Hplayed_friends?.toFixed(2)}
                          </span>
                        </li>

                        <li>
                          <h6 className='mb-0'>
                            <i
                              className='fa fa-balance-scale'
                              style={{ color: "#8461dc" }}
                            />
                            &nbsp;&nbsp;W/L Ratio
                          </h6>
                          <span className='text-secondary'>
                            {(
                              userDetails?.stats?.total?.wl_ratio * 100
                            ).toFixed(2)}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {!postLoading? <div className='card'>
                  <div className='card-body'>
                    <div className='user-feed'>
                      {posts
                        ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                        .map((post, postInd) => (
                          <>
                            {!post?.isHide ? (
                              <div className='post-page' key={post.id}>
                                <div className='filter-card' key={post.id}>
                                  <div className='filter-matches'>
                                    <div className='report-post-box'>
                                      <div
                                        className='post-user'
                                        onClick={() =>
                                          history.push(
                                            `/userProfile/${post.uid}`
                                          )
                                        }>
                                        <img
                                          src={post?.photo}
                                          alt='user-profile'
                                        />
                                        <div className='user-name'>
                                          {post?.nickname}
                                          <span className='post-time'>
                                            {timeDifference(post?.date)}
                                          </span>
                                        </div>
                                      </div>
                                      <div className='report-post'>
                                        <DropdownButton
                                          id='dropdown-basic-button'
                                          title={
                                            <i
                                              className='fa fa-ellipsis-v'
                                              aria-hidden='true'></i>
                                          }>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleShow(post.id, post.uid)
                                            }>
                                            <div className='reort-post-item'>
                                              <i
                                                className='fa fa-exclamation-triangle'
                                                aria-hidden='true'></i>
                                              <div className='reort-post-item-content'>
                                                <h6>Report Post</h6>
                                                <p>
                                                  I'm concerned about this post
                                                </p>
                                              </div>
                                            </div>
                                          </Dropdown.Item>
                                          {post?.uid !== uid ? (
                                            <>
                                              {" "}
                                              {/*<Dropdown.Item
                                                onClick={() =>
                                                  hidePostComntReply(
                                                    post.id,
                                                    undefined,
                                                    undefined,
                                                    postInd
                                                  )
                                                }>
                                                <div className='reort-post-item'>
                                                  <i
                                                    className='fa fa-eye-slash'
                                                    aria-hidden='true'></i>
                                                  <div className='reort-post-item-content'>
                                                    <h6>Hide Post</h6>
                                                  </div>
                                                </div>
                                              </Dropdown.Item>*/}
                                            </>
                                          ) : (
                                            <Dropdown.Item
                                              onClick={() => {
                                                if (post?.uid === uid)
                                                  handleDeletePost(post.id);
                                              }}>
                                              <div className='reort-post-item'>
                                                <i
                                                  className='fa fa-trash-o'
                                                  aria-hidden='true'></i>
                                                <div className='reort-post-item-content'>
                                                  <h6>Delete Post</h6>
                                                </div>
                                              </div>
                                            </Dropdown.Item>
                                          )}
                                        </DropdownButton>
                                      </div>
                                    </div>
                                    <div className='post-content'>
                                      {post.text.split(": ")[1] ? (
                                        <div>
                                          <p
                                            onClick={() =>
                                              history.push(
                                                `/feed-detail/${post.id}`
                                              )
                                            }>
                                            {" "}
                                            {post.text.split(": ")[0]}
                                          </p>
                                          <div className='original-post'>
                                            <OriginalPost
                                              uid={uid}
                                              postId={
                                                post.text.split(
                                                  ": /feed-detail/"
                                                )[1]
                                              }
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <p
                                          onClick={() =>
                                            history.push(
                                              `/feed-detail/${post.id}`
                                            )
                                          }>
                                          {post.text}
                                        </p>
                                      )}
                                    </div>
                                    <div className='post-activity'>
                                      <div className={`post-likes ${disableBtn === post.id ? 'disable-btn' : ''}`}>
                                        <span
                                          onClick={() =>
                                            handlePostLike(
                                              post.id,
                                              post.likers,
                                              post.uid
                                            )
                                          }>
                                          {/*<i
                                        className={
                                          post.likers.find(
                                            (el) => el.uid === uid
                                          )
                                            ? `fa fa-heart`
                                            : `fa fa-heart-o`
                                        }
                                        aria-hidden="true"
                                      ></i>*/}
                                          <img src={like} alt='like' />
                                          {postLike === post.id
                                            ? post.likes + countValue
                                            : post.likes}
                                        </span>
                                      </div>
                                      <div
                                        className='post-comments'
                                        style={
                                          !post.allowCom
                                            ? { visibility: "hidden" }
                                            : {}
                                        }>
                                        <span
                                          onClick={() => {
                                            handleCommentShow(post.id);
                                          }}>
                                          <img src={comments} alt='like' />
                                          {post.comments}
                                        </span>
                                      </div>
                                      <div className='post-share'>
                                        <span
                                          onClick={() => {
                                            setSelectedPost(post.id);
                                            setCloseCreatePost("share");
                                          }}>
                                          <img src={share} alt='like' />
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  {show === post.id ? (
                                    <div className='feed-comments'>
                                      <div className='body_comment'>
                                        <div className='row'>
                                          <div className='avatar_comment col-md-1'>
                                            <img src={myImg} alt='avatar' />
                                          </div>
                                          <div className='box_comment col-md-11'>
                                            <textarea
                                              className='commentar'
                                              placeholder='Add a comment...'
                                              onChange={(e) =>
                                                setNewComment(e.target.value)
                                              }
                                              value={newcomment}></textarea>
                                            <div className='box_post'>
                                              <button
                                                type='button'
                                                value='1'
                                                onClick={() =>
                                                  handleComment(
                                                    post.id,
                                                    newcomment
                                                  )
                                                }
                                                disabled={rotating}>
                                                {rotating === "comment" ? (
                                                  <Spinner animation='border' />
                                                ) : (
                                                  <i className='lab la-telegram-plane'></i>
                                                )}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          <ul
                                            id='list_comment'
                                            className='col-md-12'>
                                            {post.commentsArr
                                              ?.sort(
                                                (a, b) =>
                                                  new Date(b.date) -
                                                  new Date(a.date)
                                              )
                                              .map((comment, commentInd) => (
                                                <>
                                                  {!comment?.isHide ? (
                                                    <li
                                                      className='box_result row'
                                                      key={comment.id}>
                                                      <div className='user-comments-details'>
                                                        <div
                                                          className='avatar_comment'
                                                          onClick={() =>
                                                            history.push(
                                                              `/userProfile/${comment.uid}`
                                                            )
                                                          }>
                                                          <img
                                                            src={comment.photo}
                                                            alt='avatar'
                                                          />
                                                          <p
                                                            onClick={() =>
                                                              history.push(
                                                                `/userProfile/${comment.uid}`
                                                              )
                                                            }>
                                                            {comment.nickname}
                                                          </p>
                                                        </div>
                                                        {comment.uid !== uid ? (
                                                          <div className='report-post'>
                                                            <DropdownButton
                                                              id='dropdown-basic-button'
                                                              title={
                                                                <i
                                                                  className='fa fa-ellipsis-v'
                                                                  aria-hidden='true'></i>
                                                              }>
                                                              <Dropdown.Item
                                                                onClick={() =>
                                                                  hidePostComntReply(
                                                                    post.id,
                                                                    comment.id,
                                                                    undefined,
                                                                    postInd,
                                                                    commentInd
                                                                  )
                                                                }>
                                                                <div className='reort-post-item'>
                                                                  <i
                                                                    className='fa fa-eye-slash'
                                                                    aria-hidden='true'></i>
                                                                  <div className='reort-post-item-content'>
                                                                    <h6>
                                                                      Hide
                                                                      Comment
                                                                    </h6>
                                                                  </div>
                                                                </div>
                                                              </Dropdown.Item>
                                                            </DropdownButton>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                      <div className='result_comment'>
                                                        <p>{comment.text}</p>
                                                        <div className='tools_comment'>
                                                          <div className='likes-box'>
                                                            <div
															  className={`like-section ${disableBtn === comment.id ? 'disable-btn' : ''}`}
                                                              onClick={() =>
                                                                handleCommentLike(
                                                                  post.id,
                                                                  comment.id,
                                                                  comment.likers,
                                                                  post.uid
                                                                )
                                                              }>
                                                              <i
                                                                className={
                                                                  comment.likers.find(
                                                                    (el) =>
                                                                      el.uid ===
                                                                      uid
                                                                  )
                                                                    ? `fa fa-thumbs-up`
                                                                    : `fa fa-thumbs-o-up`
                                                                }></i>{" "}
                                                              <span className='count'>
                                                                {commentLike ===
                                                                comment.id
                                                                  ? comment.likes +
                                                                    countValue
                                                                  : comment.likes}
                                                              </span>
                                                            </div>

                                                            <div
                                                              className={`like-section ${disableBtn === comment.id ? 'disable-btn' : ''}`}
                                                              onClick={() =>
                                                                handleCommentDislike(
                                                                  post.id,
                                                                  comment.id,
                                                                  comment.dislikers,
                                                                  post.uid
                                                                )
                                                              }>
                                                              <i
                                                                className={
                                                                  comment.dislikers.find(
                                                                    (el) =>
                                                                      el.uid ===
                                                                      uid
                                                                  )
                                                                    ? `fa fa-thumbs-down`
                                                                    : `fa fa-thumbs-o-down`
                                                                }></i>{" "}
                                                              <span className='count'>
                                                                {commentDislike ===
                                                                comment.id
                                                                  ? comment.dislikes +
                                                                    countValue
                                                                  : comment.dislikes}
                                                              </span>
                                                            </div>
                                                            <div
                                                              className='like-section'
                                                              onClick={() =>
                                                                handleReplyShow(
                                                                  comment.id
                                                                )
                                                              }>
                                                              <i className='fa fa-comment'></i>{" "}
                                                              <span className='count'>
                                                                {
                                                                  comment.replies
                                                                }
                                                              </span>
                                                            </div>
                                                            <div className='like-section'>
                                                              <span>
                                                                {" " +
                                                                  timeDifference(
                                                                    comment.date
                                                                  )}
                                                              </span>
                                                            </div>
                                                          </div>
                                                          {replyShow ===
                                                          comment.id ? (
                                                            <>
                                                              <div className='row'>
                                                                <div className='avatar_comment col-md-1'>
                                                                  <img
                                                                    src={myImg}
                                                                    alt='avatar'
                                                                  />
                                                                </div>
                                                                <div className='box_comment col-md-11'>
                                                                  <textarea
                                                                    className='commentar'
                                                                    placeholder='Add a comment...'
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      setNewReply(
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                    value={
                                                                      newReply
                                                                    }></textarea>
                                                                  <div className='box_post'>
                                                                    <button
                                                                      type='button'
                                                                      value='1'
                                                                      onClick={() =>
                                                                        handleComment(
                                                                          post.id,
                                                                          newcomment,
                                                                          comment.id,
                                                                          "reply"
                                                                        )
                                                                      }
                                                                      disabled={
                                                                        rotating
                                                                      }>
                                                                      {rotating ===
                                                                      "reply" ? (
                                                                        <Spinner animation='border' />
                                                                      ) : (
                                                                        "Reply"
                                                                      )}
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <ul className='child_replay'>
                                                                {comment?.repliesArr
                                                                  ?.sort(
                                                                    (a, b) =>
                                                                      new Date(
                                                                        b.date
                                                                      ) -
                                                                      new Date(
                                                                        a.date
                                                                      )
                                                                  )
                                                                  .map(
                                                                    (
                                                                      reply,
                                                                      replyInd
                                                                    ) => (
                                                                      <>
                                                                        {!reply?.isHide ? (
                                                                          <li
                                                                            className='box_reply row'
                                                                            key={
                                                                              reply.id
                                                                            }>
                                                                            <div className='user-comments-details'>
                                                                              <div
                                                                                className='avatar_comment col-md-1'
                                                                                onClick={() =>
                                                                                  history.push(
                                                                                    `/userProfile/${reply.uid}`
                                                                                  )
                                                                                }>
                                                                                <img
                                                                                  src={
                                                                                    reply.photo
                                                                                  }
                                                                                  alt='avatar'
                                                                                />
                                                                              </div>
                                                                              {reply?.uid !==
                                                                              uid ? (
                                                                                <div className='report-post'>
                                                                                  <DropdownButton
                                                                                    id='dropdown-basic-button'
                                                                                    title={
                                                                                      <i
                                                                                        className='fa fa-ellipsis-v'
                                                                                        aria-hidden='true'></i>
                                                                                    }>
                                                                                    <Dropdown.Item
                                                                                      onClick={() =>
                                                                                        hidePostComntReply(
                                                                                          post.id,
                                                                                          comment.id,
                                                                                          reply.id,
                                                                                          postInd,
                                                                                          commentInd,
                                                                                          replyInd
                                                                                        )
                                                                                      }>
                                                                                      <div className='reort-post-item'>
                                                                                        <i
                                                                                          className='fa fa-eye-slash'
                                                                                          aria-hidden='true'></i>
                                                                                        <div className='reort-post-item-content'>
                                                                                          <h6>
                                                                                            Hide
                                                                                            Reply
                                                                                          </h6>
                                                                                        </div>
                                                                                      </div>
                                                                                    </Dropdown.Item>
                                                                                  </DropdownButton>
                                                                                </div>
                                                                              ) : null}
                                                                            </div>
                                                                            <div className='result_comment col-md-11'>
                                                                              <h4
                                                                                onClick={() =>
                                                                                  history.push(
                                                                                    `/userProfile/${reply.uid}`
                                                                                  )
                                                                                }>
                                                                                {
                                                                                  reply.nickname
                                                                                }
                                                                              </h4>
                                                                              <p>
                                                                                {
                                                                                  reply.text
                                                                                }
                                                                              </p>
                                                                              <div className='tools_comment'>
                                                                                <div className='likes-box'>
                                                                                  <div
																					className={`like-section ${disableBtn === reply.id ? 'disable-btn' : ''}`}
                                                                                    onClick={() =>
                                                                                      handleReplyLike(
                                                                                        post.id,
                                                                                        comment.id,
                                                                                        reply.id,
                                                                                        reply.likers,
                                                                                        post.uid
                                                                                      )
                                                                                    }>
                                                                                    <span className='like'>
                                                                                      {replyLike ===
                                                                                      reply.id
                                                                                        ? reply.likes +
                                                                                          countValue +
                                                                                          " Like"
                                                                                        : reply.likes +
                                                                                          " Like"}
                                                                                    </span>
                                                                                    <i
                                                                                      className={
                                                                                        reply.likers.find(
                                                                                          (
                                                                                            el
                                                                                          ) =>
                                                                                            el.uid ===
                                                                                            uid
                                                                                        )
                                                                                          ? `fa fa-thumbs-up`
                                                                                          : `fa fa-thumbs-o-up`
                                                                                      }></i>{" "}
                                                                                  </div>
                                                                                  <div className={`like-section ${disableBtn === reply.id ? 'disable-btn' : ''}`}>
                                                                                    <span
                                                                                      className='like'
                                                                                      onClick={() =>
                                                                                        handleReplyDislike(
                                                                                          post.id,
                                                                                          comment.id,
                                                                                          reply.id,
                                                                                          reply.dislikers,
                                                                                          post.uid
                                                                                        )
                                                                                      }>
                                                                                      {replyDislike ===
                                                                                      reply.id
                                                                                        ? reply.dislikes +
                                                                                          countValue +
                                                                                          " Dislike"
                                                                                        : reply.dislikes +
                                                                                          " Dislike"}
                                                                                    </span>
                                                                                    <i
                                                                                      className={
                                                                                        reply.dislikers.find(
                                                                                          (
                                                                                            el
                                                                                          ) =>
                                                                                            el.uid ===
                                                                                            uid
                                                                                        )
                                                                                          ? `fa fa-thumbs-down`
                                                                                          : `fa fa-thumbs-o-down`
                                                                                      }></i>{" "}
                                                                                  </div>
                                                                                  <span>
                                                                                    {" " +
                                                                                      timeDifference(
                                                                                        reply.date
                                                                                      )}
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                              <ul className='child_replay'></ul>
                                                                            </div>
                                                                          </li>
                                                                        ) : null}
                                                                      </>
                                                                    )
                                                                  )}
                                                              </ul>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      </div>
                                                    </li>
                                                  ) : null}
                                                </>
                                              ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}
                          </>
                        ))}
                    </div>
                  </div>
                </div>:<div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Spinner animation="border"/>
                </div>  }
              
                {totalCount > paginate && (
                  <div className='pagination-view'>
                    <Pagination>
                      <Pagination.First onClick={() => setPage(1)} />
                      <Pagination.Prev
                        onClick={() => (page - 1 > 0 ? setPage(page - 1) : "")}
                      />
                      {page - 2 > 0 ? (
                        <Pagination.Item onClick={() => setPage(page - 2)}>
                          {page - 2}
                        </Pagination.Item>
                      ) : (
                        ""
                      )}
                      {page - 1 > 0 ? (
                        <Pagination.Item onClick={() => setPage(page - 1)}>
                          {page - 1}
                        </Pagination.Item>
                      ) : (
                        ""
                      )}
                      <Pagination.Item active>{page}</Pagination.Item>
                      {(page + 1) * 9 < totalCount ? (
                        <Pagination.Item onClick={() => setPage(page + 1)}>
                          {page + 1}
                        </Pagination.Item>
                      ) : (
                        ""
                      )}
                      {(page + 2) * 9 < totalCount ? (
                        <Pagination.Item onClick={() => setPage(page + 2)}>
                          {page + 2}
                        </Pagination.Item>
                      ) : (
                        ""
                      )}
                      <Pagination.Next
                        onClick={() =>
                          page > Math.ceil(totalCount / 9)
                            ? setPage(page + 1)
                            : ""
                        }
                      />
                      <Pagination.Last
                        onClick={() => setPage(Math.ceil(totalCount / 9))}
                      />
                    </Pagination>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={closeCreatePost.length !== 0}
          onHide={() => setCloseCreatePost("")}
          centered
          className='friends-popup'>
          <Modal.Header closeButton>
            <Modal.Title>
              <small>
                {closeCreatePost === "newPost" ? "Create a post" : "Share Post"}
              </small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='feed-body'>
              <Form.Group className='post-form' controlId='post-id'>
                <div className='post-box'>
                  <img src={myImg} alt='Admin' className='rounded-circle' />
                  <small>@{nick}</small>
                </div>
                <button
                  onClick={() => setPrivacyShow(true)}
                  className='btn-sm btn-outline-light select-post'>
                  <i className='fa fa-globe' aria-hidden='true'>
                    {" "}
                  </i>
                  {privacy.label}
                </button>
              </Form.Group>

              <div className='allow-comment'>
                <label className='switch'>
                  <input
                    type='checkbox'
                    name='allowCom'
                    onChange={() => setAllowCom(!allowCom)}
                    defaultChecked={allowCom}
                  />
                  <span className='slider round'></span>
                </label>
                <label>Allow comment</label>
              </div>

              <div className='form-group'>
                <div className='the-count'>
                  <span className='current'>{newpost.length}</span>
                  <span className='maximum'>/ 250</span>
                </div>
                <textarea
                  className='form-control text-area animated'
                  placeholder="What's on your mind"
                  onChange={(e) => setNewPost(e.target.value)}
                  maxLength={250}
                  minLength={50}
				  value={newpost}
                  count={newpost}></textarea>
              </div>

              <div className='btn-float'>
                <button
                  className='btn btn-dark'
                  type='button'
                  onClick={() => {
                    closeCreatePost === "newPost"
                      ? handleNewPost()
                      : handleShare();
                  }}
                  disabled={rotating}>
                  {rotating ? <Spinner animation='border' /> : "Share"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={privacyShow}
          onHide={() => setPrivacyShow(false)}
          centered
          className='friends-popup'>
          <Modal.Header closeButton>
            <Modal.Title>
              <small>Who can see your post?</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='feed-body'>
              <div className='col-md-6'>
                <Form.Group className='post-form' controlId='post-id'>
                  <div className='post-select'>
                    <div className='filter-form-posts'>
                      <div className='filter-input radio'>
                        <input
                          type='radio'
                          value={JSON.stringify({
                            type: "all",
                            label: "Anyone",
                          })}
                          name='SharePost'
                          defaultChecked={privacy.type === "all"}
                          onChange={selectPostType}
                        />
                        <label className='radio-label' id='Basketballtext'>
                          <span className='btn-sm select-post'>
                            <i
                              className='fa fa-globe fa-fa-design'
                              aria-hidden='true'></i>
                            Anyone
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='post-select'>
                    <div className='filter-form-posts'>
                      <div className='filter-input radio'>
                        <input
                          type='radio'
                          value={JSON.stringify({
                            type: "ff",
                            label: "Friends and followers",
                          })}
                          name='SharePost'
                          defaultChecked={privacy.type === "ff"}
                          onChange={selectPostType}
                        />
                        <label className='radio-label' id='Basketballtext'>
                          <span className='btn-sm select-post'>
                            <i
                              className='fa fa-users fa-fa-design'
                              aria-hidden='true'></i>
                            Friends and followers
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='post-select'>
                    <div className='filter-form-games'>
                      <div className='filter-input radio'>
                        <input
                          type='radio'
                          value={JSON.stringify({
                            type: "friends",
                            label: "Friends",
                          })}
                          name='SharePost'
                          defaultChecked={privacy.type === "freinds"}
                          onChange={selectPostType}
                        />
                        <label className='radio-label' id='Basketballtext'>
                          <span className='btn-sm select-post'>
                            <i
                              className='fa fa-user fa-fa-design'
                              aria-hidden='true'></i>
                            Friends
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='post-select'>
                    <div className='filter-form-games'>
                      <div className='filter-input radio'>
                        <input
                          type='radio'
                          value={JSON.stringify({
                            type: "followers",
                            label: "Followers",
                          })}
                          name='SharePost'
                          defaultChecked={privacy.type === "followers"}
                          onChange={selectPostType}
                        />
                        <label className='radio-label' id='Basketballtext'>
                          <span className='btn-sm select-post'>
                            <i
                              className='fa fa-check-circle fa-fa-design'
                              aria-hidden='true'></i>
                            Followers
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className='btn-float'>
                <button
                  className='btn btn-dark'
                  onClick={() => setPrivacyShow(false)}>
                  Back
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={view}
          onHide={handleClose}
          centered
          className='report-popup'>
          <Modal.Header closeButton> </Modal.Header>
          <Modal.Body>
            <ReportPostPopup
              feedId={reportFeedId}
              ownerId={reportOwnerId}
              idToken={idToken}
            />
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
	)}
	</>
  );
};

export default UserProfile;
