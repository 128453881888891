import React, { useState, useEffect } from 'react';
import logo from "../../assets/images/Weje-logo.png";
import walletIcons from "../../assets/images/header/supported-black.png";
import registerplayImage from "../../assets/images/register_play.png";
import { Helmet } from "react-helmet";
import Layout from "../layout/layout";
import Register from "./register";
import confirm from "../../assets/animation/confirm.json";
import { Translate } from "react-auto-translate";
import VideoComponent from "../videos/video.jsx";
import "./index.css";

function Landing() {
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [activeClass, setActiveClass] = useState('');
    const [isVideoPlaying, setIsVideoPlaying] = useState(false); 

    const handleMouseEnter = (className) => {
        setActiveClass(`active-item ${className}`);
    };

    const handleMouseLeave = () => {
        setActiveClass('');
    };

    const handlePlayClick = (videoType) => {
        setIsVideoPlaying(true);
        setSelectedVideo(null); // Reset the video
        setTimeout(() => setSelectedVideo(videoType), 0); // Set the video type again after a short delay
    };

    const handleCloseVideo = () => {
        setIsVideoPlaying(false); // Reset to false
        setSelectedVideo(null); // Optionally reset the selected video if needed
    };

    const confirmLottie = {
        loop: true,
        autoplay: true,
        animationData: confirm,
    };

    return (
        <>
            <Helmet>
                <html lang='en' className='remove-header-padding' />
            </Helmet>
            <Layout>
                <div className='login account-create'>
                    <div className='login-section'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 mb-12'>
                                    <div className='signup-box'>
                                        <div className='login-form rigister-tab'>
                                            <img src={logo} alt="logo" className="title-mobile image-landing" />
                                            <div className='rigister-tab'>
                                                <div className='appstore-grid'>
                                                    <Register />
                                                    <VideoComponent key={selectedVideo} videoType={selectedVideo} onClose={handleCloseVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            { !isVideoPlaying && ( 
                                <div className="register-video-image" onClick={() => handlePlayClick("register")}>
                                    <img src={registerplayImage} alt="Play Register Video" className="play-background-image" />
                                        <i className="fa fa-play-circle play-icon register-play-icon" aria-hidden="true"></i>
                                </div>
                            )}

                            <div className={`advantages ${activeClass}`}>
                                <div className="item-overlay item-overlay-top"></div>
                                <div className="item-overlay item-overlay-bottom"></div>
                                <div className="item dapp" data-id="dapp" onMouseEnter={() => handleMouseEnter('dapp')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span><Translate>Real-time</Translate></span>
                                        </div>
                                        <div className="bottom">
                                            <span><Translate>Video chat</Translate></span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon"></div>
                                            <div className="content-title">
                                                <span><Translate>Real-time</Translate></span>
                                            </div>
                                            <ol className="content-list">
                                                <li><span><Translate>Live Video & Audio Gaming</Translate></span></li>
                                                <li><span><Translate>Real Players Worldwide</Translate></span></li>
                                                <li><span><Translate>Join Weje Poker Community</Translate></span></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                                <div className="item secure" data-id="secure" onMouseEnter={() => handleMouseEnter('secure')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span><Translate>Secure</Translate></span>
                                        </div>
                                        <div className="bottom">
                                            <span><Translate>No deposit</Translate></span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon"></div>
                                            <div className="content-title">
                                                <span><Translate>Secure</Translate></span>
                                            </div>
                                            <ol className="content-list">
                                                <li><span><Translate>No Deposits Needed</Translate></span></li>
                                                <li><span><Translate>No Registration Needed</Translate></span></li>
                                                <li><span><Translate>Encrypted WEB3 Transactions</Translate></span></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                                <div className="item decentralized" data-id="decentralized" onMouseEnter={() => handleMouseEnter('decentralized')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span><Translate>Decentralized</Translate></span>
                                        </div>
                                        <div className="bottom">
                                            <span><Translate>100% transparency</Translate></span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon"></div>
                                            <div className="content-title">
                                                <span><Translate>100% Decentralized</Translate></span>
                                            </div>
                                            <ol className="content-list">
                                                <li><span><Translate>Peer to Peer Game</Translate></span></li>
                                                <li><span><Translate>Trustless Transactions, No Intermediaries</Translate></span></li>
                                                <li><span><Translate>Complete Control Over your Crypto</Translate></span></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>                              

                                <div className="item blockchain" data-id="blockchain" onMouseEnter={() => handleMouseEnter('blockchain')} onMouseLeave={handleMouseLeave}>
                                    <div className="icon-image"></div>
                                    <div className="text">
                                        <div className="top">
                                            <span><Translate>Blockchain</Translate></span>
                                        </div>
                                        <div className="bottom">
                                            <span><Translate>Polygon matic</Translate></span>
                                        </div>
                                    </div>
                                    <div className="item-content-wrap">
                                        <div className="item-content">
                                            <div className="content-icon"></div>
                                            <div className="content-title">
                                                <span><Translate>Polygon MATIC</Translate></span>
                                            </div>
                                            <div className="content-text">
                                                <ol className="content-list">
                                                    <li><span><Translate>Polygon Ensures Transactions Applications Run Smoothly</Translate></span></li>
                                                    <li><span><Translate>You Need Polygon Matic Tokens To Play</Translate></span></li>
                                                    <li><span><Translate>Low Gas Fees</Translate></span></li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="appstore-grid">
                                <div className="wallet-icons">
                                    <img className="img-responsive" src={walletIcons} alt="ios" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Landing;
