import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Form, Button, Spinner, Modal } from "react-bootstrap";
import Layout from "../layout/layout";
import PolyLogo from "../../assets/animation/Poker.png";
import { Translate } from "react-auto-translate";
import BlackjackAbi from "../../ABI/BlackjackAbi.json";
import MinimumBet from "./minbet";
import { MainContext } from '../../context';
import { browserName, getUA } from "react-device-detect";
import Lottie from "react-lottie";
import Select from "react-select";
import toast from "react-hot-toast";
import axios from "axios";
import walletIcon from "../../assets/images/popups/wallet-logo.png";
import { ethers } from "ethers"
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import { useWeb3React } from "@web3-react/core";
import CryptoNOT from "../cryptoNOT/index";
import loadericon from "../../assets/animation/loader.webp";
import "./table.css";
import UrlConfig from "../../utils/ApiConfig";
import { v4 } from "uuid";
import { cookieSetter } from '../../dbFetchFunctions';
import confirm from "../../assets/animation/confirm.json";
import blockc from "../../assets/animation/block.json";

let device = browserName + "_" + getUA.replace(/[^A-Z0-9]/gi, "_")

const confirmLottie = {
  loop: true,
  autoplay: true,
  animationData: confirm,
};

const blockLottie = {
  loop: true,
  autoplay: true,
  animationData: blockc,
};

function BJackCreateTable() {
  const { friendList } = useContext(MainContext);
  const { firebaseUser: user } = useContext(MainContext);
  const [contract, setContract] = useState();
  const [wallet_min, setwallet_min] = useState(1);
  const [buyIn, setbuyIn] = useState(1);
  const blackJackArray = [
    { value: "Invite friends", label: "Invite friends" },
    { value: "Open game", label: "Open game" },
  ];
  const playersArray = useMemo(() => {
    return friendList.map(el => ({
      value: el.id,
      label: el.value
    }))
  }, [friendList])
  const [playerSelected, setplayerSelected] = useState([]);
  const [gameType, setgameType] = useState("Open game");
  const [smallBlind, setSmallBlind] = useState(1);
  const [gameName, setgameName] = useState('default');
  const [multiplayer, setmultiplayer] = useState(false);
  const [video_hide, setvideo_hide] = useState(false);
  const [audio_hide, setaudio_hide] = useState(false);
  const [media, setmedia] = useState("no-media");
  const [public_game, setpublic_game] = useState(true);
  const [show_public, setshow_public] = useState(false);
  const [loading, setloading] = useState(false);
  const [lobbyLink, setlobbyLink] = useState('');
  const [textlobbyLink, settextlobbyLink] = useState('');
  const [message, setmessage] = useState('');
 
  const [idToken, setidToken] = useState(localStorage.getItem("xtkn"));
  const { account, provider } = useWeb3React();
  const [deviceId] = useState(localStorage.getItem("deviceId"));
  const [balance, setBalance] = useState('');
  const [balanceInUSD, setBalanceInUSD] = useState(0);
  const [walletConnected, setwalletConnected] = useState(localStorage.getItem("isWalletConnected"));
  const [timeoutSelected] = useState({ value: "15", label: "15 second" });
  const [confirmationText, setconfirmationText] = useState('');
  const [confirmationShow, setconfirmationShow] = useState(false);

  const createGame = async (gameId, link) => {
    let tx;
    try {
      if (contract) {
        const depositAmountInMatic = buyIn;
        const valueToSend = ethers.utils.parseUnits(depositAmountInMatic.toString(), 18);
        const minBet = ethers.utils.parseUnits((buyIn).toString(), 18);
        // const { data: { result: { FastGasPrice } = {} } = {} } = await axios.get(`${UrlConfig.gasTrackerUrl}${process.env.REACT_APP_API_TOKEN}`);
       
        // estimeate gasPrice using estimateGas functions
        const estimatedGasLimit = await contract.estimateGas.createGame(
          gameId, "BlackJack_Tables", minBet, [user.uid, valueToSend, account, user.photoURL, user.displayName], parseInt(timeoutSelected.value), media, public_game, false, {
          value: valueToSend,
          gasPrice: await provider.getGasPrice() || ethers.utils.parseUnits('35', 'gwei'),
          nonce: await provider.getTransactionCount(account),
        }
        );
        console.log("limit", ethers.BigNumber.from(Math.floor(Number(estimatedGasLimit) * 1.2)), Math.floor(Number(estimatedGasLimit) * 1.2));
        tx = await contract.createGame(gameId, "BlackJack_Tables", minBet, [user.uid, valueToSend, account, user.photoURL, user.displayName], parseInt(timeoutSelected.value), media, public_game, false, {
          value: valueToSend,
          gasPrice: await provider.getGasPrice() || ethers.utils.parseUnits('35', 'gwei'),
          nonce: await provider.getTransactionCount(account),
          gasLimit: ethers.BigNumber.from(Math.floor(Number(estimatedGasLimit) * 1.2)),
        });
        setconfirmationText('Waiting For Block Confirmation');
        const receipt = await tx.wait();
        return receipt;
      }
    } catch (e) {
      if (e.message.includes("transaction was replaced")) {
        window.location.href = link;
        return;
      }
      if (e.message.includes("execution reverted")) {
        const revertReason = await contract.provider.getTransactionReceipt(tx.hash)
          .then((receipt) => receipt ? receipt.reason : "No revert reason");
        toast.error(<span><Translate>Revert Reason: {revertReason}</Translate></span>, {
          duration: 4000,
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        });
        setconfirmationShow(true);
        setloading(false);
        setconfirmationText('Waiting For Block Confirmation');
        return false;
      } else {
        if (e.message.includes("insufficient funds")) {
          toast.error(<span><Translate>Insufficient funds to create a game</Translate></span>, {
            duration: 4000,
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
        }
        setconfirmationShow(true);
        setloading(false);
        return false;
      }
    }
  };

  const handleSubmit = async (e) => {
    let link;
    try {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      const isValid = validate();
      if (isValid) {
        setloading(true);
        let gameId = v4();
        gameId = gameId.split("-").join("");
        setconfirmationText('Please Confirm Transaction');
        const res = await axios.post(UrlConfig.tableCreateAPI, {
          game: "BlackJack_Tables",
          gameName,
          tid: gameId,
          betMin: buyIn,
          ballance: parseFloat(balance),
          coinName: 'Matic',
          buyIn: parseFloat(buyIn),
          smallBlind: parseFloat(buyIn),
          rTimeout: parseInt(timeoutSelected.value),
          public: public_game,
          allowWat: false,
          adminStart: false,
          invPlayers: playerSelected.map(el => el.value),
          media: media,
          gameTime: 0,
          MaticUSD: parseFloat(balanceInUSD),
          wid: account,
        }, {
          headers: {
            device: deviceId || device,
          },
          withCredentials: true,
          credentials: 'include',
        });
        if (res.data.error === "no error") {
          link = res.data.link;
          console.log("calling create game");
          const tx = await createGame(gameId, link);
          if (!tx) {
            setloading(false);
            await axios.get(UrlConfig.resetSessionAPI, {
              headers: {
                uid: user.uid
              },
              withCredentials: true,
              credentials: 'include',
            })
            return;
          }
          window.location.href = res.data.link;
          return;
        } else if (res.data.error === "missing auth token") {
          await cookieSetter(account, handleSubmit, setidToken);
        } if (res.data.error === "cookie expired") {
          window.location.href = '/';
        } else if (res.data.error === "User inGame") {
          link = res.data.link;
          window.location.href = res.data.link;
          return;
        } else {
          toast.error(<Translate>{res.data.error}</Translate>, {
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
        }
      }
    } catch (e) {
      if (link) {
        await axios.get(UrlConfig.resetSessionAPI, {
          headers: {
            uid: user.uid
          },
          withCredentials: true,
          credentials: 'include',
        })
      }
    }
  };

  function validate() {
    let error = "";
    if (balance < buyIn) {
      toast.error(
        <span>
          <Translate>You are missing </Translate>
          <b>{(buyIn - balance).toFixed(2)} POL</b>
          <Translate> to play</Translate>
        </span>,
        {
          duration: 4000,
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        }
      );
      return false;
    }

    if (!public_game && playerSelected.length === 0) {
      error = "Please invite Player for private table";
    }
    if (error) {
      setmessage(error);
      return false;
    } else {
      return true;
    }
  };

  function updatesWalletCoins(event) {
    setwallet_min(parseFloat(event.target.value));
  }

  const handleChange = (event) => {
    if (event.value === 'Invite friends') {
      setgameType('Invite friends');
      setpublic_game(true);
      setshow_public(true);
      setmultiplayer(true);
    } if (event.value === 'Open game') {
      setgameType('Open game');
      setpublic_game(true);
      setshow_public(false);
      setmultiplayer(false);
      setmessage("");
    }
  };


  function hendleplayerSelect(event) {
    setplayerSelected(event);
  }

  function toggleAudio(e) {
    if (e.target.checked) {
      setmedia("audio");
      setvideo_hide(true);
      toast.success(
        <span className="wallet-image">
          <Translate>Live voice interaction</Translate>
        </span>,
        {
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        }
      );
    } else {
      setmedia("no-media");
      setvideo_hide(false);
    }
  };

  function toggleVideo(e) {
    if (e.target.checked) {
      setmedia("video");
      setaudio_hide(true);
      toast.success(
        <span className="wallet-image">
          <Translate>Live video chat</Translate>
        </span>,
        {
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        }
      );
    } else {
      setmedia("no-media");
      setaudio_hide(false);
    }
  };
  function togglePublic(e) {
    setpublic_game(e.target.checked);
    if (e.target.checked == true) {
      toast.success (
        <span className="wallet-image">
          <Translate>Anyone can join the game</Translate>
        </span>,
        {
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        }
      );
    }
  };

  const confirmationClose = () => {
    setconfirmationShow(!confirmationShow);
  };

  useEffect(() => {
    const getMaticUsdPrice = async (matic) => {
      const res = await axios.get(UrlConfig.maticUsdPriceUrl);
      if (res.data) {
        const curentPrice = res.data.market_data.current_price.usd
        setBalanceInUSD(curentPrice * matic);
      }
    }
    const getBalance = async () => {
      if (account) {
        const value = await provider?.getBalance(account);
        if (value)
          setBalance(Number(ethers.utils.formatEther(value)).toFixed(5));
        getMaticUsdPrice(Number(ethers.utils.formatEther(value)));
        setwalletConnected(true);
        setmessage("");
      } else {
        setwalletConnected(false);
        setmessage("Crypto wallet is not connected");
      }
    }
    getBalance()
  }, [account, provider])

 

  useEffect(() => {
    if (account && provider) {
      const signer = provider.getSigner();
      let cont = new ethers.Contract(process.env.REACT_APP_BLACKJACK_CONTRACT_ADDRESS, BlackjackAbi, signer);
      setContract(cont);
    }
  }, [account, provider])

  const customStyles = {
    option: (provided) => ({
      ...provided,
      borderBottom: "1px solid #2d2d32",
      color: "#ddd",
      backgroundColor: "#191b25",
    }),
    control: () => ({
      border: "1px solid #51525f",
      borderRadius: ".25rem",
      display: "flex",
      padding: "2px 10px",
      backgroundColor: "transparent",
      color: "#fff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: "#8461dc",
        color: "#fff",
      },
    }),
  };

  return (
    <>
      {!walletConnected ? (
        <CryptoNOT />
      ) : (
        <Layout>
          <div className="bet-weje">
            <div className="container">
              <div className="row">

                <div className="col-md-6 m-auto">
                  <div className="bet-weje-content">
                    <h2>
                    BlackJack
                      <small className='bet-weje-content-small'><Translate>{gameType}</Translate></small>
                    </h2>
                    <img
                      src={PolyLogo}
                      alt="BlackJack"
                      className="pol-coin"
                    />

                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                        <div className="row">
                        <div className="col-md-5">
                        <Form.Group controlId="formBasicFirst" >
                            <Form.Label><Translate>Select game</Translate></Form.Label>
                            <Select
                              name="game"
                              options={blackJackArray}
                              styles={customStyles}
                              onChange={handleChange}
                              defaultValue={{
                                value: "Open game",
                                label: "Open game",
                              }}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-5">
                        {multiplayer ? (
                              <Form.Group controlId="formBasicFirst">
                                 <Form.Label><Translate>Select players</Translate></Form.Label>
                                <Select
                                  id="SelectPlayer"
                                  name="player"
                                  options={playersArray}
                                  styles={customStyles}
                                  closeMenuOnSelect={false}
                                  isMulti
                                  onChange={hendleplayerSelect}
                                />
                              </Form.Group>
                            ) : (
                              null
                            )}
                        </div>
                        </div>

                        </div>
                        <div className="col-sm-12">
                          <Form.Group
                            controlId="formBasicFirst"
                            className="coin-input">
                            <Form.Label>
                              <b>POL<span style={{ fontSize: "10px" }}>(MATIC)</span></b>
                            </Form.Label>
                            <div className="tooltipHover">
                              <img src={walletIcon} alt="info" className="iconInfo" />
                              <small className="tooltiptext matic-text">
                                POL<span style={{ fontSize: "10px" }}>(MATIC)</span> <Translate>Founds</Translate>
                              </small>
                            </div>
                            <div className="your-wallet">
                              <img src={walletIcon} alt="info" className="PolygonCoins" />
                              <p className="your-wallet-p">
                                {balance}
                              </p>
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <MinimumBet
                            value={wallet_min}
                            onChange={updatesWalletCoins}
                            onSelect={updatesWalletCoins}
                            smallBlind={smallBlind}
                            buyIn={buyIn}
                            setSmallBlind={setSmallBlind}
                            setbuyIn={setbuyIn}
                          />
                        </div>

                        <div className="col-md-6">
                          <div className="invite-radio">
                            {!audio_hide ? (
                              <>
                                <div className="filter-input checkbox">
                                  <input
                                    type="checkbox"
                                    id="video"
                                    name="media"
                                    onChange={toggleAudio} />
                                  <label className="radio-label-audio">
                                    <span className='audio-text'><Translate>Audio</Translate></span>
                                  </label>
                                  <i
                                    style={{ color: "#8461dc" }}
                                    className="fa fa-headphones came"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </>
                            ) : null}

                            {!video_hide ? (
                              <>
                                <div className="filter-input checkbox">
                                  <input
                                    type="checkbox"
                                    id="video"
                                    name="media"
                                    onChange={toggleVideo} />
                                  <label className="radio-label-video">
                                    <span className='video-text'><Translate>Video</Translate></span>
                                  </label>
                                  <i
                                    style={{ color: "#8461dc" }}
                                    className="fa fa-video-camera came"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </>
                            ) : null}

                            {show_public ? (
                              <>
                                <div className="filter-input checkbox">
                                  <input
                                    type="checkbox"
                                    id="Public"
                                    name="public"
                                    defaultChecked={public_game}
                                    onChange={togglePublic}
                                  />
                                  <label className="radio-label-public">
                                    <span className='public-text'><Translate>Public game</Translate></span>
                                  </label>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-12">
                          {loading ? (
                            <div id="cover-spin">
                              <img
                                src={loadericon}
                                alt="loader"
                                id="imageLoader" />
                              <p>
                                <small><Translate>Creating your Game..</Translate></small>
                              </p>
                            </div>
                          ) : null}
                          <div className="errorBoxMessage">
                            <p className="Error"><Translate>{message}</Translate></p>
                            <a
                              style={{ color: "#8461dc" }}
                              href={lobbyLink} >
                              {textlobbyLink}
                            </a>
                          </div>

                          <div className="login-button">
                            <Link to={"/profile"}>
                              <Button className="btn-dark"><Translate>Cancel</Translate></Button>
                            </Link>
                            <Button
                              type="submit"
                              className="l-btn btn-gold btn-play"
                              disabled={loading} >
                              {loading ? (
                                <Spinner animation="border" />
                              ) : (
                                <Translate>Play</Translate>
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>

                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <>
                <Modal
                  show={true}
                  onHide={() => confirmationClose()}
                  centered
                  className="friends-popup pending-popup">
                  <Modal.Header >
                    <Modal.Title>
                      <Spinner animation="border" />
                      <small className='title-pending'>
                        {confirmationText === "Waiting For Block Confirmation" ? (
                          <span style={{ color: '#8461dc' }}><Translate>{confirmationText}</Translate></span>
                        ) : (
                          <Translate>{confirmationText}</Translate>
                        )}
                      </small>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="feed-body">
                      {confirmationText === "Please Confirm Transaction" ? (
                        <Lottie options={confirmLottie} width={250} height={250} />
                      ) : <Lottie options={blockLottie} width={300} height={250} />}
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            ) : null}
          </div>
        </Layout>
      )}
    </>
  );
};

export default BJackCreateTable;