import { useContext, useState, useEffect} from "react";
import PokerAbi from "../ABI/WejeAbi.json";
import BlackjackAbi from "../ABI/BlackjackAbi.json";
import SportsBetAbi from "../ABI/SportsAbi.json";
import { MainContext } from "../context";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";


const useEventListener = () => {
    const [link, setLink] = useState("");
 
    const { firebaseUser } = useContext(MainContext);
    const { provider } = useWeb3React()

    useEffect(() => {
        if(!provider)
            return;
        const signer = provider.getSigner();
        let pokerContract = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, PokerAbi, signer);
        let blackjackContract = new ethers.Contract(process.env.REACT_APP_BLACKJACK_CONTRACT_ADDRESS, BlackjackAbi, signer);
        // let sportsContract = new ethers.Contract(process.env.REACT_APP_SPORTS_CONTRACT_ADDRESS, SportsBetAbi, signer);

        pokerContract.on("GameCreated",async(gameId) => {
            console.log("gameID", gameId)
            const game = await pokerContract.getGameById(gameId);
            if(game && game.players.find(el => el.playerId === firebaseUser.uid)){
                setLink(`/poker/index.html?tableid=${gameId}&gameCollection=${game.gameType}`)
            }
            
        });
        pokerContract.on("PlayerJoined",(gameId, playerId) => {
            if(playerId === firebaseUser.uid){
            setLink(`/poker/index.html?tableid=${gameId}&gameCollection=pokerCP_Tables`)
            }
        });
        blackjackContract.on("GameCreated",async(gameId) => {
            const game = await blackjackContract.getGameById(gameId);
            if(game && game.players.find(el => el.playerId === firebaseUser.uid)){
                setLink(`/poker/index.html?tableid=${gameId}&gameCollection=Blackjack_Tables`)
            }
            
        });
        blackjackContract.on("PlayerJoined",(gameId, playerId) => {
            if(playerId === firebaseUser.uid){
            setLink(`/poker/index.html?tableid=${gameId}&gameCollection=Blackjack_Tables`)
            }
        });
        return () => {
            blackjackContract.off("PlayerJoined")
            blackjackContract.off("GameCreated")
            pokerContract.off("GameCreated");
            pokerContract.off("PlayerJoined");
        }
    },[provider])
    return { link}
}

export default useEventListener;