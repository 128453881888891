import React from 'react';

const LogContainer = ({ 
    ChampionsLeagueData = [], 
    EuropaLeagueData = [],
    EnglandLeagueData = [],
    GermanyLeagueData = [],
    SpainLeagueData = [],
    BrazilLeagueData = [],
    ArgentinaLeagueData = [],
    IndiaLeagueData = [],
    NBAData = [], 
    EuroLeagueData = [], 
    RussiaVTBLeagueData = [], 
    SpainACBLeagueData = [], 
    TurkeyTBSLLeagueData = [], 
    BrazilNBBLeagueData = [], 
    ChinaCBALeagueData = [], 
    NHLData = [], 
    AHLData = [],
    KHLData = [],
    AustralianOpenData = [],
    USOpenData = [],
    }) => {
    const renderData = (data) => {
        if (!Array.isArray(data)) {
            return null; // or return a message like 'No data available'
        }

        return data.map((item, index) => {
            // Define default styles
            let homeStyle = {};
            let awayStyle = {};
            let vsStyle = {};

            // Apply conditional styles based on the choice
            if (item.choice === "home") {
                homeStyle = { fontWeight: 'bold', color: '#8461dc', fontSize: '110%' };
            } else if (item.choice === "away") {
                awayStyle = { fontWeight: 'bold', color: '#8461dc', fontSize: '110%' };
            } else if (item.choice === "tie") {
                vsStyle = { fontWeight: 'bold', color: '#8461dc', fontSize: '110%' };
            }

            return (
                <small key={index} className='games-small'>
                    <span style={homeStyle}>{item.home}</span> 
                    <span className='x-game' style={vsStyle}> X </span>
                    <span style={awayStyle}>{item.away}</span><br/>
                </small>
            );
        });
    };

    return (
      
            <div className='game-logs'>
                <div>
                    {renderData(ChampionsLeagueData)}
                </div>
                <div>
                    {renderData(EuropaLeagueData)}
                </div>
                <div>
                    {renderData(EnglandLeagueData)}
                </div>
                <div>
                    {renderData(GermanyLeagueData)}
                </div>
                <div>
                    {renderData(SpainLeagueData)}
                </div>
                <div>
                    {renderData(BrazilLeagueData)}
                </div>
                <div>
                    {renderData(ArgentinaLeagueData)}
                </div>
                <div>
                    {renderData(IndiaLeagueData)}
                </div>
                <div>
                    {renderData(NBAData)}
                </div>
                <div>
                    {renderData(EuroLeagueData)}
                </div>
                <div>
                    {renderData(RussiaVTBLeagueData)}
                </div>
                <div>
                    {renderData(SpainACBLeagueData)}
                </div>
                <div>
                    {renderData(TurkeyTBSLLeagueData)}
                </div>
                <div>
                    {renderData(BrazilNBBLeagueData)}
                </div>
                <div>
                    {renderData(ChinaCBALeagueData)}
                </div>
                <div>
                    {renderData(NHLData)}
                </div>
                <div>
                    {renderData(AHLData)}
                </div>
                <div>
                    {renderData(KHLData)}
                </div>
                <div>
                    {renderData(AustralianOpenData)}
                </div>
                <div>
                    {renderData(USOpenData)}
                </div>
            </div>

    );
};

export default LogContainer;
