import React from "react";
import pokerGame from "./images/poker-game.png";
import ludoGame from "./images/ludo-game.jpg";
import { Translate } from "react-auto-translate";

const Games = () => {
  return (
    <div className="tabbedcontent about-us">
      <h4 className="title">
      <Translate>Games</Translate> 
      </h4>
        <div className="container">
            <div className="row">
                   <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body help-card-body">
                                <h5 className="group card-title inner list-group-item-heading">
                                    Poker 🔶</h5>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={pokerGame} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text"><b className="step-text">
                                            Pol Polygon LIVE Poker! </b> <Translate>Play your favorite poker games with real players from around the globe, all from the comfort of your home. 
                                            Powered by the advanced Pol Polygon blockchain, enjoy lightning-fast transactions, top-notch security, and a casino gaming experience.</Translate>
                                            </p>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body help-card-body">
                                <h5 className="group card-title inner list-group-item-heading">
                                    Ludo 🪆</h5>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={ludoGame} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text"><b className="step-text">Strategic Pol LIVE Ludo! </b> <Translate>the classic board game for two to four players, now available to play online on the Pol Polygon network. 
                                        Join the excitement, roll the dice, and race your tokens to victory in our immersive digital Ludo experience.</Translate></p>
                                    </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body">
                                <h5 className="group card-title inner list-group-item-heading">
                                    Esports 🏀</h5>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={Sports} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text">Empowering Esports with a thrill of sports betting! Bet live on football, basketball, hockey, tennis, and more. Create a winner bet and share your picks online. The one who wins the most games take the Pol amount.</p>
                                    </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body">
                                <h5 className="group card-title inner list-group-item-heading">
                                    Social Media 😃</h5>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={Template} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text">The dopamine rush from online interactions becomes a thrilling boost with Pol Polygon network. 
                                        Elevate your social media experience, and join our community.</p>
                                    </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </div>
  );
};

export default Games;
