import axios from "axios";
import toast from "react-hot-toast";
import Lottie from 'react-lottie';
import loading from "./assets/animation/loading.json";
import firebase from "./firebase";
import UrlConfig from "./utils/ApiConfig";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
  };
const db = firebase.firestore();

export const cookieSetter = async (account, callback, setidToken) => {
  try {
    // Set new id Token and call cookieSetterAPI
    const ridToken = await firebase.auth().currentUser.getIdToken(true);
    localStorage.setItem("idtoken", ridToken);
    setidToken(ridToken);
    const response = await axios.get(`${UrlConfig.cookieSetterAPI}`, {
      headers: {
        idtoken: ridToken,
        wid: account,
      },
      params: {
        isLogout: "no",
        refreshCookie: "yes"
      },
      withCredentials: true,
      credentials: "include"
    });
    if (response.data.error == "no error") {
       callback();
    }
  } catch (error) {
      console.log("cookieSetter error: " + error);
  }
}

export const getDoc = async (u, token) => {
    const idTokenStorage = token || localStorage.getItem('idtoken');
    const response = await axios.get(UrlConfig.getDocAPI, {
      headers: { 
         wid: localStorage.getItem("walletid"),
       },
      withCredentials: true,
      credentials: "include",
    });
      if (response.data.success) {
        localStorage.setItem('provider', response.data.userDoc.widProvider);
        if (typeof response.data.sessCookie === 'string')
          localStorage.setItem('scookie', response.data.sessCookie);
          sessionStorage.setItem('scookie', response.data.sessCookie);
        return {
          ...response.data.doc,
          uid: u,
          doc: response.data.doc,
          userDoc: response.data.userDoc,
          inGame: response.data.inGame,
          inQue: response.data.inQue,
          currency: response.data.currency,
          datetimeNow: response.data.datetimeNow
        };
      }
      if (response.data.error === "missing auth token") {
          const account = localStorage.getItem("walletid");
          const setidToken = (newToken) => localStorage.setItem("idtoken", newToken); 
          await cookieSetter(account, () => getDoc(u, idTokenStorage), setidToken);
          window.location.reload(false);
      }
      if (!response.data.success && response.data.error === "id token too old") {
        const idtoken = await firebase.auth().currentUser.getIdToken(true);
        toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
              style: {
                borderRadius: "5px",
                background: "#333",
                color: "#fff",
              },
          });
      }else if(response.data.error === "wallet mismatch"){
        //force signout
        firebase
        .auth()
        .signOut()
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(
          UrlConfig.cookieSetterAPI,
          {
            params: {
              isLogout: "yes",
              uid: u,
            },
            headers: {
              idtoken: localStorage.getItem("idtoken") || token,
              wid: localStorage.getItem("walletid"),
            },
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.error === "no error") {
            localStorage.removeItem("idtoken");
        localStorage.removeItem("walletid");
        localStorage.removeItem("provider");
        window.location.href = "/"
          }
        })
        .catch((error) => {
          console.log("Error req", error);
        });
      }
      else if(u) {
          const db = firebase.firestore();
          const querySnapshot = await db
            .collection(`${process.env.REACT_APP_DB_PREFIX}users`)
            .where("nickname", "==", u)
            .get();
            if(querySnapshot.docs.length)
           return await getDoc(querySnapshot.docs[0].id, querySnapshot.docs[0].id)
      }
  };


export const chatHistory = async (p, u) => {
  //u=uid p=peerId
  var x = [];
  const db = firebase.firestore();
  const snapshot = await db
    .collection(`${process.env.REACT_APP_DB_PREFIX}messanger`)
    .doc(u)
    .collection("chats")
    .where("isRead", "==", false)
    .where("from", "==", p)
    .where("to", "==", u)
    .orderBy("date", "asc")
    .limitToLast(500)
    .get();
  snapshot.forEach(async (doc) => {
    x.push(doc.data());
    await db
      .collection(`${process.env.REACT_APP_DB_PREFIX}messanger`)
      .doc(u)
      .collection("chats")
      .doc(doc.id)
      .update({
        isRead: true,
      });
  });
};

export const getBaseStats = async (coll, u) => {
    const idTokenStorage = localStorage.getItem('idtoken');
    const res = await axios.get(UrlConfig.getDocAPI, {
        headers: { 
          wid: localStorage.getItem("walletid"),
         },
        withCredentials: true,
        credentials: "include",
    });
    return res.data.doc;
};


// time calculation
export const timeDifference = (time1) => {
  const current = new Date();
  const previous = new Date(time1);
  let msPerMinute = 60 * 1000;
  let msPerHour = msPerMinute * 60;
  let msPerDay = msPerHour * 24;
  let msPerMonth = msPerDay * 30;
  let msPerYear = msPerDay * 365;

  let elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return Math.round(elapsed / msPerYear) + " years ago";
  }
};

export const getPost = async (uid, value, paginate, page) => {
  const res = await axios.get("https://show-feed-t3e66zpola-uc.a.run.app/", {
    params: {
      usid: uid,
      paginate: paginate,
      page: page,
    },
  });
  if (res.data.error === "no error") {
    return res.data.FeedArray;
  } else return res.data;
};

export const updateReadNotification = async (uid, notiId) => {
  const db = firebase.firestore();
   await db
    .collection(`${process.env.REACT_APP_DB_PREFIX}messanger`)
    .doc(uid)
    .collection("noti").doc(notiId).update({
		isRead: true
	})
};

export const getImage = (img) => {
  return (
    "https://storage.googleapis.com/mycool-net-app.appspot.com/" +
    img.split(".com/")[1]
  );
};

export const getFeed = async (uid, postId) => {
  try {
    const res = await axios.get(
      "https://single-post-t3e66zpola-uc.a.run.app/",
      {
        params: {
          usid: uid,
          postId,
        },
      }
    );
    if (res.data.error === "no error") {
      return res.data;
    }
    return res.data;
  } catch (error) {
    console.log("Error in get feed", error);
  }
};

export const getTimeline = async (ownerUid, uid, paginate, page) => {
  try {
    const res = await axios.get("https://timeline-t3e66zpola-uc.a.run.app/", {
      params: {
        ownerUid,
        usid: uid,
        paginate,
        page,
      },
    });
    if (res.data.error === "no error") {
      return { post: res.data.FeedArray, count: res.data.queryCount };
    }
    return res.data;
  } catch (error) {
    console.log("Error in getTimeline =>", error);
  }
};

// Function to fetch user information based on user ID
export const getUserInfo = async(userID) => {
    const userDoc = await db.collection(`${process.env.REACT_APP_DB_PREFIX}users`).doc(userID).get();
    return {...userDoc.data(), uid: userID};
    
}

export const getUserStats = async(userID) => {
    const userDoc = await db.collection(`${process.env.REACT_APP_DB_PREFIX}baseStats`).doc(userID).get();
    return {...userDoc.data(), uid: userID};
}