
import axios from "axios";

export const getAsset = async (chain, token_address, token_id) => {
  const asset = await axios.get(
	`https://api.opensea.io/v2/chain/matic/contract/0x48ff40070eaefbef26e1cef07cd202643c02e9e1/nfts/${token_id}`,  { headers: { 'X-API-KEY': 'f57074f33c6844e08172d47c9592c888'}}
  );
  return asset.data;
};

export const getAssets = async (slug, size, next) => {
  const asset = await axios.get(
	`https://api.opensea.io/v2/collection/${slug}/nfts?limit=${size}&next=${next}`, { headers: { 'X-API-KEY': 'f57074f33c6844e08172d47c9592c888'}}
  );
  return asset.data;
};

export const getAssetsBySlug = async (slug) => {
  const asset = await axios.get(
    `https://testnets-api.opensea.io/api/v1/assets?collection_slug=${slug}`
  );
  return asset.data.assets;
};


export const buyItem = async (
  accountAddress,
  asset_contract_address,
  token_id,
  web3
) => {
  try {
    console.log("buyOrder =>");
  } catch (error) {
    console.log("Error in buyItem =>", error);
  }
};
