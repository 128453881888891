import React from "react";
import MetaMaskInstall from "./images/Install-metamask.png";
import WejeAccount from "./images/weje-account.png";
import CreateAccount from "./images/create_account.png";
import MaticSent from "./images/welcome-weje.png";
import { Translate } from "react-auto-translate";

const Crypto = () => {
  return (
    <div className="tabbedcontent about-us">
      <h4 className="title">
        Create a WEJE account!
      </h4>
        <div className="container">
            <div className="row">
                <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body help-card-body">
                                <h6 className="group card-title inner list-group-item-heading">
                                    <span className="num-title step-text">① </span> <Translate>Set up a crypto wallet</Translate></h6>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={MetaMaskInstall} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text">Install <a className="metamask-link" target="_blank" href="https://metamask.io/download/" rel="noreferrer">Metamask</a> <Translate>browser extension.</Translate></p>
                                        <p className="group inner list-group-item-text">Install <a className="coinbase-link" target="_blank" href="https://www.coinbase.com/wallet/downloads" rel="noreferrer">Coinbase</a> <Translate>browser extension.</Translate></p>
                                    </div>
                            </div>
                        </div>
                    </div>
 
                    <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body help-card-body">
                                <h6 className="group card-title inner list-group-item-heading">
                                <span className="num-title step-text">② </span> <Translate>Connect your wallet</Translate></h6>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={WejeAccount} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text"><span className="min-step-text">① </span> <Translate>Enter your provider password on your crypto wallet prompt</Translate></p>
                                        <p className="group inner list-group-item-text"><span className="min-step-text">② </span> <Translate>Click on</Translate> <b><Translate>'Connect Wallet'</Translate></b> <Translate>button || Refresh the page</Translate></p>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body help-card-body">
                                <h6 className="group card-title inner list-group-item-heading">
                                <span className="num-title step-text">③ </span> <Translate>Create a</Translate> <b>WEJE</b> <Translate>account</Translate></h6>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={CreateAccount} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text"><span className="min-step-text">① </span> <Translate>Agree to terms and conditions</Translate></p>
                                        <p className="group inner list-group-item-text"><span className="min-step-text">② </span> <Translate>Click</Translate> <b> <Translate>Create Account</Translate> </b></p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="item col-xs-6 col-lg-6 mobile-view-crypto">
                        <div className="thumbnail card-crypto">
                            <div className="caption card-body help-card-body">
                                <h6 className="group card-title inner list-group-item-heading">
                                <span className="num-title step-text">④ </span> <Translate>Welcome to</Translate> WEJE! 😃</h6>
                                    <div className="img-event">
                                        <img className="group list-group-image img-fluid" src={MaticSent} alt="" />
                                    </div>
                                    <div className="info-help">
                                        <p className="group inner list-group-item-text">
                                            <span className="min-step-text"><b className="con-now"><Translate>Congratulations!</Translate></b> <Translate>Now, let's play!</Translate></span></p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default Crypto;
