import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import { Translate } from "react-auto-translate";
import loading from "../../assets/animation/loading.json";
import "./report.css";

const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
};

const ReportUserPopup = ({ peer, idToken }) => {
  const [step, setStep] = useState();

  const handleStep = async (value) => {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
        duration: 6000,
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
    });
    const res = await axios.get(
      "https://report-t3e66zpola-uc.a.run.app/reportUser",
      {
        headers: {
          idtoken: idToken,
        },
        params: {
          frId: peer,
          subject: value,
        },
      }
    );
    if (res.data.error === "no error") {
        toast.success(<span><Translate>Thank you for letting us know</Translate></span>, {
            style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
        });
      setStep(true);
    } else if (res.data.error === "you cant report this user again") {
        toast.error(<span><Translate>You've already reported this user</Translate></span>, {
            style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
        });
      setStep(true);
    } else if (res.data.error !== "no error"){
        toast.error(<Translate>{res.data.error}</Translate>, {
            id: "toast-error",
            style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
            },
        });
	    }
  };

  return (
    <div className="report-user-popup">
      <div className="report-popup">
        <div className="report-popup-header">
          <h2><Translate>Report</Translate></h2>
        </div>

        {!step ? (
          <div className="report-popup-content">
            <h4><Translate>Please select a problem</Translate></h4>
            <p>
            <Translate>If you think this text putting you in immediate danger, call local emergency services immediately</Translate>
            </p>
            <ul>
                <li onClick={() => handleStep("Fake account")}>
                  <Translate>Fake account</Translate>
                </li>
                <li onClick={() => handleStep("Fraud or a scam")}>
                  <Translate>Fraud or a scam</Translate>
                </li>
                <li onClick={() => handleStep("Texting inappropriate things")}>
                  <Translate>Texting inappropriate things</Translate>
                </li>
                <li onClick={() => handleStep("Distributing text messages")}>
                  <Translate>Distributing text messages</Translate>
                </li>
                <li onClick={() => handleStep("Something else")}>
                  <Translate>Something else</Translate>
                </li>
              </ul>
          </div>
        ) : (
          <div className="report-popup-success">
            <h4>
              <Translate>You have reported this person successfully.</Translate>
            </h4>
            <p>
              <Translate>
                Thank you for letting us know! We review your report carefully, and
                we will take action according to the user behavior.
              </Translate>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default ReportUserPopup;
