import React from "react";
import FeedMenu from "./index";
import Layout from "../layout/layout";

class Feed extends React.Component {
  render() {
    return (
      <Layout>
        <FeedMenu />
      </Layout>
    );
  }
}

export default Feed;
