import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom"
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Web3Provider from "./components/cryptoWallet/Web3ReactProvider";
ReactDOM.render(
  <React.StrictMode>
    <Router>
    <div id="google_translate_element"></div>
    <Web3Provider>
		<App />
    </Web3Provider>
	</Router>
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorker.register();
