import React, { useState } from "react";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  TumblrShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  TumblrIcon,
  FacebookMessengerIcon,
} from "react-share";

const Share = ({ shareuserId }) => {
  const ticketId = shareuserId;

  const shareUrl = `https://weje.com/sports-tickets?ticket=${ticketId}`;
  const title = "Weje | Spread the word with your betting challenge!";

  return (
    <div className="buttons_group">
        <div className="share_button">
        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="share_button_share-button" >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
      </div>

      <div className="share_button">
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="share_button_share-button" >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>

      <div className="share_button">
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>

      <div className="share_button">
        <FacebookMessengerShareButton
          url={shareUrl}
          appId="183626020236510"
          className="share-button" >
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton>
      </div>

      <div className="share_button">
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="share_button_share-button" >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>

      <div className="share_button">
        <LinkedinShareButton
          url={shareUrl}
          className="share_button_share-button"
          title={title} >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

      <div className="share_button">
        <TumblrShareButton
          url={shareUrl}
          title={title}
          className="share_button_share-button" >
          <TumblrIcon size={32} round />
        </TumblrShareButton>
      </div>
    </div>
  );
};

export default Share;