const emojis = [
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-1.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-2.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-4.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-5.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-6.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-7.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-8.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-9.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-10.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-11.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-12.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-13.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-14.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-15.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-16.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-17.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-18.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-19.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-20.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-21.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-22.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-23.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-24.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-25.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-26.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-27.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-28.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-29.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-30.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-31.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-32.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-33.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-34.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-35.png",
  "https://storage.googleapis.com/mycool-net-app.appspot.com/emojis/emoji-36.png",
];

export default emojis;
