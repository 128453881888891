import { useEffect, useState } from "react";
import Layout from "../layout/layout";
import { Link } from "react-router-dom";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Translate } from "react-auto-translate";
import Crypto from "./Crypto";
import Games from "./Games";
import "./help-center.css";
import { useParams } from "react-router-dom";

const HelpCenter = () => {
  const { tab } = useParams();
  const [key, setKey] = useState(0);
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [key]);

  useEffect(() => {
      
    if (tab) {
      if (tab === "crypto") {
        setKey(0);
      } else if (tab === "games") {
        setKey(3);
      } else {
        setKey(0);
      }
    }
  }, [tab]);


  return (
    <Layout>
      <>
        <div className="help-center-section">
          <div className="container">
            <div className="help-center-content">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={tab || "crypto"} >
                <Row>
                  <Col sm={2}>
                    <div id="left-tabs">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            to="/help-center/crypto"
                            eventKey="crypto" >
                            <Translate>Account</Translate>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            to="/help-center/games"
                            eventKey="games" >
                            <Translate>Games</Translate>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link>
                        <div className="copyright-text text-center">
                            <p>© 2024 <Link to="/">WeJe</Link></p>
                         </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Col>

                  <Col sm={10}>
                    <Tab.Content>
                      <Tab.Pane eventKey="crypto">
                        <Crypto />
                      </Tab.Pane>
                      <Tab.Pane eventKey="games">
                        <Games />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default HelpCenter;
