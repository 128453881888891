import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC_h4VhJPDNrSKTDAppRedNjEQ65Vv3VSc",
  authDomain: "weje-2023.firebaseapp.com",
  projectId: "weje-2023",
  storageBucket: "weje-2023.appspot.com",
  messagingSenderId: "198324805267",
  appId: "1:198324805267:web:916d8da4a768b59b4f3766",
  measurementId: "G-ZRBCBVWYBR"
};

// Initialize Firebase instance
export const firebaseApp = firebase.initializeApp(firebaseConfig);
// Initialize other services on firebase instance
firebase.firestore();
firebase.storage();
firebase.database();

export default firebase;

// try {
//   if ("Notification" in window)
//     firebase.messaging(firebaseApp).onMessage((payload) => {
//       const { data, notification: { title, body, icon } = {} } = payload || {};
//       if (Notification.permission === "granted") {
//         // If it's okay let's create a notification
//         navigator.serviceWorker.ready.then((registration) => {
//           registration.showNotification(title, {
//             ...data,
//             body,
//             icon,
//             click_action: data.click_action,
//             image: "",
//             tag: data.click_action,
//             vibrate: [200, 100, 200, 100, 200, 100, 200],
//           });
//         });
//       }
//     });
// } catch (error) {
//   console.log("error in messaging", error);
// }
