import React from "react";

const GameContext = {
  friendList: [],
  blockList: [],
  following: [],
  followers: [],
  baseStats: null,
  setBaseStates: () => {},
  feeds: [],
  token: null,
  firebaseUser: null,
  setFirebaseUser: () =>{},
  setToken : () => {},
  notification: [],
  setFeeds: () => {},
  setFollowers: () => {},
  setFollowing: () => {},
  setNotification: () => {},
  setBlockList: () => {},
  setFriendList: () => {},
  docData: {},
  setDocData: () => {},
};

export const MainContext = React.createContext(GameContext);
