
import { Translate } from "react-auto-translate";
import { useHistory } from "react-router-dom";
import "./Card.css";

const Card = ({ nftData, createBuyOrder, createMoralisBuyOrder, tokenId }) => {
  const history = useHistory();
  const {
    description,
    image_url,
    name,
  } = nftData || {};

  return (
    <div
      className="col-md-4 mb-4 mobile-nft"
      onClick={() => {
        history.push(`/marketplace/nft/${tokenId}`);
      }} >
      <div className="nft-card">
        <img src={image_url} alt="" />
        <div className="nft-card-body">
          <h2>{name}</h2>
          <h5><Translate>{description}</Translate></h5>

          {/*<h5>Owned by - {ownerUser?.username}</h5>
          <h5>Created by - {user?.username}</h5>*/}

          <div className="nft-card-btn">
              <button >
              <Translate>Make Offer</Translate>
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;