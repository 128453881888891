import Emojis from "../../emoji-url";

const EmojiPicker = ({ onEmojiClick }) => {
  return (
    <div className="emoji-picker">
      {Emojis.map((emoji, i) => (
        <div className="emoji-img">
          <img
            src={emoji}
            alt="LV-emoji"
            key={i}
            onClick={(e) => onEmojiClick(e, emoji)}
          />
        </div>
      ))}
    </div>
  );
};

export default EmojiPicker;
