import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Translate } from "react-auto-translate";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAssets } from "../../../collection";
import Layout from "../../layout/layout";
import CardListing from "./Card/CardListing";
import loadingicon from "../../../assets/images/header/loader.gif";

const Nft = () => {
  const [size] = useState(18);
  const [contination, setContinuation] = useState("");
  const [assets, setAssets] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const createSellOrder = async (values) => {
  };
  const createBuyOrder = async (values) => {};
  const createMoralisBuyOrder = async (values) => {
  };

  const dd = async () => {
    //lasvegaspandas = "0xa5a18f18f8191aea96bd6fd6be08d1f9cf794050"
    const ass = await getAssets("lasvegaspandas", size, contination);
    setContinuation(ass.next);
    setAssets([...assets, ...ass.nfts]);
  };

  useEffect(() => {
    dd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
    <div className="nft-page">
      <div className="container m-container">
        <div className="wallet-btn"></div>
        <span id="top"></span>
        <div
          className="scroll-btn"
          onClick={() => {
            let ele = document.getElementById("top");
            if (ele) {
              ele.scrollIntoView({ behavior: "smooth" });
            }
          }} >
          <i className="fa fa-arrow-circle-up" aria-hidden="true"></i>
        </div>
        <InfiniteScroll
          dataLength={assets.length} //This is important field to render the next data
          next={dd}
          hasMore={assets.length < 2280}
          loader={
            <div className="nft-loader">
              <img src={loadingicon} alt="" />
            </div>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b><Translate>Yay! You have seen it all</Translate></b>
            </p>
          } >
          <CardListing
            list={assets}
            createBuyOrder={createBuyOrder}
            createSellOrder={createSellOrder}
            createMoralisBuyOrder={createMoralisBuyOrder}
            dd={dd}
          />
        </InfiniteScroll>
        <Modal
          show={showAlert}
          onHide={() => setShowAlert(false)}
          size="lg"
          className="friends-popup wallet-not-con"
          centered >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h4><Translate>Wallet not Connected</Translate></h4>
            <p>
              <Translate>
                Please connect your wallet before making any buy or sell offer
              </Translate>
            </p>
            <Button onClick={() => setShowAlert(false)}>
              <Translate>Close</Translate>
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  </Layout>  
  );
};

export default Nft;
