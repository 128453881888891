import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Layout from "../layout/layout";
// import PlaceMarket from "../place_market";
// import Followers from "../followers/tablelist";
// import Following from "../following/tablelist";
// import loading from "../../assets/animation/loading.json";
// import QPost from "../quickPost/index";
// import QMatch from "../quickMatch/index";
import VideoComponent from "../videos/video.jsx";
import MaticPriceChart from "../feed/post/MaticPriceChart.jsx";
import Level from "../level";
import RecentlyPlayedWith from "../friends/recentlyplayedwith";
import info from "../../assets/images/icons/info_30.png";
import "./profile.css";
import brnCardSound from "../../assets/sounds/dice.mp3";
import firebase from "../../firebase";
import axios from "axios";
import { Link } from "react-router-dom";
import { MainContext } from "../../context";
import FeedMenu from "../feed/index.jsx";
import UploadingFile from "../settings/uploadingFile";
import ReConnect from "../cryptoWallet/ReConnect";
import { getCookie } from "../../utils/index.js";
import SignInWithGoogle from "../gmail/SignGmail";

// New ux ui
import Level100 from '../../assets/animation/Level100.png';
import Level100GIF from '../../assets/animation/gifs/Level-up.gif';
import Games100 from '../../assets/animation/Games100.png';
import GameGIF from '../../assets/animation/gifs/Games-gif.gif';
import Wins100 from '../../assets/animation/Wins100.png';
import WinsGif from '../../assets/animation/gifs/Wins-gif.gif';
import Lost100 from '../../assets/animation/Lost100.png';
import LostGif from '../../assets/animation/gifs/Lost-gif.gif';
import Friends100 from '../../assets/animation/Friends100.png';
import FriendsGif from '../../assets/animation/gifs/Friends-gif.gif';
import Time100 from '../../assets/animation/Time100.png';
import TimeGif from '../../assets/animation/gifs/Time-gif.gif';
import Ration100 from '../../assets/animation/Ration100.png';
import RatioGif from '../../assets/animation/gifs/Ratio-gif.gif';
import Matic100 from "../../assets/images/header/logo-new.svg";
import UrlConfig from "../../utils/ApiConfig.js";
import { Translate } from "react-auto-translate";
  
let interval;

function Profile() {
    const context = useContext(MainContext);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [hiddenGames, setHiddenGames] = useState([]);
    const [wProvider, setwProvider] = useState(localStorage.getItem('provider')); 

    const [state, setState] = useState({
        isWalletConnect: null,
        deviceId: localStorage.getItem("deviceId"),
        i_sound: localStorage.getItem("i_sound"),
        ip: "",
        idToken: getCookie("idtoken"),
        pending: true,
        renderSignout: false,
        friends: [],
        following: [],
        isFollowing: false,
        isFollowers: false,
        redirect: null,
        playedWith: [],
        image: "",
        balanceLoader: "",
        Que: false,
        QueDate: null,
        notificationShow: false,
        pushTitle: "",
        pushImage: null,
        pushText: "",
        postShow: isMobile ? false : true,
        statShow: isMobile ? false : true,
        maticChartShow: isMobile ? false : true,
        showWallet: false,
        showpopup: "",
        userName: "",
        showCopyTooltip: false,
        stats: {
          maticwins: 1,
          maticlost: 1,
          games: 1,
          wins: 1,
          losses: 1,
          friends: 1,
          followers: 1,
          following: 1,
          Hplayed: 1,
          winLoseRation: 1,
          Level: 0,
          coins: 1,
        },
        uid: null,
        signedin: false,
        nick: "AdiKing",
        lastSign: "Sat, 11 September 2001 09:30 GMT",
        NvalidLogins: 0,
        observerCalled: false,
        winAnimations: [],
        loseDefence: [],
        avatarFrames: [],
        followerShow: false,
        followingShow: false,
        spinTime: "",
        datetimeNow: "",
        qtimer: "",
        ETA: localStorage.getItem("eta"),
        isGotMatch: false,
        QuickPokerCP: true,
        QuickBlackjack: true,
        QuickBackgammon: true,
        QuickLudo: true,
        showQuicktable: false,
        Error: "",
        loading: false,

        //Animations isHovered
        isLevelHovered: false,
        isGamesHovered: false,
        isWinsHovered: false,
        isLostHovered: false,
        isFriendsHovered: false,
        isTimeHovered: false,
        isRatioHovered: false,
      });
    
      useEffect(() => {
        handleInitialize();
        async function fetchData() {
          if (!context.firebaseUser || !context.token) return;
          const { baseStats } = context;
          let img = context?.firebaseUser?.photoURL?.includes("yelloPhantom.png") ?`https://storage.cloud.google.com/weje-2023.appspot.com/Countryflags/${baseStats.userDoc.countryCode}.png` : context.firebaseUser.photoURL
          if (baseStats) {
            setState((prevState) => ({
              ...prevState,
              playedWith: baseStats.PlayedWith,
              idToken: context.token,
              pending: false,
              signedin: true,
              uid: context.firebaseUser?.uid,
              nick: context.firebaseUser?.displayName,
              image: img,
              lastSign: context.firebaseUser?.metadata.lastSignInTime,
              NvalidLogins: prevState.NvalidLogins + 1,
              balanceLoader: baseStats.inGame,
              Que: baseStats.inQue,
              QueDate: baseStats.lastQueDate,
              stats: {
                maticwins: baseStats.total.winCoins.toFixed(2),
                maticlost: baseStats.total.loseCoins.toFixed(2),
                Level: baseStats.total.Level.toLocaleString(),
                Hplayed: baseStats.total.HoursPlayed.toFixed(2),
                wins: baseStats.total.win.toLocaleString(),
                losses: baseStats.total.lose.toLocaleString(),
                friends: baseStats.social.friends.toLocaleString(),
                followers: baseStats.social.followers.toLocaleString(),
                following: baseStats.social.following.toLocaleString(),
                games: baseStats.total.games.toLocaleString(),
                winLoseRation: baseStats.total?.wl_ratio?.toFixed(2) ?? 1,
              },
            }));
          }
    
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.get("modalType")?.toLowerCase() === "welcome") {
            setState((prevState) => ({
              ...prevState,
              userName: urlParams.get("userName"),
              showpopup: "welcome",
              isWalletConnect: true,
            }));
          }
          isPushNotification();
        }
    
        fetchData(); // Call the async fetchData
      }, [context.firebaseUser, context.token, context.baseStats]); // Dependency array includes context    


      useEffect(() => {
        // Load hidden games from local storage on component mount
        const storedHiddenGames = localStorage.getItem('hiddenGames');
        if (storedHiddenGames) {
          setHiddenGames(JSON.parse(storedHiddenGames));
        }
      }, []);

      async function handleInitialize() {
          if (context.friendList.length) {
            setState((prevState) => ({
              ...prevState,
              friends: [...context.friendList],
              isTableVisible: true,
            }));
          } else {
            const UserId = context.firebaseUser?.uid;
            const Uidtoken = context.token;
            if (UserId) {
              try {
                const response = await axios.get(
                  UrlConfig.showFriends,
                  {
                    params: {
                      uid: UserId,
                      mode: "profilePage",
                      viewMore: "no",
                    },
                  }
                );
                if (response.data) {
                  context.setFriendList(response.data.frTable);
                  setState((prevState) => ({
                    ...prevState,
                    friends: [...response.data.frTable],
                    isTableVisible: true,
                  }));
                }
              } catch (error) {
                console.log("Error req", error);
              }
            }
          }
        }

      const handlePlayClick = (videoType) => {
        setSelectedVideo(null); // Reset the video to trigger re-render
        setTimeout(() => setSelectedVideo(videoType), 0); // Set the video type again after a short delay
        // Update the hidden games arrow state
        setHiddenGames((prev) => [...prev, videoType]);
        // Update local storage for games arrow state
        localStorage.setItem('hiddenGames', JSON.stringify([...hiddenGames, videoType]));
    };

      const isPushNotification= () => {
        const paramePath = window.location.search;
        const isNotification = paramePath.slice(1, 13);
        if (isNotification === "notification") {
          this.setState({
            notificationShow: true,
          });
        }
      }

      const toggleState = () => {
        setState((prevState) => ({
          ...prevState,
          statShow: !prevState.statShow,
        }));
      };

      const toggleMaticChart = () => {
        setState((prevState) => ({
          ...prevState,
          maticChartShow: !prevState.maticChartShow,
        }));
      };

      const handleClosePopup = () => {
        setState((prevState) => ({
          ...prevState,
          showpopup: false,
        }));
      };

      const handleMouseEnter = (param) => {
        if (param === 'level') {
          setState((prevState) => ({
            ...prevState,
            isLevelHovered: true,
          }));
        } if (param === 'games') {
            setState((prevState) => ({
              ...prevState,
              isGamesHovered: true, 
            }));
        } if (param === 'wins') {
            setState((prevState) => ({
              ...prevState,
              isWinsHovered: true, 
            }));
        } if (param === 'lost') {
            setState((prevState) => ({
              ...prevState,
              isLostHovered: true, 
            }));
        } if (param === 'friends') {
            setState((prevState) => ({
              ...prevState,
              isFriendsHovered: true, 
            }));
        } if (param === 'time') {
            setState((prevState) => ({
              ...prevState,
              isTimeHovered: true, 
            }));
        } if (param === 'ratio') {
            setState((prevState) => ({
              ...prevState,
              isRatioHovered: true, 
            }));
        }
      };
    
      const handleMouseLeave = (param) => {
        if (param === 'level') {
          setState((prevState) => ({
            ...prevState,
            isLevelHovered: false, 
          }));
        } if (param === 'games') {
            setState((prevState) => ({
              ...prevState,
              isGamesHovered: false, 
            }));
        } if (param === 'wins') {
            setState((prevState) => ({
              ...prevState,
              isWinsHovered: false, 
            }));
        } if (param === 'lost') {
            setState((prevState) => ({
              ...prevState,
              isLostHovered: false, 
            }));
        } if (param === 'friends') {
            setState((prevState) => ({
              ...prevState,
              isFriendsHovered: false, 
            }));
        } if (param === 'time') {
            setState((prevState) => ({
              ...prevState,
              isTimeHovered: false, 
            }));
        } if (param === 'ratio') {
            setState((prevState) => ({
              ...prevState,
              isRatioHovered: false, 
            }));
        }
      };


  return (
    <Layout >
    <div className="profile">
      <div className="container">
        <div className="profile-section">
          <div className="profile-user-grid">
            <div className="card">
              <div className="card-body">
                <div className="profile-box d-flex flex-column align-items-center text-center">
                  {state.image ===
                  "https://storage.googleapis.com/mycool-net-app.appspot.com/default-avatar/yelloPhantom.png" ? (
                    <UploadingFile
                      useridFromParent={state.uid}
                      imageFromParent={state.image}
                      idToken={state.idToken}
                    />
                  ) : (
                    <img
                      src={state.image}
                      alt="Admin"
                      className="rounded-circle"
                    />
                  )}

                  <div className="profile-box">
                    <h4>{state.nick}</h4>
                    <small className="timezones text-muted font-size-sm">
                     <Translate> Time: </Translate> {new Date(state.lastSign).toLocaleString()}
                    </small>

                    <div className="inline-btn">
                      <ReConnect />
                    </div> 
                  </div>

                  {/* <div className="followers-text">
                    {state.isFollowers ? (
                      <p>
                        <small>
                          {state.stats.followers.toLocaleString()}
                          <span onClick={() => this.followers()}>
                            {" "}
                            Followers
                          </span>{" "}
                        </small>
                      </p>
                    ) : (
                      <p className="alignleft">
                        <small>
                          {state.stats.followers.toLocaleString()}
                          <span className="text-secondary">
                            {" "}
                            Followers
                          </span>{" "}
                        </small>
                      </p>
                    )}

                    {state.isFollowing ? (
                      <p className="alignright">
                        <small>
                          {state.stats.following.toLocaleString()}{" "}
                          <span onClick={() => this.following()}>
                            {" "}
                            Following
                          </span>{" "}
                        </small>
                      </p>
                    ) : (
                      <p className="alignright">
                        <small>
                          {state.stats.following.toLocaleString()}{" "}
                          <span className="text-secondary"> Following</span>{" "}
                        </small>
                      </p>
                    )}
                  </div> */}

                  <div className="card-body smallbtndiv">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <Button
                          className={
                            state.statShow
                              ? "active btn-xs btn-dark small-btn"
                              : "btn-xs btn-dark small-btn"
                          }
                          onClick={toggleState}>
                          <i className="fa fa-signal" />
                        </Button>
                        {/* View only on Mobile */}
                        {isMobile ? (
                         <Button
                         className={
                           state.postShow
                             ? "active btn-xs btn-dark small-btn"
                             : "btn-xs btn-dark small-btn"
                         }
                         onClick={() => toggleMaticChart()}>
                        <i className="fa fa-bullseye" aria-hidden="true"></i>
                       </Button>
                      ) : null}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {state.maticChartShow && isMobile ? (
              <div className="card mt-3">
                <div className="card-body">
                <MaticPriceChart />
                </div>
              </div>
            ) : null}

            {state.statShow ? (
              <div className="card mt-3">
                <div className="card-body">
                  <ul className="list-group list-group-flush stats-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <h6 className="mb-0">
                      <img src={Matic100} alt="Admin" className="matic-fix" />
                          <span className="text-white">
                            <b>{state.stats?.maticwins}</b>
                          </span>
                        <i className="fa fa-arrow-up stat-coins" aria-hidden="true" style={{ color: "#32CD32" }}></i>
                        <small>POL<span style={{ fontSize: "10px" }}>(MATIC)</span></small> 
                      </h6>
                      
                    </li>

                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap list-second">
                      <h6 className="mb-0">
                      <img src={Matic100} alt="Admin" className="matic-fix" />
                          <span className="text-white">
                            <b>{state.stats?.maticlost}</b>
                          </span>
                        <i className="fa fa-arrow-down stat-coins" aria-hidden="true" style={{ color: "#FF0000" }}></i>
                        <small>POL<span style={{ fontSize: "10px" }}>(MATIC)</span></small>   
                      </h6>
                    </li>

                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                        onMouseEnter={() => handleMouseEnter('level')}
                        onMouseLeave={() => handleMouseLeave('level')} >
                      <h6 className="mb-0 image-container">
                        <img
                            src={state.isLevelHovered ? Level100GIF :  Level100}
                            alt="Level"
                            className="image"
                        />
                        <Translate>Level</Translate>
                        <div className="tooltipHover">
                          <img alt="info" src={info} className="iconInfo" />
                          <div className="mobileLevelres">
                          <Level
                            uid={state.uid}
                            level={state.stats.Level}
                            friends={state.stats.friends}
                            idToken={state.idToken}
                            playingH={state.stats.Hplayed}
                            coins={state.stats.coins}
                          />
                          </div>
                        </div>
                      </h6>
                      <span className="text-white">
                        <b style={{ color: "#ffffff" }}>
                          {state.stats?.Level}
                        </b>
                      </span>
                    </li>

                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                        onMouseEnter={() => handleMouseEnter("games")}
                        onMouseLeave={() => handleMouseLeave("games")} >
                      <h6 className="mb-0">
                      <img
                        src={state.isGamesHovered ? GameGIF :  Games100}
                        alt="Games"
                        className="image" />
                        <Translate>Games</Translate>
                      </h6>
                      <span className="text-white">
                        {state.stats?.games}
                      </span>
                    </li>

                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                        onMouseEnter={() => handleMouseEnter("wins")}
                        onMouseLeave={() => handleMouseLeave("wins")} >
                      <h6 className="mb-0">
                      <img
                        src={state.isWinsHovered ? WinsGif :  Wins100}
                        alt="wins"
                        className="image" />
                        <Translate>Wins</Translate>
                      </h6>
                      <span className="text-white">
                        {state.stats?.wins}
                      </span>
                    </li>

                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                        onMouseEnter={() => handleMouseEnter("lost")}
                        onMouseLeave={() => handleMouseLeave("lost")} >
                      <h6 className="mb-0">
                      <img
                        src={state.isLostHovered ? LostGif :  Lost100}
                        alt="lost"
                        className="image" />
                        <Translate>Losses</Translate>
                      </h6>
                      <span className="text-white">
                        {state.stats?.losses}
                      </span>
                    </li>

                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                    onMouseEnter={() => handleMouseEnter("friends")}
                    onMouseLeave={() => handleMouseLeave("friends")} >
                      <h6 className="mb-0">
                      <img
                        src={state.isFriendsHovered ? FriendsGif :  Friends100}
                        alt="friends"
                        className="image" />
                        <Translate>Friends</Translate>
                      </h6>
                      <span className="text-white">
                        {state.stats?.friends}
                      </span>
                    </li>
                    
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                        onMouseEnter={() => handleMouseEnter("time")}
                        onMouseLeave={() => handleMouseLeave("time")} >
                      <h6 className="mb-0">
                      <img
                        src={state.isTimeHovered ? TimeGif :  Time100}
                        alt="time"
                        className="image" />
                       <Translate>Playing hours</Translate>
                      </h6>
                      <span className="text-white">
                        {state.stats?.Hplayed}
                      </span>
                    </li>
                    
                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                        onMouseEnter={() => handleMouseEnter("ratio")}
                        onMouseLeave={() => handleMouseLeave("ratio")} >
                      <h6 className="mb-0">
                      <img
                        src={state.isRatioHovered ? RatioGif :  Ration100}
                        alt="ratio"
                        className="image" />
                        <Translate>Won | Lost</Translate>
                      </h6>
                      <span className="text-white">
                        {state.stats?.winLoseRation}
                      </span>
                    </li>

                  </ul>
                </div>
              </div>
            ) : null}
          </div>

          <div className="profile-game-grid">
            <div className="profile-game">
                {/* A JSX comment QuickMatch
                <QMatch
                uid={state?.uid || this.context?.firebaseUser?.uid}
                idToken={state.idToken || this.context.token}
                deviceId={state.deviceId}
                isEnterQ={state.Que}
                QTime={state.QueDate}
                coins={state.stats.coins}
                isMobile={state.postShow}
                interval={interval}
                qtimer={state.qtimer}
                ETA={state.ETA}
                isGotMatch={state.isGotMatch}
                QuickPokerCP={state.QuickPokerCP}
                QuickBlackjack={state.QuickBlackjack}
                QuickBackgammon={state.QuickBackgammon}
                QuickLudo={state.QuickLudo}
                showQuicktable={state.showQuicktable}
                Error={state.Error}
                loading={state.loading}
              />
                */}
              
              <div className="profile-game-list">
                <div className="profile-game-info">
                  <div className="poker-panda">
                    <h5 className="title-poker-panda">Poker</h5>
                    <div className="profile-game-btn">
                      <Link to={"/table-poker"}>
                        <Button className="btn-gold"><Translate>Create Game</Translate></Button>
                      </Link>
                      <Link to={"/jointable/poker"}>
                        <Button className="btn-dark"><Translate>Join Game</Translate></Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <small className="text-panda">🏆 <Translate>Only 1% Rake on each transaction</Translate></small>
                <div className="play-icon-container">
                  <i
                    className="fa fa-play-circle play-icon"
                    aria-hidden="true"
                    onClick={() => handlePlayClick("poker")}
                  ></i>
                  {!hiddenGames.includes("poker") && (
                    <div className="arrow-container">
                      <span>How it works</span>
                    </div>
                  )}
                </div>
              </div>


              <div className="profile-game-list">
                <div className="profile-game-info">
                  <div className="sports-panda">
                    <h5 className="title-poker-panda"><Translate>Sports bet</Translate></h5>
                    <div className="profile-game-btn">
                      <Link
                        to={{
                          pathname: "/sports-tickets/open",
                          state: { friends: state.stats?.friends }
                        }} >
                        <Button className="btn-gold"><Translate>Open Ticket</Translate></Button>
                      </Link>
                      <Link to={"/sports-tickets"}>
                        <Button className="btn-dark"><Translate>Join Ticket</Translate></Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <small className="text-panda">🏀 <Translate>5% Rake: 2.5% goes to the creator!</Translate></small>
                <div className="play-icon-container">
                  <i
                    className="fa fa-play-circle play-icon"
                    aria-hidden="true"
                    onClick={() => handlePlayClick("sportsbet")}
                  ></i>
                  {!hiddenGames.includes("sportsbet") && (
                    <div className="arrow-container">
                      <span>How it works</span>
                    </div>
                  )}
                </div>
              </div>


              <div className="profile-game-list">
                  <div className="profile-game-info">
                    <div className="blackjack-panda">
                      <h5 className="title-poker-panda"><Translate>BlackJack</Translate></h5>
                      <div className="profile-game-btn">
                        <Link to={"/table-blackjack"}>
                          <Button className="btn-gold"><Translate>Create Game</Translate></Button>
                        </Link>
                        <Link to={"/jointable/blackjack"}>
                          <Button className="btn-dark"><Translate>Join Game</Translate></Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <small className="text-panda">❤️ <Translate>Winnings are instantly transferred</Translate></small>
                  <div className="play-icon-container">
                    <i
                      className="fa fa-play-circle play-icon"
                      aria-hidden="true"
                      onClick={() => handlePlayClick("blackjack")}
                    ></i>
                    {!hiddenGames.includes("blackjack") && (
                      <div className="arrow-container">
                        <span>How it works</span>
                      </div>
                    )}
                  </div>
                </div>

              {selectedVideo && <VideoComponent key={selectedVideo} videoType={selectedVideo} />}

                    {/* <div className="profile-game-list">
                        <div className="profile-game-pic">
                        <div className="game-pic">
                            <img
                            src={LudoImage}
                            alt="Ludo"
                            className="logo-fix"
                            />
                        </div>
                        </div>
                        <div className="profile-game-info">
                            <h5>Ludo</h5>
                            <div className="profile-game-btn">
                            <Link to={"/table-ludo"}>
                                <Button className="btn-gold">Create Table</Button>
                            </Link>
                            <Link to={"/jointable/ludo"}>
                                <Button className="btn-dark">
                                    Join Table
                                </Button>
                            </Link>
                            </div>
                        </div>
                    </div> */}
              

                {/* <Market Place />  
                      <div className="profile-game-list">
                      <div className="profile-game-pic">
                        <div>
                          <img
                            src=""
                            alt="Market"
                            className="Market"
                          />
                        </div>
                      </div>
                      <div className="profile-game-info">
                        <h5 className="remove-title-mobile">Market Place</h5>
                        <div className="profile-game-btn">
                          <Button
                            className="btn btn-gold"
                            onClick={
                              () => this.props.history.push("/marketplace")
                            } >
                            Go to Market
                          </Button>
                        </div>
                      </div>
                    </div> 
                */}

            </div>
          </div>

          <div className="profile-feeds-grid">
          {!isMobile ? (
            <FeedMenu />
          ) : null}
            <RecentlyPlayedWith 
              recPlayedWith={state.playedWith || []}
              signedin={state.signedin}
              uid={state.uid}
              idToken={state.idToken}
            />
          </div>
            
        </div>
      </div>
      <audio className="audio-card-btn">
        <source src={brnCardSound}></source>
      </audio>
    </div>


    {/* <Modal
      show={state.showMarket}
      onHide={() => this.closeMarket()}
      centered
      className="market-popup friends-popup"
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title><Translate>Market Place</Translate></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="market-main">
          <PlaceMarket
            avatar={state.image}
            uid={state.uid}
            coins={state.stats.coins}
            nick={state.nick}
            idToken={state.idToken}
            marketPopup={() => this.toggleMarket()}
          />
        </div>
      </Modal.Body>
    </Modal> */}

    {/* <Modal
      show={state.followerShow}
      onHide={() => this.followers()}
      centered
      className="friends-popup"
      size="lg" >
      <Modal.Header closeButton>
        <Modal.Title className="followTitle">
          {state.followers} <Translate>Followers</Translate>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="Followers">
          <Followers
            uid={state.uid}
            isFollowers={state.isFollowers}
          />
        </div>
      </Modal.Body>
    </Modal> */}

    {/* <Modal
      show={state.followingShow}
      onHide={() => this.following()}
      centered
      className="friends-popup"
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="followTitle">
          {state.following} <Translate>Following</Translate>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="Following">
          <Following
            uid={state.uid}
            isFollowing={state.isFollowing}
          />
        </div>
      </Modal.Body>
    </Modal> */}

  {state.showpopup === "welcome" && (!isMobile || wProvider !== 'Coinbase') && (
      <SignInWithGoogle onClose={handleClosePopup} profileConnected={state.isWalletConnect} />
  )}

  </Layout>
  );
};

export default Profile;