import React, { useEffect, useState, useRef, useContext } from "react";
import { Form, Button, Modal, Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import loading from "../../assets/animation/loading.json";
import user1 from "../../assets/images/header/logo.png";
import "./chat.css";
import firebase from "../../firebase";
import { chatHistory, getUserInfo } from "../../dbFetchFunctions";
import axios from "axios";
import { useHistory } from "react-router-dom";
import EmojiPicker from "./Emoji-picker";
import ReportUserPopup from "../reportpopup/reportuserpopup";
import BlockUserPopUp from "../reportpopup/blockUser";
import { MainContext } from "../../context";
import { cookieSetter } from '../../dbFetchFunctions';
import UrlConfig from "../../utils/ApiConfig";
import { useWeb3React } from "@web3-react/core";
import { Translate } from "react-auto-translate";

const loadingLottie = {
  loop: true,
  autoplay: true,
  animationData: loading,
};

let QuickPokerCP = true;
let QuickBlackjack = true;
let QuickBackgammon = true;
let QuickLudo = true;

const Chat = ({ open, handleClick, handleIsQue, handleStartTimer }) => {
  const { firebaseUser, token } = useContext(MainContext);
  const [usid] = useState(firebaseUser?.uid);
  const [nick] = useState(firebaseUser?.displayName);
  const [peer, setPeer] = useState("");
  const [show, setShow] = useState(true);
  const [chatHist, setChatHist] = useState([]);
  const [userPhoto] = useState(firebaseUser?.photoURL);
  const [peerPhoto, setPeerPhoto] = useState("");
  const [peername, setpeername] = useState("");
  const [idToken, setidToken] = useState(token);
  const [loader, setLoader] = useState("");
  const [spinnerLoading, setSpinnerLoading] = useState(false)

  const [last_unread, setLast_unread] = useState({
    chatPersons: {},
    totalUnreadCount: 0,
  });

  useEffect(() => {
    let unsubscribe1, unsubscribe2;
    let chatPersons = {};
    let totalUnreadCount = 0;
    const db = firebase.firestore();

    const processSnapshots = async (snapshot, u, type) => {
      for await (const change of snapshot.docChanges()) {
        if (change.type === "modified") {
          let doc = change.doc.data();
          chatPersons[doc.from].allMessages.forEach(el => {
            if (el.body === doc.body) {
              el.isRead = true;
              totalUnreadCount--;
              chatPersons[doc.from].unReadCount--;
            }
          })
          setLast_unread({
            chatPersons,
            totalUnreadCount,
          });
          if (peer) {
            setChatHist(chatPersons[peer].allMessages);
            chatHistory(peer, u);
          }
          return;
        }
        if (change.type !== "added") {
          return;
        }
        // Process newly added document
        const data = change.doc.data();
        const { from, to, body, date, isRead, button } = data;
        const otherUserUid = from === u ? to : from;

        if (!(otherUserUid in chatPersons)) {
          let activeStatus = "offline";
          const newUserInfo = await getUserInfo(otherUserUid);
          if (newUserInfo?.Session?.status === "online") {
            activeStatus = "online";
          } else if (newUserInfo?.Session?.status === "offline") {
            activeStatus = "offline";
          } else {
            activeStatus = "inGame";
          }

          chatPersons[otherUserUid] = {
            allMessages: [],
            lastMessage: {},
            unReadCount: 0,
            photoURL: newUserInfo.photoURI, // You need to set this properly
            name: newUserInfo.nickname, // You need to set this properly
            activeStatus,
            uid: otherUserUid,
          };
        }
        const isUnread = !isRead; // Check if the message is unread by the current user
        if (isUnread) {
          totalUnreadCount++;
          chatPersons[otherUserUid].unReadCount++;
        }
        chatPersons[otherUserUid].allMessages.push({ ...data, body, date, from, isRead, to, button });
        if (!chatPersons[otherUserUid].lastMessage.date || date > chatPersons[otherUserUid].lastMessage.date) {
          chatPersons[otherUserUid].lastMessage = { ...data, body, date: date, from, isRead, to, button };
        }
      }

      // Sort allMessages array for each peer based on message date
      Object.values(chatPersons).forEach(peer => {
        peer.allMessages.sort((a, b) => a.date - b.date);
      });

      setLast_unread({
        chatPersons,
        totalUnreadCount,
      });
      if (peer) {
        setChatHist(chatPersons[peer].allMessages);
        chatHistory(peer, u);
      }
    }
    const getChatPeers = async (u) => {

      unsubscribe1 = db.collection(`${process.env.REACT_APP_DB_PREFIX}messanger`)
        .doc(u)
        .collection("chats")
        .where("to", "==", u)
        .orderBy("date", "asc")
        .onSnapshot(async (snapshot) => {
          await processSnapshots(snapshot, u);
          if (!unsubscribe2) {
            unsubscribe2 = db.collection(`${process.env.REACT_APP_DB_PREFIX}messanger`)
              .doc(u)
              .collection("chats")
              .where("from", "==", u)
              .orderBy("date", "asc")
              .onSnapshot(async (snapshot) => {
                await processSnapshots(snapshot, u, "from");
              });
          }
        });
    };
    if (usid) getChatPeers(usid);
    return () => {
      if (unsubscribe1 && unsubscribe2) {
        unsubscribe1()
        unsubscribe2()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChatHist = async (peer) => {
    setPeer(peer.uid);
    setidToken(token);
    setShow(false);
    setPeerPhoto(peer.photoURL);
    setpeername(peer.name);
    setChatHist(peer.allMessages);
    chatHistory(peer.uid, usid);
  };

  const handleBackClick = async () => {
    setChatHist([]);
    setPeer("");
    setPeerPhoto("");
    setShow(true);
    setpeername("");
  };


  const [view, setView] = useState(false);
  const handleClose = () => setView(false);
  const handleShow = (value) => setView(value);
  const handleBlock = (e) => {
    e.preventDefault();
    setSpinnerLoading(true)
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      duration: 4000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    axios
      .get("https://base-api-t3e66zpola-uk.a.run.app", {
        params: {
          usid: peer,
          service: "blockUser",
          params: "usid=" + usid + ",block=" + peer + ",type=block",
        },
        headers: { idtoken: idToken || token },
      })
      .then((response) => {
        setSpinnerLoading(false)
        if (response.data) {
          const myError = response.data.error;
          if (myError === "no error") {
            toast.error(<span><Translate>Friend has been blocked</Translate></span>, {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            });
            handleClose();
          } else {
            toast.error(<Translate>{myError}</Translate>, {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            })
          }
        }
      })
      .catch((error) => {
        setSpinnerLoading(false)
      });
  };
  if (!usid) return "";

  return (
    <div className={`chat-wrapper ${!open ? `expand` : ``}`}>
      <div className='chat-section'>
        <div className='chat-header'>
          {last_unread?.totalUnreadCount > 0 ? (
            <span className='unread-alert'>{last_unread?.totalUnreadCount}</span>
          ) : (
            ""
          )}
          <span className='chat-author'>
            {peerPhoto === "" ? (
              <div className='report-post' onClick={() => handleClick(!open)}>
                <img
                  src={
                    peerPhoto !== ""
                      ? peerPhoto
                      : userPhoto !== ""
                        ? userPhoto
                        : user1
                  }
                  alt='user'
                />
                {peername !== "" ? peername : <Translate>Messaging</Translate>}
              </div>
            ) : (
              <div className='report-post'>
                <DropdownButton
                  id='dropdown-basic-button'
                  title={
                    <>
                      <img
                        src={
                          peerPhoto !== ""
                            ? peerPhoto
                            : userPhoto !== ""
                              ? userPhoto
                              : user1
                        }
                        alt='user'
                      />
                      {peername !== "" ? peername : <Translate>Messaging</Translate>}
                    </>
                  }>
                  <Dropdown.Item onClick={() => handleShow("report")}>
                    <div className='reort-post-item'>
                      <i
                        className='fa fa-exclamation-triangle'
                        aria-hidden='true'></i>
                      <div className='reort-post-item-content'>
                        <h6><Translate>Report User</Translate></h6>
                        <p><Translate>I'm concerned about this user</Translate></p>
                      </div>
                    </div>
                  </Dropdown.Item>
                  {/* <Dropdown.Item onClick={() => handleShow("block")}>
                    <div className='reort-post-item'>
                      <i className='fa fa-ban' aria-hidden='true'></i>
                      <div className='reort-post-item-content'>
                        <h6>Block</h6>
                        <p>I'm concerned about this user</p>
                      </div>
                    </div>
                  </Dropdown.Item> */}
                </DropdownButton>
              </div>
            )}
          </span>
          {!show && (
            <div
              className='chat-back'
              onClick={() => handleBackClick()}
              role='presentation'>
              <i className='fa fa-angle-left' />
            </div>
          )}
          <span
            className='close-icon'
            onClick={() => handleClick(!open)}
            role='presentation'>
            <i className={`fa fa-${!open ? `angle-up` : `angle-down`}`} />
          </span>
        </div>
        {show ? (
          <ChatUserList
            setShow={setShow}
            setPeer={setPeer}
            last_unread={last_unread}
            getChatHist={getChatHist}
          />
        ) : (
          <SingleChatUser
            usid={usid}
            nick={nick}
            userPhoto={userPhoto}
            peer={{ uid: peer, name: peername, photoURL: peerPhoto }}
            chatHist={chatHist}
            setChatHist={setChatHist}
            getChatHist={getChatHist}
            handleIsQue={handleIsQue}
            handleStartTimer={handleStartTimer}
            loader={loader}
            setLoader={setLoader}
          />
        )}
      </div>

      <Modal
        show={view === "report"}
        onHide={handleClose}
        centered
        className='report-popup'>
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          <ReportUserPopup peer={peer} idToken={idToken} />
        </Modal.Body>
      </Modal>
      <Modal
        show={view === "block"}
        onHide={handleClose}
        centered
        className="friends-popup">
        <Modal.Header closeButton>
          <Modal.Title><Translate>Block friend</Translate></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BlockUserPopUp
            handleClose={handleClose}
            nickname={peername}
            handleBlock={handleBlock}
            spinnerLoading={spinnerLoading}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Chat;

const ChatUserList = ({ last_unread, getChatHist }) => {
  const [filterUsers, setFilterUser] = useState(Object.values(last_unread.chatPersons));
  const [searchUser, setSearchUser] = useState();
  const [err, setErr] = useState(false);
  useEffect(() => {
    setFilterUser(Object.values(last_unread.chatPersons));
    
  }, [last_unread]);
  const handleSearch = async (event) => {
    const searchValue = event.target.value.trim();
   
    if (searchValue.length) {
      event.preventDefault();
      try {
        filterUsers.forEach(user => {
          if (user.name.startsWith(searchValue)) {
            setSearchUser({
              name: user.name,
              photoURL: user.photoURL,
              uid: user.uid,
              allMessages: user.allMessages,
              activeStatus: user.activeStatus,
              lastMessage: user.lastMessage,
              unReadCount: user.unReadCount,
            });
            setErr("");
          }
        });
      } catch (err) {
        setSearchUser();
        setErr(true);
      }
    } else {
      setSearchUser();
      setErr("");
    }
   
  };

  return (
    <div className='chat-content'>
      <div className='chat-search'>
        <Form inline>
            <Form.Control
              type='text'
              id='search'
              onKeyUp={handleSearch}
              placeholder='Search User'
            />
          
          <Button>
            <i className='fa fa-search' />
          </Button>
        </Form>
        {searchUser ? (
          <div className="chat-users" onClick={() => getChatHist(searchUser)}>
            <div className="chat-list">
              <div className="chat-user-pic">
                <PeerImage img={searchUser.photoURL} />
              </div>
              <div className="chat-user-content">
                <h4>
                  {searchUser.name}
                </h4>
                <p
                  contentEditable='false'
                  dangerouslySetInnerHTML={{ __html: "" }}></p>
              </div>
            </div>
          </div>
        ) : err ? (<div className="error"><Translate>User not found</Translate></div>) : ""}
      </div>

      <div className='chat-users'>
        {filterUsers.map((peer, i) => {
          let status = peer?.activeStatus;
          return (
            <div
              key={`item-${i}`}
              className={`chat-list ${!peer.unReadCount ? "unread-msg" : ""}`}
              onClick={() => {
                getChatHist(peer);
              }}
              role='presentation'>
              <div className='chat-user-pic'>
                <PeerImage img={peer.photoURL} />
                {peer.uid === "Weje777weje777Bot777wejE" ? (
                  ""
                ) : (
                  <div className={`status-dot ${status}`}></div>
                )}
              </div>
              <div className='chat-user-content'>
                <h4>
                  {peer.name}
                  <span>{peer?.lastMessage?.date?.toDate()?.toDateString()}</span>
                </h4>
                <p
                  contentEditable='false'
                  dangerouslySetInnerHTML={{ __html: peer?.lastMessage?.body }}></p>
              </div>
              {peer.unReadCount > 0 ? (
                <span className='unread-alert'>{peer.unReadCount}</span>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SingleChatUser = ({
  usid,
  peer,
  nick,
  userPhoto,
  chatHist,
  getChatHist,
  setChatHist,
  handleIsQue,
  handleStartTimer,
  loader,
  setLoader,
}) => {
  const dummy = useRef();
  const [emoji, setEmoji] = useState(null);
  const [message, setMessage] = useState("");
  const db = firebase.firestore();
  const [altDate, setAltDate] = useState(false);
  const onEmojiClick = (e, emojiUrl) => {
    let msg = `<img src='${emojiUrl}' alt='LV-emoji' />`;
    sendMessage(e, msg);
    setEmoji(false);
  };

  const toggleEmoji = () => {
    setEmoji(!emoji);
  };

  useEffect(() => {
    function scrollToBottom() {
      if (chatHist.length > 1) {
        dummy?.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (chatHist.length > 1) {
      scrollToBottom();
    }
  }, [chatHist.length]);

  function getMessage(event) {
    setMessage(event.target.value);
  }

  async function sendMessage(event, msg) {
    const date = firebase.firestore.Timestamp.fromDate(new Date());
    event.preventDefault();
    let msgData = {
      from: usid,
      to: peer.uid,
      body: msg ? msg : message,
      date,
      isRead: false,
    };
    setMessage("");
    setAltDate(true);
    await db
      .collection(`${process.env.REACT_APP_DB_PREFIX}messanger`)
      .doc(peer.uid)
      .collection("chats")
      .doc()
      .set(msgData);
    msgData.isRead = true;
    await db
      .collection(`${process.env.REACT_APP_DB_PREFIX}messanger`)
      .doc(usid)
      .collection("chats")
      .doc()
      .set(msgData);
  }

  return (
    <div className='chat-content'>
      <div className='chat-users chat-message-box'>
        {chatHist.map((msg, i) => {
          let side = "msg right-msg";
          let myDate = new Date().toLocaleString();
          let name = nick;
          let photoURL = userPhoto;
          let content = msg.body;
          let uid = usid;
          let dt;
          if (content) {
            let cc = content.split("at ");
            if (cc[1]) {
              let dd = cc[1].split(". ");
              cc.splice(1, 1, ...dd);
              dt = new Date(cc[1] + "Z").toLocaleString();
              cc[1] = dt + ". ";
              if (new Date(dt) === "Invalid Date")
                content = cc[0] + cc[1] + cc[2];
            }
            if (content.includes("wants to be your friend")) {
              let msgs = content.split(" ");
              let uname = msgs.splice(0, 1);
              let remainBody = " " + msgs.join(" ");
              content = `<a href="${window.location.origin}/userprofile/${uname}">${uname}</a>${remainBody}`;
            }
            if (content.includes("you invited")) {
              let msgs = content.split(" ");
              let uname = msgs.splice(2, 1);
              let remainArray = msgs.slice(2);
              let start = msgs[0] + " " + msgs[1] + " ";
              let remainBody = " " + remainArray.join(" ");
              content = `${start}<a href="${window.location.origin}/userprofile/${uname}">${uname}</a>${remainBody}`;
            }
            if (content.includes("We notified")) {
              let msgs = content.split(" ");
              let uname = msgs.splice(2, 1);
              let remainArray = msgs.slice(2);
              let start = msgs[0] + " " + msgs[1] + " ";
              let remainBody = " " + remainArray.join(" ");
              content = `${start}<a href="${window.location.origin}/userprofile/${uname}">${uname}</a>${remainBody}`;
            }
            if (content.includes("we are friends again!")) {
              let msgs = content.split(",");
              let uname = msgs.splice(0, 1);
              let remainBody = " " + msgs.join(" ");
              content = `<a href="${window.location.origin}/userprofile/${uname}">${uname}</a>${remainBody}`;
            }
            if (content.includes("we are friends now!")) {
              let msgs = content.split(",");
              let uname = msgs.splice(0, 1);
              let remainBody = " " + msgs.join(" ");
              content = `<a href="${window.location.origin}/userprofile/${uname}">${uname}</a>${remainBody}`;
            }

            if (content.includes("invited you to a")) {
              let msgs = content.split(" ");
              let uname = msgs.splice(0, 1);
              let remainBody = " " + msgs.join(" ");
              content = `<a href="${window.location.origin}/userprofile/${uname}">${uname}</a>${remainBody}`;
            }
          }
          if (msg.from === peer.uid) {
            side = "msg left-msg";
            name = peer.name;
            photoURL = peer.photoURL;
            uid = peer.uid;
          }
          if (!altDate) {
            myDate = msg?.date?.toDate()?.toLocaleString();
          }

          return (
            <div className={side} key={`item-${i}`}>
              <div className='msg-img'>
                {peer.name === "WEJE" ? (
                  <PeerImage img={photoURL} />
                ) : (
                  <a
                    href={`${window.location.origin}/userprofile/${uid}`}>
                    <PeerImage img={photoURL} />
                  </a>
                )}
              </div>
              <div className='msg-bubble'>
                <div className='msg-info'>
                  <div className='msg-info-name'>
                    {name === "WEJE" ? (
                      name
                    ) : (
                      <a
                        href={`${window.location.origin}/userprofile/${uid}`}>
                        {name}
                      </a>
                    )}
                  </div>
                  <div className='msg-info-time'>{myDate}</div>
                </div>
                {/* <div className="msg-text">{msg.body}</div> */}
                <div
                  contentEditable='false'
                  className='msg-text'
                  dangerouslySetInnerHTML={{ __html: content }}>
                </div>
                {msg.button && (
                  <ActionButton
                    type={msg.button}
                    peer={peer}
                    link={msg.link}
                    body={msg.body}
                    getChatHist={getChatHist}
                    handleIsQue={handleIsQue}
                    handleStartTimer={handleStartTimer}
                    loader={loader}
                    setLoader={setLoader}
                    msg={msg}
                  />
                )}
              </div>
              <span ref={dummy}></span>
            </div>
          );
        })}
      </div>

      <div className='chat-search chat-input'>
        <Form inline onSubmit={sendMessage}>
          <Form.Control
            type='text'
            placeholder='Say it now'
            value={message}
            onChange={getMessage}
          />
          <Button
            variant='outline-secondary'
            id='button-addon2'
            onClick={() => toggleEmoji()}
            className='emoji-btn'>
            <i className='fa fa-smile-o' aria-hidden='true'></i>
          </Button>
          <Button type='submit' disabled={message.length === 0}>
            <i className='fa fa-location-arrow' />
          </Button>
        </Form>
      </div>
      {emoji ? (
        <div className='emoji'>
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const ActionButton = ({
  type,
  peer,
  link,
  body,
  getChatHist,
  handleIsQue,
  handleStartTimer,
  msg
  //loader,
  //setLoader,
}) => {
  const { setFriendList, setBlockList, firebaseUser, token } = useContext(MainContext);
  const history = useHistory();
  const { account } = useWeb3React();
  const [usid] = useState(firebaseUser.uid);
  const [idToken, setidToken] = useState(token);
  const [isClicked, setIsClicked] = useState("");
  const [loader, setLoader] = useState("");
  // const [coins, setCoins] = useState(0);

  const handleAcceptRequest = async () => {
    setIsClicked(true);
    const nick = body.split(" ");
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    axios
      .get(UrlConfig.acceptFriendsRequest, {
        params: { frId: msg.friendRequesterUid },
        headers: { wid: localStorage.getItem("walletid") },
        withCredentials: true,
        credentials: "include",
      })
      .then(async (response) => {
        if (response.data) {
          let fName = nick[0];
          if (response.data.error === "no error") {
            toast.success(<span><Translate>Congratulations!</Translate><strong> @{fName}</strong> <Translate>and you are friends!</Translate></span>, {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            });
            axios
              .get(UrlConfig.showFriends, {
                params: {
                  uid: usid,
                  mode: "profilePage",
                  viewMore: "no",
                },
              })
              .then((response) => {
                if (response.data) {
                  setFriendList(response.data.frTable);
                  setBlockList(response.data.blockTable);
                }
              })
              .catch((error) => {
                console.log("Error req", error);
              });
          } else if (response.data.error.includes("already friends")) {
            toast.error(<span><Translate>The request has already been accepted</Translate></span>, {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            });
          } else if (response.data.error === "missing auth token") {
            const account = localStorage.getItem("walletid");
            await cookieSetter(account, refreshCookieAlert, setidToken);
          } else {
            toast.error(response.data.error, { id: "A" });
          }
        }
      })
      .catch(async (error) => {
        console.log("Error req", error);
      });
  };

  const refreshCookieAlert = () => {
    toast.success(<span><Translate>Oops! Something went wrong</Translate></span>, {
      duration: 4000,
      icon: '⚠️',
      style: {
        maxWidth: 800,
        fontSize: "14px",
        borderRadius: "15px",
      },
    }
    );
  };


  const handleCancelRequest = async () => {
    setIsClicked(true);
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      duration: 4000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    try {
      const response = await axios.get(UrlConfig.rejectFriendRequest, {
        params: { frId: msg.friendRequesterUid },
        headers: { wid: localStorage.getItem("walletid") },
        withCredentials: true,
        credentials: "include",
      });
      if (response.data) {
        if (response.data.error === "no error") {
          toast.success(<span><Translate>Friend request has been declined</Translate></span>, {
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
        } else if (response.data.error === "missing auth token") {
          const account = localStorage.getItem("walletid");
          await cookieSetter(account, refreshCookieAlert, setidToken);
        } else {
          toast.error(<Translate>{response.data.error}</Translate>, { id: "A" });
        }
      }
    } catch (error) {
      console.log("Error req", error);
    }
  };


  const handleSendRequest = async (text) => {
    setLoader(text);
    setIsClicked(true);
    const FUid = peer;
    axios
      .get(UrlConfig.sendFriendRequest, {
        params: { frId: FUid },
        headers: { wid: localStorage.getItem("walletid") },
        withCredentials: true,
        credentials: "include",
      })
      .then((response) => {
        setLoader("");
        if (response.data) {
          getChatHist();
        } else {
          setLoader("");
        }
      })
      .catch((error) => {
        console.log("Error req", error);
      });
  };



  const handlePlayQuickMatch = async (text) => {
    setLoader(text);
    const IdTokenConst = idToken || token;
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      duration: 4000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    axios
      .get("https://quick-match-t3e66zpola-uk.a.run.app", {
        params: {
          BJ: QuickBlackjack.toString(),
          BG: QuickBackgammon.toString(),
          pokerCP: QuickPokerCP.toString(),
          ludoMT: QuickLudo.toString(),
        },
        headers: {
          idtoken: IdTokenConst || token,
          device: localStorage.getItem("deviceId"),
        },
      })
      .then((response) => {
        if (response.data.error === "user logged in other device") {
          toast.error(<span><Translate>User is currently logged in on another device</Translate></span>, {
            style: {
              maxWidth: 800,
              fontSize: "14px",
              borderRadius: "15px",
            },
          });
          setLoader("");
          return (window.location.href = "/");
        }
        if (response.data) {
          setLoader("");
          if (
            response.data.error === "no error" &&
            response.data.success === true &&
            response.data.message === "table found"
          ) {
            this.setState({
              isEnterQ: false,
              isGotMatch: true,
            });
            let pokerQuickLink = response.data.link;
            toast.success(<span><Translate>Game is starting!</Translate></span>, {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            });
            window.location.href = pokerQuickLink;
          }
          if (
            response.data.error === "no error" &&
            response.data.message === "table created, Match!" &&
            response.data.success === true
          ) {
            this.setState({
              isEnterQ: false,
              isGotMatch: true,
            });
            let QuickLink = response.data.link;
            toast.success(<span><Translate>Game is starting!</Translate></span>, {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            });
            window.location.href = QuickLink;
          }
          if (response.data.error === "user already in Que") {
            toast.error(<span><Translate>You are already in the queue</Translate></span>, {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            });
          }
          if (
            response.data.error === "no error" &&
            response.data.message === "user set in Que" &&
            response.data.success === true
          ) {
            let responseTime = response.data.estimated_wait_seconds;
            handleIsQue();
            localStorage.setItem("eta", responseTime);
            handleStartTimer();
            toast.success(<span><Translate>You are entering the queue. Please wait for another user to join</Translate></span>, {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            });
          }
          if (response.data.error === "sorry, no table found") {
            toast.error(<span><Translate>No games found</Translate></span>, {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            });
          }
          if (response.data.error === "already inGame") {
            toast.error(<span><Translate>You are currently in a game and cannot join multiple games simultaneously</Translate></span>, {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            }
            );
          }
          else if (response.data.error.startsWith("Token expired")) {
            window.location.reload();
          } else if (response.data.error !== "no error") {
            toast.error(<Translate>{response.data.error}</Translate>, {
              style: {
                maxWidth: 800,
                fontSize: "14px",
                borderRadius: "15px",
              },
            });
          }
        } else {
          setLoader("");
        }
      })
      .catch((error) => {
        console.log("Error req", error);
        setLoader("");
      });
  };

  const handleJoinTable = async (text) => {
    toast.custom(<Lottie options={loadingLottie} width={100} height={100} />, {
      duration: 4000,
      style: {
        borderRadius: "5px",
        background: "#333",
        color: "#fff",
      },
    });
    setLoader(text);
    let d = link.split("=");
    let tabId = d[1].split("&")[0];
    let gameColl = d[2];
    const res = await axios.post(`${UrlConfig.setInGameAPI}`,
      {
        gameColl,
        ballance: 1000000000.1,
        userCurrency: 'Matic',
        tableCurrency: 'Matic',
        buyIn: 0, // float or tournament for ,
        betMin: 1,
        wid: account
      },
      {
        headers: {
          device: localStorage.getItem("deviceId"),
          tabId: tabId,
          wid: account
        },
        withCredentials: true,
        credentials: "include",
      });
    if (res.data.success) {
      setLoader("");
      const link1 = link.split("https://");
      window.location.href = `https://${link1[1]}`;
    } else if (res.data.error === "User is already lobby") {
      toast.error(<span><Translate>You are currently in a game and cannot play multiple games</Translate></span>, {
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
      setLoader("");
      let pokerTableLink = res.data.link;
      window.location.href = pokerTableLink;
    } else if (res.data.error === "User has no session. Force signin") {
      setLoader("");
      console.log("No active session detected, forcing signout");
      axios
        .get("https://base-api-t3e66zpola-uk.a.run.app/", {
          params: {
            usid: usid,
            service: "signout",
            params: `usid=${usid}`,
          },
          headers: { idtoken: idToken || token },
        })
        .then((response) => {
          if (response.data.error === "no error") {
            localStorage.removeItem("xtkn");
            firebase
              .auth()
              .signOut()
              .then(() => {
                // this.setState({ renderSignout: true });
                history.push("/");
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log("Error req", error);
        });
    } else if (res.data.error === "user logged in other device") {
      setLoader("");
      toast.error(<span><Translate>User is currently logged in on another device</Translate></span>, {
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
      setLoader("");
      return (window.location.href = "/");
    } else {
      setLoader("");
      toast.error(<Translate>{res.data.error}</Translate>, {
        style: {
          maxWidth: 800,
          fontSize: "14px",
          borderRadius: "15px",
        },
      });
      if (res.data.error === "id token veri failed" || res.data.error.includes("verification") || res.data.error.includes("Token expired")) {
        window.location.reload()
      }
    }
  };

  const handleBetAccept = async () => {
    let url = link.split("/");
    history.push("/" + url[url.length - 1]);
  };

  switch (type) {
    case "send-friend-request": {
      return (
        <Button
          onClick={() => handleSendRequest("sendbtn")}
          disabled={loader === "sendbtn"}>
          {loader === "sendbtn" ? (
            <Spinner animation='border' variant='warning' />
          ) : (
            <Translate>Send</Translate>
          )}
        </Button>
      );
    }
    case "play-quick-match": {
      return (
        <Button
          disabled={loader === "playbtn"}
          onClick={() => handlePlayQuickMatch("playbtn")}>
          {loader === "playbtn" ? (
            <Spinner animation='border' variant='warning' />
          ) : (
            <Translate>Play</Translate>
          )}
        </Button>
      );
    }
    case "join-table": {
      return (
        <Button
          disabled={loader === "joinbtn"}
          onClick={() => handleJoinTable("joinbtn")}>
          {loader === "joinbtn" ? (
            <Spinner animation='border' variant='warning' />
          ) : (
            <Translate>Join</Translate>
          )}
        </Button>
      );
    }
    case "accept-friend-request": {
      return (
        <>
          {!isClicked && (
            <>
              <Button onClick={() => handleAcceptRequest()}><Translate>Accept</Translate></Button>
              <Button onClick={() => handleCancelRequest()}><Translate>Reject</Translate></Button>
            </>
          )}
        </>
      );
    }
    case "open-bet-form": {
      return (
        <>
          {!isClicked && (
            <>
              <Button onClick={() => handleBetAccept()}><Translate>Bet Details</Translate></Button>
            </>
          )}
        </>
      );
    }
    default: {
      return "";
    }
  }
};

const PeerImage = ({ img }) => {
  return <img src={img} alt='Peer-Profile' />;
};
