import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import axios from "axios";
import UrlConfig from "../../utils/ApiConfig";
import Pagination from "react-bootstrap/Pagination";
import { Translate } from "react-auto-translate";
import "./transaction.css";

const TransactionList = ({ usid }) => {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination] = useState(20);
  const [totalCount, setTotalCount] = useState(20);
  const [loading, setLoading] = useState(true);
  const walletId = localStorage.getItem("walletid");

  useEffect(() => {
    const fetchTransactionLogs = async () => {
      const res = await axios.get(UrlConfig.transactionHistory, {
        params: { usid, page, paginate: pagination },
      });
      setLoading(false);
      if (res.data.error === "no error") {
        setList(res.data.transactions);
        setTotalCount(res.data.queryCount);
      }
    };
    fetchTransactionLogs();
  }, [usid, page, pagination]);

  // Calculate the starting serial number for the current page
  const startSerialNumber = (page - 1) * pagination + 1;

  // Function to format the token amount
  const formatTokenAmount = (amount) => {
    // Use regex to extract the numeric part and the currency
    const match = amount.match(/([+-]?\d+(\.\d+)?)\s*(Matic)/);
    if (match) {
      const numericPart = parseFloat(match[1]);
      const formattedNumber = numericPart.toFixed(2); // Adjust toFixed if needed
      return `${formattedNumber} ${match[3]}`;
    }
    return amount; // Return original if regex does not match
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 transaction-table">
          <div className="transaction-content">
            <h2><Translate>Transaction History</Translate></h2>
            <div className="transaction-table">
              <Table striped bordered hover variant="dark" responsive>
                <thead>
                  <tr>
                    <th><Translate>SR No</Translate></th>
                    <th><Translate>Coins</Translate></th>
                    <th><Translate>Achievement</Translate></th>
                    <th><Translate>Game Type</Translate></th>
                    <th><Translate>Date</Translate></th>
                    <th><Translate>Transaction</Translate></th>
                    <th><Translate>Fee</Translate></th>
                    {/* <th>Id</th> */}
                  </tr>
                </thead>
                <tbody>
                  {list && list.length !== 0 ? (
                    list.map((item, i) => (
                      <tr
                        key={item.id} // Add a unique key to each row
                        className={`${
                          item["amount"].search("-") !== -1
                            ? "decrease"
                            : "increase"
                        }`} >
                        <td>{startSerialNumber + i}</td>
                        <td>{formatTokenAmount(item["amount"])}</td>
                        <td>{item["transaction name"]}</td>
                        <td>{item["transaction origin"]}</td>
                        <td>{`${new Date(
                          item["date"]
                        ).toLocaleDateString()}, ${new Date(
                          item["date"]
                        ).toLocaleTimeString()}`}</td>
                         
                         <td>
                            {item["hash"] === "NotFound" ? (
                              <a
                                href={`https://polygonscan.com/address/${walletId}#internaltx`}
                                target="_blank"
                                rel="noreferrer"
                                className="hash-tra" >
                                Hash
                              </a>
                            ) : (
                              <a
                                href={`${UrlConfig.polygonChain}/${item["hash"]}`}
                                target="_blank"
                                rel="noreferrer"
                                className="hash-tra" >
                              Hash
                              </a>
                            )}
                          </td>
                          
                        <td>{item["commission"]}</td>
                        {/* <td>{item["weje-transaction-id"]}</td> */}
                      </tr>
                    ))
                  ) : loading ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        <Spinner animation="border" />
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        <Translate>No Records found</Translate>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {totalCount > pagination && (
                <div className="pagination-view">
                  <Pagination>
                    <Pagination.First onClick={() => setPage(1)} />
                    <Pagination.Prev
                      onClick={() => (page - 1 > 0 ? setPage(page - 1) : "")}
                    />
                    {page - 2 > 0 && (
                      <Pagination.Item onClick={() => setPage(page - 2)}>
                        {page - 2}
                      </Pagination.Item>
                    )}
                    {page - 1 > 0 && (
                      <Pagination.Item onClick={() => setPage(page - 1)}>
                        {page - 1}
                      </Pagination.Item>
                    )}
                    <Pagination.Item active>{page}</Pagination.Item>
                    {(page + 1) * 9 < totalCount && (
                      <Pagination.Item onClick={() => setPage(page + 1)}>
                        {page + 1}
                      </Pagination.Item>
                    )}
                    {(page + 2) * 9 < totalCount && (
                      <Pagination.Item onClick={() => setPage(page + 2)}>
                        {page + 2}
                      </Pagination.Item>
                    )}
                    <Pagination.Next
                      onClick={() =>
                        page > Math.ceil(totalCount / 9)
                          ? setPage(page + 1)
                          : ""
                      }
                    />
                    <Pagination.Last
                      onClick={() => setPage(Math.ceil(totalCount / 9))}
                    />
                  </Pagination>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionList;