
import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";

const Layout = ({ children, handleIsQue, handleStartTimer }) => {
    return (
        <div className="wrapper">
            <Header />
            <div className="content-wrapper">
                {children}
            </div>
            <Footer handleIsQue={handleIsQue} handleStartTimer={handleStartTimer} />
        </div>
    )
}


export default Layout;