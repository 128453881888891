import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getFeed, timeDifference } from "../../../dbFetchFunctions";
import { Translate } from "react-auto-translate";

const OriginalPost = ({ postId, uid }) => {
  const history = useHistory();
  const [originalFeed, setOriginalFeed] = useState();
  useEffect(() => {
    const data = async () => {
		let u = uid;
		let p = postId;
		if(postId.includes("/")){
			u = postId.split("/")[0];
			p = postId.split("/")[1];
		}
      const data = await getFeed(u, p);
      setOriginalFeed(data.post);
    };
    if (uid && postId) data();
  }, [postId, uid]);

  return (
	<>
	{originalFeed && (
    <div className="filter-matches">
      <div
        className="post-user"
        onClick={() => history.push(`/userProfile/${originalFeed.uid}`)} >
        <img src={originalFeed?.photo} alt="user-profile" />
        <div className="user-name">
          {originalFeed?.nickname}
          <span className="post-time">
            {timeDifference(originalFeed?.date)}
          </span>
        </div>
      </div>
      <div
        className="post-content"
        onClick={() => history.push(`/feed-detail/${originalFeed.id}`)} >
        <p><Translate>{originalFeed?.text}</Translate></p>
      </div>
      <div className="post-activity">
        <div className="post-likes">
          <span>
            <i
              className={
                originalFeed?.likers?.find((el) => el.uid === uid)
                  ? `fa fa-heart`
                  : `fa fa-heart-o`
              }
              aria-hidden="true"
            ></i>
            {originalFeed?.likes}
          </span>
        </div>
        {originalFeed?.allowCom ? (
          <div className="post-comments">
            <span>
              <i className="fa fa-comment" aria-hidden="true"></i>
              <Translate>{originalFeed?.comments}</Translate> <Translate>Comments</Translate>
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
	)}
	</>
  );
};

export default OriginalPost;
