import React, { useRef, useState, useEffect } from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import SportBet from '../sports/bet';
import firebase from '../../firebase';
import { getDoc } from '../../dbFetchFunctions';
import Layout from '../layout/layout';
import './filter.css';
import { isMobile } from "react-device-detect";
import Lottie from "react-lottie";
import { Translate } from "react-auto-translate";
import arrow from "../../assets/animation/arrow.json";
import LogContainer from './LogContainer';
import BetFilter from './betFilter';

const arrowLottie = {
    loop: true,
    autoplay: true,
    animationData: arrow,
};

const SportsFilter = () => {
  const [step, setStep] = useState(1);
  const [dateStart, setDateStart] = useState('');
  const [uid, setUid] = useState(null);
  const [isVisible, setIsVisible] = useState(true);

  // Radio Buttons Sport List
  const [football, setFootball] = useState(false);
  const [basketball, setBasketball] = useState(false);
  const [hockey, setHockey] = useState(false);
  const [tennis, setTennis] = useState(false);
  const [showArrow, setShowArrow] = useState(true);

  // Radio Buttons leagueId Rendering Components
  const [ChampionsLeague, setChampionsLeague] = useState(false);
  const [EuropaLeague, setEuropaLeague] = useState(false);
  const [EnglandLeague, setEnglandLeague] = useState(false);
  const [GermanyLeague, setGermanyLeague] = useState(false);
  const [SpainLeague, setSpainLeague] = useState(false);
  const [BrazilLeague, setBrazilLeague] = useState(false);
  const [ArgentinaLeague, setArgentinaLeague] = useState(false);
  const [IndiaLeague, setIndiaLeague] = useState(false);

  const [NBA, setNBA] = useState(false);
  const [EuroLeague, setEuroLeague] = useState(false);
  const [RussiaVTBLeague, setRussiaVTBLeague] = useState(false);
  const [SpainACBLeague, setSpainACBLeague] = useState(false);
  const [TurkeyTBSLLeague, setTurkeyTBSLLeague] = useState(false);
  const [BrazilNBBLeague, setBrazilNBBLeague] = useState(false);
  const [ChinaCBALeague, setChinaCBALeague] = useState(false);
  const [NHL, setNHL] = useState(false);
  const [AHL, setAHL] = useState(false);
  const [KHL, setKHL] = useState(false);
  const [AustralianOpen, setAustralianOpen] = useState(false);
  const [USOpen, setUSOpen] = useState(false);

  // showModal remove data confirmation
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  
  //Games data selection
  const [ChampionsLeagueData, setChampionsLeagueData] = useState([]);
  const [EuropaLeagueData, setEuropaLeagueData] = useState([]);
  const [EnglandLeagueData, setEnglandLeagueData] = useState([]);
  const [GermanyLeagueData, setGermanyLeagueData] = useState([]);
  const [SpainLeagueData, setSpainLeagueData] = useState([]);
  const [BrazilLeagueData, setBrazilLeagueData] = useState([]);
  const [ArgentinaLeagueData, setArgentinaLeagueData] = useState([]);
  const [IndiaLeagueData, setIndiaLeagueData] = useState([]);
  const [NBAData, setNBAData] = useState([]);
  const [EuroLeagueData, setEuroLeagueData] = useState([]);
  const [RussiaVTBLeagueData, setRussiaVTBLeagueData] = useState([]);
  const [SpainACBLeagueData, setSpainACBLeagueData] = useState([]);
  const [TurkeyTBSLLeagueData, setTurkeyTBSLLeagueData] = useState([]);
  const [BrazilNBBLeagueData, setBrazilNBBLeagueData] = useState([]);
  const [ChinaCBALeagueData, setChinaCBALeagueData] = useState([]);
  const [NHLData, setNHLData] = useState([]);
  const [AHLData, setAHLData] = useState([]);
  const [KHLData, setKHLData] = useState([]);
  const [AustralianOpenData, setAustralianOpenData] = useState([]);
  const [USOpenData, setUSOpenData] = useState([]);

  // Games counting
  const [ChampionsLeagueCount, setChampionsLeagueCount] = useState(0);
  const [EuropaLeagueCount, setEuropaLeagueCount] = useState(0);
  const [EnglandLeagueCount, setEnglandLeagueCount] = useState(0);
  const [GermanyLeagueCount, setGermanyLeagueCount] = useState(0);
  const [SpainLeagueCount, setSpainLeagueCount] = useState(0);
  const [BrazilLeagueCount, setBrazilLeagueCount] = useState(0);
  const [ArgentinaLeagueCount, setArgentinaLeagueCount] = useState(0);
  const [IndiaLeagueCount, setIndiaLeagueCount] = useState(0);
  const [NBACount, setNBACount] = useState(0);
  const [EuroLeagueCount, setEuroLeagueCount] = useState(0);
  const [RussiaVTBLeagueCount, setRussiaVTBLeagueCount] = useState(0);
  const [SpainACBLeagueCount, setSpainACBLeagueCount] = useState(0);
  const [TurkeyTBSLLeagueCount, setTurkeyTBSLLeagueCount] = useState(0);
  const [BrazilNBBLeagueCount, setBrazilNBBLeagueCount] = useState(0);
  const [ChinaCBALeagueCount, setChinaCBALeagueCount] = useState(0);
  const [NHLCount, setNHLCount] = useState(0);
  const [AHLCount, setAHLCount] = useState(0);
  const [KHLCount, setKHLCount] = useState(0);
  const [AustralianOpenCount, setAustralianOpenCount] = useState(0);
  const [USOpenCount, setUSOpenCount] = useState(0);
  
  // automatically scroll to the relevant section
  const championsLeagueRef = useRef(null);
  const europaLeagueRef = useRef(null);
  const EnglandLeagueRef = useRef(null);
  const GermanyLeagueRef = useRef(null);
  const SpainLeagueRef = useRef(null);
  const BrazilLeagueRef = useRef(null);
  const ArgentinaLeagueRef = useRef(null);
  const IndiaLeagueRef = useRef(null);
  const nbaRef = useRef(null);
  const euroLeagueRef = useRef(null);
  const RussiaVTBLeagueRef = useRef(null);
  const SpainACBLeagueRef = useRef(null);
  const TurkeyTBSLLeagueRef = useRef(null);
  const BrazilNBBLeagueRef = useRef(null);
  const ChinaCBALeagueRef = useRef(null);
  const NHLRef = useRef(null);
  const AHLRef = useRef(null);
  const KHLRef = useRef(null);
  const australianOpenRef = useRef(null);
  const usOpenRef = useRef(null);

  const hasData = 
    ChampionsLeagueData.length > 0 || 
    EuropaLeagueData.length > 0 || 
    EnglandLeagueData.length > 0 ||
    GermanyLeagueData.length > 0 ||
    SpainLeagueData.length > 0 ||
    BrazilLeagueData.length > 0 ||
    ArgentinaLeagueData.length > 0 ||
    IndiaLeagueData.length > 0 ||
    NBAData.length > 0 || 
    EuroLeagueData.length > 0 ||
    RussiaVTBLeagueData.length > 0 ||
    SpainACBLeagueData.length > 0 ||
    TurkeyTBSLLeagueData.length > 0 ||
    BrazilNBBLeagueData.length > 0 ||
    ChinaCBALeagueData.length > 0 ||
    NHLData.length > 0 ||
    AHLData.length > 0 ||
    KHLData.length > 0 ||
    AustralianOpenData.length > 0 ||
    USOpenData.length > 0;

  const totalCount = ChampionsLeagueCount + EuropaLeagueCount + EnglandLeagueCount + GermanyLeagueCount + SpainLeagueCount + BrazilLeagueCount + ArgentinaLeagueCount + IndiaLeagueCount + 
    NBACount + EuroLeagueCount + RussiaVTBLeagueCount + SpainACBLeagueCount + TurkeyTBSLLeagueCount + BrazilNBBLeagueCount + ChinaCBALeagueCount + 
    NHLCount + AHLCount + KHLCount + 
    AustralianOpenCount + USOpenCount;


  useEffect(() => {
    const dateObj = new Date().toISOString().split('T')[0];
    setDateStart(dateObj);
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const baseStats = await getDoc(firebase.auth().currentUser.uid);
        const frList = [];
        if (baseStats && frList) {
          setUid(firebase.auth().currentUser.uid);
        }
      } else {
        // Handle redirection if necessary
      }
    });
  }, []);

  useEffect(() => {
    if (ChampionsLeague && championsLeagueRef.current) {
      championsLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    }, [ChampionsLeague]);
  
  useEffect(() => {
    if (EuropaLeague && europaLeagueRef.current) {
      europaLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [EuropaLeague]);

  useEffect(() => {
    if (EnglandLeague && EnglandLeagueRef.current) {
        EnglandLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [EnglandLeague]);

  useEffect(() => {
    if (GermanyLeague && GermanyLeagueRef.current) {
        GermanyLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [GermanyLeague]);

  useEffect(() => {
    if (SpainLeague && SpainLeagueRef.current) {
        SpainLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [SpainLeague]);

  useEffect(() => {
    if (BrazilLeague && BrazilLeagueRef.current) {
      BrazilLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [BrazilLeague]);

  useEffect(() => {
    if (ArgentinaLeague && ArgentinaLeagueRef.current) {
      ArgentinaLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ArgentinaLeague]);

  useEffect(() => {
    if (IndiaLeague && IndiaLeagueRef.current) {
      IndiaLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [IndiaLeague]);
  
  useEffect(() => {
    if (NBA && nbaRef.current) {
      nbaRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [NBA]);
  
  useEffect(() => {
    if (EuroLeague && euroLeagueRef.current) {
      euroLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [EuroLeague]);

  useEffect(() => {
    if (RussiaVTBLeague && RussiaVTBLeagueRef.current) {
      RussiaVTBLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [RussiaVTBLeague]);

  useEffect(() => {
    if (SpainACBLeague && SpainACBLeagueRef.current) {
      SpainACBLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [SpainACBLeague]);

  useEffect(() => {
    if (TurkeyTBSLLeague && TurkeyTBSLLeagueRef.current) {
      TurkeyTBSLLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [TurkeyTBSLLeague]);

  useEffect(() => {
    if (BrazilNBBLeague && BrazilNBBLeagueRef.current) {
      BrazilNBBLeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [BrazilNBBLeague]);

  useEffect(() => {
    if (ChinaCBALeague && ChinaCBALeagueRef.current) {
      ChinaCBALeagueRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ChinaCBALeague]);
  
  useEffect(() => {
    if (NHL && NHLRef.current) {
      NHLRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [NHL]);

  useEffect(() => {
    if (AHL && AHLRef.current) {
      AHLRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [AHL]);

  useEffect(() => {
    if (KHL && KHLRef.current) {
      KHLRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [KHL]);
  
  useEffect(() => {
    if (USOpen && usOpenRef.current) {
      usOpenRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [USOpen]);
  
  useEffect(() => {
    if (AustralianOpen && australianOpenRef.current) {
      australianOpenRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [AustralianOpen]);

  const ChampionsLeagueUpdate = (gamesVar) => {
    setChampionsLeagueData(gamesVar);
    setChampionsLeagueCount(gamesVar.length);
    console.log("setChampionsLeagueData: " +gamesVar.length);
  };

  const EuropaLeagueUpdate = (gamesVar) => {
    setEuropaLeagueData(gamesVar);
    setEuropaLeagueCount(gamesVar.length);
    console.log("setEuropaLeagueData: " +gamesVar.length);
  };

  const EnglandLeagueUpdate = (gamesVar) => {
    setEnglandLeagueData(gamesVar);
    setEnglandLeagueCount(gamesVar.length);
    console.log("setEnglandLeagueData: " +gamesVar.length);
  };

  const GermanyLeagueUpdate = (gamesVar) => {
    setGermanyLeagueData(gamesVar);
    setGermanyLeagueCount(gamesVar.length);
    console.log("setGermanyLeagueData: " + JSON.stringify(gamesVar));
  };

  const SpainLeagueUpdate = (gamesVar) => {
    setSpainLeagueData(gamesVar);
    setSpainLeagueCount(gamesVar.length);
    console.log("setSpainLeagueData: " + JSON.stringify(gamesVar));
  };

  const BrazilLeagueUpdate = (gamesVar) => {
    setBrazilLeagueData(gamesVar);
    setBrazilLeagueCount(gamesVar.length);
    console.log("setBrazilLeagueData: " + JSON.stringify(gamesVar));
  };

  const ArgentinaLeagueUpdate = (gamesVar) => {
    setArgentinaLeagueData(gamesVar);
    setArgentinaLeagueCount(gamesVar.length);
    console.log("setArgentinaLeagueData: " + JSON.stringify(gamesVar));
  };

  const IndiaLeagueUpdate = (gamesVar) => {
    setIndiaLeagueData(gamesVar);
    setIndiaLeagueCount(gamesVar.length);
    console.log("setIndiaLeagueData: " +gamesVar.length);
  };

  const NBAUpdate = (gamesVar) => {
    setNBAData(gamesVar);
    setNBACount(gamesVar.length);
    console.log("setNBAData: " + JSON.stringify(gamesVar));
  };

  const EuroLeagueUpdate = (gamesVar) => {
    setEuroLeagueData(gamesVar);
    setEuroLeagueCount(gamesVar.length);
    console.log("setEuroLeagueData: " + JSON.stringify(gamesVar));
  };

  const RussiaVTBLeagueUpdate = (gamesVar) => {
    setRussiaVTBLeagueData(gamesVar);
    setRussiaVTBLeagueCount(gamesVar.length);
    console.log("setRussiaVTBLeagueData: " + JSON.stringify(gamesVar));
  };

  const SpainACBLeagueUpdate = (gamesVar) => {
    setSpainACBLeagueData(gamesVar);
    setSpainACBLeagueCount(gamesVar.length);
    console.log("setSpainACBLeagueData: " + JSON.stringify(gamesVar));
  };

  const TurkeyTBSLLeagueUpdate = (gamesVar) => {
    setTurkeyTBSLLeagueData(gamesVar);
    setTurkeyTBSLLeagueCount(gamesVar.length);
    console.log("setTurkeyTBSLLeagueData: " + JSON.stringify(gamesVar));
  };

  const BrazilNBBLeagueUpdate = (gamesVar) => {
    setBrazilNBBLeagueData(gamesVar);
    setBrazilNBBLeagueCount(gamesVar.length);
    console.log("setBrazilNBBLeagueData: " + JSON.stringify(gamesVar));
  };

  const ChinaCBALeagueUpdate = (gamesVar) => {
    setChinaCBALeagueData(gamesVar);
    setChinaCBALeagueCount(gamesVar.length);
    console.log("setChinaCBALeagueData: " + JSON.stringify(gamesVar));
  };

  const NHLUpdate = (gamesVar) => {
    setNHLData(gamesVar);
    setNHLCount(gamesVar.length);
    console.log("setNHLData: " + JSON.stringify(gamesVar));
  };

  const AHLUpdate = (gamesVar) => {
    setAHLData(gamesVar);
    setAHLCount(gamesVar.length);
    console.log("setAHLData: " + JSON.stringify(gamesVar));
  };

  const KHLUpdate = (gamesVar) => {
    setKHLData(gamesVar);
    setKHLCount(gamesVar.length);
    console.log("setKHLData: " + JSON.stringify(gamesVar));
  };

  const AustralianOpenUpdate = (gamesVar) => {
    setAustralianOpenData(gamesVar);
    setAustralianOpenCount(gamesVar.length);
    console.log("setAustralianOpenData: " + JSON.stringify(gamesVar));
  };

  const USOpenUpdate = (gamesVar) => {
    setUSOpenData(gamesVar);
    setUSOpenCount(gamesVar.length);
    console.log("setUSOpenData: " + JSON.stringify(gamesVar));
  };

  // Handlers for radio button clicks
  const handleSportChange = (sport) => {
    setShowArrow(false);
    if (sport == "Football") {
      setFootball(!football);
      setBasketball(false);
      setHockey(false);
      setTennis(false);
    } if (sport == "Basketball") {
      setBasketball(!basketball);
      setFootball(false);
      setHockey(false);
      setTennis(false);
    } if (sport == "Hockey") {
      setHockey(!hockey);
      setFootball(false);
      setBasketball(false);
      setTennis(false);
    } if (sport == "Tennis") {
      setTennis(!tennis);
      setFootball(false);
      setBasketball(false);
      setHockey(false);
    }
  };

  
  const handleToggleAPI = (e) => {
    
 // Handlers for toggle apis call
    if (e == "UEFAChampionsLeague") {
      if (ChampionsLeagueData.length > 0) {
        setModalContent('UEFA Champions League');
        setShowModal(true);
      } else {
        setChampionsLeague(!ChampionsLeague);
      }
    }
    if (e == "UEFAEuropaLeague") {
      if (EuropaLeagueData.length > 0) {
        setModalContent('UEFA Europa League');
        setShowModal(true);
      } else {
        setEuropaLeague(!EuropaLeague);
      }
    }
    if (e == "EnglandPremierLeague") {
      if (EnglandLeagueData.length > 0) {
        setModalContent('England Premier League');
        setShowModal(true);
      } else {
        setEnglandLeague(!EnglandLeague);
      }
    }
    if (e == "GermanyBundesliga") {
      if (GermanyLeagueData.length > 0) {
        setModalContent('Germany Bundesliga');
        setShowModal(true);
      } else {
        setGermanyLeague(!GermanyLeague);
      }
    }
    if (e == "SpainLaLiga") {
      if (SpainLeagueData.length > 0) {
        setModalContent('Spain LaLiga');
        setShowModal(true);
      } else {
        setSpainLeague(!SpainLeague);
      }
    }
    if (e == "BrazilSerieA") {
      if (BrazilLeagueData.length > 0) {
        setModalContent('Brazil SerieA');
        setShowModal(true);
      } else {
        setBrazilLeague(!BrazilLeague);
      }
    }
    if (e == "ArgentinaSerieA") {
      if (ArgentinaLeagueData.length > 0) {
        setModalContent('Argentine Primera División');
        setShowModal(true);
      } else {
        setArgentinaLeague(!ArgentinaLeague);
      }
    }
    if (e == "IndiaLeague") {
      if (IndiaLeagueData.length > 0) {
        setModalContent('India League');
        setShowModal(true);
      } else {
        setIndiaLeague(!IndiaLeague);
      }
    }
    if (e == "NBA") {
      if (NBAData.length > 0) {
        setModalContent('NBA');
        setShowModal(true);
      } else {
        setNBA(!NBA);
      }
    }
    if (e == "EuroLeague") {
      if (EuroLeagueData.length > 0) {
        setModalContent('Euro League');
        setShowModal(true);
      } else {
        setEuroLeague(!EuroLeague);
      }
    }
    if (e == "RussiaVTBLeague") {
      if (RussiaVTBLeagueData.length > 0) {
        setModalContent('RussiaVTB');
        setShowModal(true);
      } else {
        setRussiaVTBLeague(!RussiaVTBLeague);
      }
    }
    if (e == "SpainACBLeague") {
      if (SpainACBLeagueData.length > 0) {
        setModalContent('SpainACB');
        setShowModal(true);
      } else {
        setSpainACBLeague(!SpainACBLeague);
      }
    }
    if (e == "TurkeyTBSLLeague") {
      if (TurkeyTBSLLeagueData.length > 0) {
        setModalContent('TurkeyTBSL');
        setShowModal(true);
      } else {
        setTurkeyTBSLLeague(!TurkeyTBSLLeague);
      }
    }
    if (e == "BrazilNBBLeague") {
      if (BrazilNBBLeagueData.length > 0) {
        setModalContent('BrazilNBB');
        setShowModal(true);
      } else {
        setBrazilNBBLeague(!BrazilNBBLeague);
      }
    }
    if (e == "ChinaCBALeague") {
      if (ChinaCBALeagueData.length > 0) {
        setModalContent('ChinaCBA');
        setShowModal(true);
      } else {
        setChinaCBALeague(!ChinaCBALeague);
      }
    }
    if (e == "NHL") {
      if (NHLData.length > 0) {
        setModalContent('NHL');
        setShowModal(true);
      } else {
        setNHL(!NHL);
      }  
    }
    if (e == "AHL") {
      if (AHLData.length > 0) {
        setModalContent('AHL');
        setShowModal(true);
      } else {
        setAHL(!AHL);
      }  
    }
    if (e == "KHL") {
      if (KHLData.length > 0) {
        setModalContent('KHL');
        setShowModal(true);
      } else {
        setKHL(!KHL);
      }  
    }
    if (e == "USOpen") {
      if (USOpenData.length > 0) {
        setModalContent('US Open');
        setShowModal(true);
      } else {
        setUSOpen(!USOpen);
      }
    }
    if (e == "AustralianOpen") {
      if (AustralianOpenData.length > 0) {
        setModalContent('AustralianOpen');
        setShowModal(true);
      } else {
        setAustralianOpen(!AustralianOpen);
      }
    }
  };


  const handleModalConfirm = () => {
    if (modalContent === 'UEFA Champions League') {
        setChampionsLeagueData([]);
        setChampionsLeagueCount(0);
        setChampionsLeague(false);
    }
    if (modalContent === 'UEFA Europa League') {
        setEuropaLeagueData([]);
        setEuropaLeagueCount(0);
        setEuropaLeague(false);
    }
    if (modalContent === 'England Premier League') {
      setEnglandLeagueData([]);
      setEnglandLeagueCount(0);
      setEnglandLeague(false);
    }
    if (modalContent === 'Germany Bundesliga') {
      setGermanyLeagueData([]);
      setGermanyLeagueCount(0);
      setGermanyLeague(false);
    }
    if (modalContent === 'Spain LaLiga') {
      setSpainLeagueData([]);
      setSpainLeagueCount(0);
      setSpainLeague(false);
    }
    if (modalContent === 'Brazil SerieA') {
      setBrazilLeagueData([]);
      setBrazilLeagueCount(0);
      setBrazilLeague(false);
    }
    if (modalContent === 'Argentine Primera División') {
      setArgentinaLeagueData([]);
      setArgentinaLeagueCount(0);
      setArgentinaLeague(false);
    }
    if (modalContent === 'India League') {
      setIndiaLeagueData([]);
      setIndiaLeagueCount(0);
      setIndiaLeague(false);
    }
    if (modalContent === 'NBA') {
        setNBAData([]);
        setNBACount(0);
        setNBA(false);
    }
    if (modalContent === 'Euro League') {
        setEuroLeagueData([]);
        setEuroLeagueCount(0);
        setEuroLeague(false);
    }
    if (modalContent === 'RussiaVTB') {
      setRussiaVTBLeagueData([]);
      setRussiaVTBLeagueCount(0);
      setRussiaVTBLeague(false);
    }
    if (modalContent === 'SpainACB') {
      setSpainACBLeagueData([]);
      setSpainACBLeagueCount(0);
      setSpainACBLeague(false);
    }
    if (modalContent === 'TurkeyTBSL') {
      setTurkeyTBSLLeagueData([]);
      setTurkeyTBSLLeagueCount(0);
      setTurkeyTBSLLeague(false);
    }
    if (modalContent === 'BrazilNBB') {
      setBrazilNBBLeagueData([]);
      setBrazilNBBLeagueCount(0);
      setBrazilNBBLeague(false);
    }
    if (modalContent === 'ChinaCBA') {
      setChinaCBALeagueData([]);
      setChinaCBALeagueCount(0);
      setChinaCBALeague(false);
    }
    if (modalContent === 'NHL') {
        setNHLData([]);
        setNHLCount(0);
        setNHL(false);
    }
    if (modalContent === 'AHL') {
      setAHLData([]);
      setAHLCount(0);
      setAHL(false);
    }
    if (modalContent === 'KHL') {
      setKHLData([]);
      setKHLCount(0);
      setKHL(false);
    }
    if (modalContent === 'US Open') {
        setUSOpenData([]);
        setUSOpenCount(0);
        setUSOpen(false);
    }
    if (modalContent === 'AustralianOpen') {
        setAustralianOpenData([]);
        setAustralianOpenCount(0);
        setAustralianOpen(false);
    }
    setShowModal(false);
  };
  
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleInfoModalClose = () => {
      setShowArrow(false);
  }
 

  const validate = () => {
    if (!ChampionsLeagueData.length &&
        !EuropaLeagueData.length && 
        !EnglandLeagueData.length &&
        !GermanyLeagueData.length &&
        !SpainLeagueData.length &&
        !BrazilLeagueData.length &&
        !ArgentinaLeagueData.length &&
        !IndiaLeagueData.length &&
        !NBAData.length && 
        !EuroLeagueData.length && 
        !RussiaVTBLeagueData.length && 
        !SpainACBLeagueData.length && 
        !TurkeyTBSLLeagueData.length && 
        !BrazilNBBLeagueData.length && 
        !ChinaCBALeagueData.length && 
        !NHLData.length && 
        !AHLData.length &&
        !KHLData.length &&
        !AustralianOpenData.length && 
        !USOpenData.length ) {
        toast.error(<span><Translate>No games selected</Translate></span>, {
          duration: 1500,
          style: {
            maxWidth: 800,
            fontSize: "14px",
            borderRadius: "15px",
          },
        });
      if (football == false && basketball == false && hockey == false && tennis == false) {
        setShowArrow(true);
      }
      return false;
    } else {
      return true;
    }
  };

  const nextStep = () => {
    if (validate()) {
      setStep(step + 1);
    }
  };

  const handleScroll = () => {
    setIsVisible(false);
  };

  const toggleVisibility = () => {
    setIsVisible(prevState => !prevState);
  };

  const prevStep = () => {
    setChampionsLeagueData([]);
    setEuropaLeagueData([]);
    setEnglandLeagueData([]);
    setGermanyLeagueData([]);
    setSpainLeagueData([]);
    setBrazilLeagueData([]);
    setArgentinaLeagueData([]);
    setIndiaLeagueData([]);
    setNBAData([]);
    setEuroLeagueData([]);
    setRussiaVTBLeagueData([]);
    setSpainACBLeagueData([]);
    setTurkeyTBSLLeagueData([]);
    setBrazilNBBLeagueData([]);
    setChinaCBALeagueData([]);
    setNHLData([]);
    setAHLData([]);
    setKHLData([]);
    setAustralianOpenData([]);
    setUSOpenData([]);

    setFootball(false);
    setBasketball(false);
    setHockey(false);
    setTennis(false);

    setChampionsLeague(false);
    setEuropaLeague(false);
    setEnglandLeague(false);
    setGermanyLeague(false);
    setSpainLeague(false);
    setBrazilLeague(false);
    setArgentinaLeague(false);
    setIndiaLeague(false);
    setNBA(false);
    setEuroLeague(false);
    setRussiaVTBLeague(false);
    setSpainACBLeague(false);
    setTurkeyTBSLLeague(false);
    setBrazilNBBLeague(false);
    setChinaCBALeague(false);
    setNHL(false);
    setAHL(false);
    setKHL(false);
    setAustralianOpen(false);
    setUSOpen(false);

    setChampionsLeagueCount(0);
    setIndiaLeagueCount(0);
    setEuropaLeagueCount(0);
    setEnglandLeagueCount(0);
    setGermanyLeagueCount(0);
    setSpainLeagueCount(0);
    setBrazilLeagueCount(0);
    setArgentinaLeagueCount(0);
    setNBACount(0);
    setEuroLeagueCount(0);
    setRussiaVTBLeagueCount(0);
    setSpainACBLeagueCount(0);
    setTurkeyTBSLLeagueCount(0);
    setBrazilNBBLeagueCount(0);
    setChinaCBALeagueCount(0);
    setNHLCount(0);
    setAHLCount(0);
    setKHLCount(0);
    setAustralianOpenCount(0);
    setUSOpenCount(0);

    setShowArrow(true);
    setStep(step - 1);
  };

  switch (step) {
    case 1:
      return (
        <Layout>
          <div className='filter-page'>
            <div className='container'>
              <div className='filter-box'>
                <Form>
                  <div className='row'>
                    <div className='col-md-3'>
                      {showArrow && (
                        <div className="arrow-up">
                          <Lottie className="arrow-up" options={arrowLottie} width={150} height={150} />
                        </div>
                      )}

                      {isVisible && (
                      <div className='filter-card filter-sport filter-select'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='filter-form'>
                              <div className='filter-form-games'>
                                <div className='filter-input radio'>
                                  <input
                                    type='radio'
                                    id='Football'
                                    name='radio'
                                    checked={football}
                                    onClick={() => handleSportChange('Football')}
                                  />
                                  <label className='radio-label' htmlFor='Football'>
                                  <Translate>Football</Translate>
                                  </label>
                                  {(ChampionsLeagueCount + EuropaLeagueCount + GermanyLeagueCount + SpainLeagueCount + BrazilLeagueCount + EnglandLeagueCount + ArgentinaLeagueCount + IndiaLeagueCount) > 0 && (
                                    <span className='badge badge-info'>
                                      {ChampionsLeagueCount + EuropaLeagueCount + GermanyLeagueCount + SpainLeagueCount + BrazilLeagueCount + EnglandLeagueCount + ArgentinaLeagueCount + IndiaLeagueCount}
                                    </span>
                                  )}
                                  {football && (
                                  <>
                                  <br/>
                                  <input
                                    className='input-api'
                                    type='radio'
                                    id='UEFAChampionsLeague'
                                    name='UEFAChampionsLeague'
                                    value='UEFA Champions League'
                                    checked={ChampionsLeague}
                                    onClick={(e) => handleToggleAPI(e.target.name)}
                                  />
                                  <label className='label-api' htmlFor='UEFAChampionsLeague'>
                                  <Translate>UEFA Champions League</Translate>
                                  </label>
                                  {ChampionsLeagueCount > 0 && (
                                    <span className='badge badge-info'>{ChampionsLeagueCount}</span>
                                  )}
                                  <br/>

                                  <input
                                    className='input-api'
                                    type='radio'
                                    id='UEFAEuropaLeague'
                                    name='UEFAEuropaLeague'
                                    value='UEFA Europa League'
                                    checked={EuropaLeague}
                                    onClick={(e) => handleToggleAPI(e.target.name)}
                                  />
                                  <label className='label-api' htmlFor='UEFAEuropaLeague'>
                                    <Translate>UEFA Europa League</Translate>
                                  </label>
                                  {EuropaLeagueCount > 0 && (
                                    <span className='badge badge-info'>{EuropaLeagueCount}</span>
                                  )}
                                  <br/>

                                  <input
                                    className='input-api'
                                    type='radio'
                                    id='EnglandPremierLeague'
                                    name='EnglandPremierLeague'
                                    value='England Premier League'
                                    checked={EnglandLeague}
                                    onClick={(e) => handleToggleAPI(e.target.name)}
                                  />
                                  <label className='label-api' htmlFor='EnglandPremierLeague'>
                                    <Translate>England Premier League</Translate>
                                  </label>
                                  {EnglandLeagueCount > 0 && (
                                    <span className='badge badge-info'>{EnglandLeagueCount}</span>
                                  )}
                                  <br/>
                                  <input
                                    className='input-api'
                                    type='radio'
                                    id='GermanyBundesliga'
                                    name='GermanyBundesliga'
                                    value='Germany Bundesliga'
                                    checked={GermanyLeague}
                                    onClick={(e) => handleToggleAPI(e.target.name)}
                                  />
                                  <label className='label-api' htmlFor='GermanyBundesliga'>
                                    <Translate>Germany Bundesliga</Translate>
                                  </label>
                                  {GermanyLeagueCount > 0 && (
                                    <span className='badge badge-info'>{GermanyLeagueCount}</span>
                                  )}
                                  <br/>
                                  <input
                                    className='input-api'
                                    type='radio'
                                    id='SpainLaLiga'
                                    name='SpainLaLiga'
                                    value='SpainLaLiga'
                                    checked={SpainLeague}
                                    onClick={(e) => handleToggleAPI(e.target.name)}
                                  />
                                  <label className='label-api' htmlFor='SpainLaLiga'>
                                    <Translate>Spain LaLiga</Translate>
                                  </label>
                                  {SpainLeagueCount > 0 && (
                                    <span className='badge badge-info'>{SpainLeagueCount}</span>
                                  )}
                                  <br/>
                                  <input
                                    className='input-api'
                                    type='radio'
                                    id='BrazilSerieA'
                                    name='BrazilSerieA'
                                    value='BrazilSerieA'
                                    checked={BrazilLeague}
                                    onClick={(e) => handleToggleAPI(e.target.name)}
                                  />
                                  <label className='label-api' htmlFor='BrazilSerieA'>
                                    <Translate>Brazil SerieA</Translate>
                                  </label>
                                  {BrazilLeagueCount > 0 && (
                                    <span className='badge badge-info'>{BrazilLeagueCount}</span>
                                  )}
                                  <br/>
                                  <input
                                    className='input-api'
                                    type='radio'
                                    id='ArgentinaSerieA'
                                    name='ArgentinaSerieA'
                                    value='ArgentinaSerieA'
                                    checked={ArgentinaLeague}
                                    onClick={(e) => handleToggleAPI(e.target.name)}
                                  />
                                  <label className='label-api' htmlFor='ArgentinaSerieA'>
                                    <Translate>Argentine Primera</Translate>
                                  </label>
                                  {ArgentinaLeagueCount > 0 && (
                                    <span className='badge badge-info'>{ArgentinaLeagueCount}</span>
                                  )}
                                  <br/>
                                  <input
                                    className='input-api'
                                    type='radio'
                                    id='IndiaLeague'
                                    name='IndiaLeague'
                                    value='India League'
                                    checked={IndiaLeague}
                                    onClick={(e) => handleToggleAPI(e.target.name)}
                                  />
                                  <label className='label-api' htmlFor='IndiaLeague'>
                                    <Translate>India I League</Translate>
                                  </label>
                                  {IndiaLeagueCount > 0 && (
                                    <span className='badge badge-info'>{IndiaLeagueCount}</span>
                                  )}
                                </>
                              )}
                                </div>
                              </div>

                              <div className='filter-form-games'>
                                <div className='filter-input radio'>
                                  <input
                                    type='radio'
                                    id='Basketball'
                                    name='radio'
                                    checked={basketball}
                                    onClick={() => handleSportChange('Basketball')}
                                  />
                                  <label className='radio-label' htmlFor='Basketball'>
                                    <Translate>Basketball</Translate>
                                  </label>
                                  {(NBACount + EuroLeagueCount + RussiaVTBLeagueCount + SpainACBLeagueCount + TurkeyTBSLLeagueCount + BrazilNBBLeagueCount + ChinaCBALeagueCount) > 0 && (
                                    <span className='badge badge-info'>
                                      {NBACount + EuroLeagueCount + RussiaVTBLeagueCount + SpainACBLeagueCount + TurkeyTBSLLeagueCount + BrazilNBBLeagueCount + ChinaCBALeagueCount}
                                    </span>
                                  )}
                                  {basketball && (
                                  <><br/>
                                  <div className='radio-options'>
                                    <input
                                      className='input-api'
                                      type='radio'
                                      id='NBA'
                                      name='NBA'
                                      value='NBA'
                                      checked={NBA}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='NBA'>
                                      NBA
                                    </label>
                                    {NBACount > 0 && (
                                      <span className='badge badge-info'>{NBACount}</span>
                                    )}
                                    <br/>
                                    <input
                                      className='input-api'
                                      type='radio'
                                      id='EuroLeague'
                                      name='EuroLeague'
                                      value='EuroLeague'
                                      checked={EuroLeague}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='EuroLeague'>
                                      <Translate>EuroLeague</Translate>
                                    </label>
                                    {EuroLeagueCount > 0 && (
                                      <span className='badge badge-info'>{EuroLeagueCount}</span>
                                    )}
                                    <br/>
                                    <input
                                      className='input-api'
                                      type='radio'
                                      id='RussiaVTBLeague'
                                      name='RussiaVTBLeague'
                                      value='RussiaVTBLeague'
                                      checked={RussiaVTBLeague}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='RussiaVTBLeague'>
                                    <Translate>Russia</Translate> VTB
                                    </label>
                                    {RussiaVTBLeagueCount > 0 && (
                                      <span className='badge badge-info'>{RussiaVTBLeagueCount}</span>
                                    )}
                                    <br/>
                                    <input
                                      className='input-api'
                                      type='radio'
                                      id='SpainACBLeague'
                                      name='SpainACBLeague'
                                      value='SpainACBLeague'
                                      checked={SpainACBLeague}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='SpainACBLeague'>
                                    <Translate>Spain</Translate> ACB
                                    </label>
                                    {SpainACBLeagueCount > 0 && (
                                      <span className='badge badge-info'>{SpainACBLeagueCount}</span>
                                    )}
                                    <br/>
                                    <input
                                      className='input-api'
                                      type='radio'
                                      id='TurkeyTBSLLeague'
                                      name='TurkeyTBSLLeague'
                                      value='TurkeyTBSLLeague'
                                      checked={TurkeyTBSLLeague}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='TurkeyTBSLLeague'>
                                    <Translate>Turkey</Translate> TBSL
                                    </label>
                                    {TurkeyTBSLLeagueCount > 0 && (
                                      <span className='badge badge-info'>{TurkeyTBSLLeagueCount}</span>
                                    )}
                                    <br/>
                                    <input
                                      className='input-api'
                                      type='radio'
                                      id='BrazilNBBLeague'
                                      name='BrazilNBBLeague'
                                      value='BrazilNBBLeague'
                                      checked={BrazilNBBLeague}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='BrazilNBBLeague'>
                                    <Translate>Brazil</Translate> NBB
                                    </label>
                                    {BrazilNBBLeagueCount > 0 && (
                                      <span className='badge badge-info'>{BrazilNBBLeagueCount}</span>
                                    )}
                                    <br/>
                                    <input
                                      className='input-api'
                                      type='radio'
                                      id='ChinaCBALeague'
                                      name='ChinaCBALeague'
                                      value='ChinaCBALeague'
                                      checked={ChinaCBALeague}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='ChinaCBALeague'>
                                    <Translate>China</Translate> CBA
                                    </label>
                                    {ChinaCBALeagueCount > 0 && (
                                      <span className='badge badge-info'>{ChinaCBALeagueCount}</span>
                                    )}
                                  </div>
                                  </>
                                )}
                                </div>
                              </div>

                              <div className='filter-form-games'>
                                <div className='filter-input radio'>
                                  <input
                                    type='radio'
                                    id='Hockey'
                                    name='radio'
                                    checked={hockey}
                                    onClick={() => handleSportChange('Hockey')}
                                  />
                                  <label className='radio-label' htmlFor='Hockey'>
                                    <Translate>Hockey</Translate>
                                  </label>
                                  {(NHLCount + AHLCount + KHLCount) > 0 && (
                                    <span className='badge badge-info'>
                                      {NHLCount + AHLCount + KHLCount}
                                    </span>
                                  )}
                                  {hockey && (
                                  <>
                                  <br/>
                                  <div className='radio-options'>
                                    <input
                                      className='input-api'
                                      type='radio'
                                      id='NHL'
                                      name='NHL'
                                      value='NHL'
                                      checked={NHL}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='NHL'>
                                      NHL
                                    </label>
                                    {NHLCount > 0 && (
                                      <span className='badge badge-info'>{NHLCount}</span>
                                    )}
                                  </div>
                                  <div className='radio-options'>
                                    <input
                                      className='input-api'
                                      type='radio'
                                      id='AHL'
                                      name='AHL'
                                      value='AHL'
                                      checked={AHL}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='AHL'>
                                      AHL
                                    </label>
                                    {AHLCount > 0 && (
                                      <span className='badge badge-info'>{AHLCount}</span>
                                    )}
                                  </div>
                                  <div className='radio-options'>
                                    <input
                                      className='input-api'
                                      type='radio'
                                      id='KHL'
                                      name='KHL'
                                      value='KHL'
                                      checked={KHL}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='KHL'>
                                      KHL
                                    </label>
                                    {KHLCount > 0 && (
                                      <span className='badge badge-info'>{KHLCount}</span>
                                    )}
                                  </div>
                                  </>
                                )}
                                </div>
                              </div>

                              <div className='filter-form-games'>
                                <div className='filter-input radio'>
                                  <input
                                    type='radio'
                                    id='Tennis'
                                    name='radio'
                                    checked={tennis}
                                    onClick={() => handleSportChange('Tennis')}
                                  />
                                  <label className='radio-label' htmlFor='Tennis'>
                                    <Translate>Tennis</Translate>
                                  </label>
                                  {(USOpenCount + AustralianOpenCount) > 0 && (
                                    <span className='badge badge-info'>
                                      {USOpenCount + AustralianOpenCount}
                                    </span>
                                  )}
                                  {tennis && (
                                    <><br/>
                                  <div className='radio-options'>
                                  <input
                                      className='input-api'
                                      type='radio'
                                      id='USOpen'
                                      name='USOpen'
                                      value='US Open'
                                      checked={USOpen}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='USOpen'>
                                    <Translate>US Open</Translate>
                                    </label>
                                    {USOpenCount > 0 && (
                                      <span className='badge badge-info'>{USOpenCount}</span>
                                    )}
                                    <br/>
                                    <input
                                      className='input-api'
                                      type='radio'
                                      id='AustralianOpen'
                                      name='AustralianOpen'
                                      value='AustralianOpen'
                                      checked={AustralianOpen}
                                      onClick={(e) => handleToggleAPI(e.target.name)}
                                    />
                                    <label className='label-api' htmlFor='AustralianOpen'>
                                    <Translate>Australian Open</Translate>
                                    </label>
                                    {AustralianOpenCount > 0 && (
                                      <span className='badge badge-info'>{AustralianOpenCount}</span>
                                    )}
                                  </div>
                                  </>
                                )}

                                </div>
                              </div>
                            </div>

                            <div className='card-footer text-center'>
                              <Button className='btn-gold' onClick={nextStep}>
                                <Translate>Start a bet</Translate>
                              </Button>
                            </div>

                            <>
                              {hasData && !isMobile && (
                                <LogContainer
                                  ChampionsLeagueData={ChampionsLeagueData}
                                  EuropaLeagueData={EuropaLeagueData}
                                  EnglandLeagueData={EnglandLeagueData}
                                  GermanyLeagueData={GermanyLeagueData}
                                  SpainLeagueData={SpainLeagueData}
                                  BrazilLeagueData={BrazilLeagueData}
                                  ArgentinaLeagueData={ArgentinaLeagueData}
                                  IndiaLeagueData={IndiaLeagueData}
                                  NBAData={NBAData}
                                  EuroLeagueData={EuroLeagueData}
                                  RussiaVTBLeagueData={RussiaVTBLeagueData}
                                  SpainACBLeagueData={SpainACBLeagueData}
                                  TurkeyTBSLLeagueData={TurkeyTBSLLeagueData}
                                  BrazilNBBLeagueData={BrazilNBBLeagueData}
                                  ChinaCBALeagueData={ChinaCBALeagueData}
                                  NHLData={NHLData}
                                  AHLData={AHLData}
                                  KHLData={KHLData}
                                  AustralianOpenData={AustralianOpenData}
                                  USOpenData={USOpenData}
                                />
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    )}

                    {isMobile > 0 && (
                      <button type="button" className="floating-button" onClick={toggleVisibility}>
                          {isVisible ? (
                              <i className="fa fa-minus" aria-hidden="true"></i>
                            ) : (
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            )}
                      </button>
                      
                    )}
                    </div>

                    <div className='col-md-9'>
                    {ChampionsLeague && (
                      <div ref={championsLeagueRef}>
                        <BetFilter 
                          title="UEFA Champions League"
                          selectionUpdate={ChampionsLeagueUpdate}
                          leagueId={817}
                          className="football-page"
                          isDrawable={true}
                          endDays={6}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {EuropaLeague && (
                      <div ref={europaLeagueRef}>
                        <BetFilter 
                          title="UEFA Europa League"
                          selectionUpdate={EuropaLeagueUpdate}
                          leagueId={818}
                          className="football-page"
                          isDrawable={true}
                          endDays={6}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {EnglandLeague && (
                      <div ref={EnglandLeagueRef}>
                          <BetFilter 
                          title="England Premier League"
                          selectionUpdate={EnglandLeagueUpdate}
                          leagueId={317}
                          className="football-page"
                          isDrawable={true}
                          endDays={3}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {GermanyLeague && (
                      <div ref={GermanyLeagueRef}>
                        <BetFilter 
                          title="Germany Bundesliga"
                          selectionUpdate={GermanyLeagueUpdate}
                          leagueId={512}
                          className="football-page"
                          isDrawable={true}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {SpainLeague && (
                      <div ref={SpainLeagueRef}>
                        <BetFilter 
                          title="Spain LaLiga"
                          selectionUpdate={SpainLeagueUpdate}
                          leagueId={251}
                          className="football-page"
                          isDrawable={true}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {BrazilLeague && (
                      <div ref={BrazilLeagueRef}>
                        <BetFilter 
                          title="Brazil SerieA"
                          selectionUpdate={BrazilLeagueUpdate}
                          leagueId={905}
                          className="football-page"
                          isDrawable={true}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {ArgentinaLeague && (
                      <div ref={ArgentinaLeagueRef}>
                        <BetFilter 
                          title="Argentine Primera División"
                          selectionUpdate={ArgentinaLeagueUpdate}
                          leagueId={154}
                          className="football-page"
                          isDrawable={true}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {IndiaLeague && (
                      <div ref={IndiaLeagueRef}>
                        <BetFilter 
                          title="India I-League"
                          selectionUpdate={IndiaLeagueUpdate}
                          leagueId={565}
                          className="football-page"
                          isDrawable={true}
                          endDays={5}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {NBA && (
                      <div ref={nbaRef}>
                        <BetFilter 
                          title="NBA"
                          selectionUpdate={NBAUpdate}
                          leagueId={7422}
                          className="basketball-page"
                          isDrawable={false}
                          endDays={2}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {EuroLeague && (
                      <div ref={euroLeagueRef}>
                        <BetFilter 
                          title="EuroLeague"
                          selectionUpdate={EuroLeagueUpdate}
                          leagueId={7473}
                          className="basketball-page"
                          isDrawable={false}
                          endDays={6}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {RussiaVTBLeague && (
                      <div ref={RussiaVTBLeagueRef}>
                        <BetFilter 
                          title="VTB United League"
                          selectionUpdate={RussiaVTBLeagueUpdate}
                          leagueId={7377}
                          className="basketball-page"
                          isDrawable={false}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {SpainACBLeague && (
                      <div ref={SpainACBLeagueRef}>
                        <BetFilter 
                          title="Spain Liga ACB"
                          selectionUpdate={SpainACBLeagueUpdate}
                          leagueId={7378}
                          className="basketball-page"
                          isDrawable={false}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {TurkeyTBSLLeague && (
                      <div ref={TurkeyTBSLLeagueRef}>
                        <BetFilter 
                          title="Turkey Super League"
                          selectionUpdate={TurkeyTBSLLeagueUpdate}
                          leagueId={7403}
                          className="basketball-page"
                          isDrawable={false}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {BrazilNBBLeague && (
                      <div ref={BrazilNBBLeagueRef}>
                        <BetFilter 
                          title="Novo Basquete Brasil"
                          selectionUpdate={BrazilNBBLeagueUpdate}
                          leagueId={7443}
                          className="basketball-page"
                          isDrawable={false}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {ChinaCBALeague && (
                      <div ref={ChinaCBALeagueRef}>
                        <BetFilter 
                          title="Chinese Basketball Association"
                          selectionUpdate={ChinaCBALeagueUpdate}
                          leagueId={7445}
                          className="basketball-page"
                          isDrawable={false}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {NHL && (
                      <div ref={NHLRef}>
                        <BetFilter 
                          title="National Hockey League"
                          selectionUpdate={NHLUpdate}
                          leagueId={7588}
                          className="hockey-page"
                          isDrawable={false}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {AHL && (
                      <div ref={AHLRef}>
                        <BetFilter 
                          title="American Hockey League"
                          selectionUpdate={AHLUpdate}
                          leagueId={7591}
                          className="hockey-page"
                          isDrawable={false}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {KHL && (
                      <div ref={KHLRef}>
                        <BetFilter 
                          title="Kontinental Hockey League"
                          selectionUpdate={KHLUpdate}
                          leagueId={7598}
                          className="hockey-page"
                          isDrawable={false}
                          endDays={4}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {AustralianOpen && (
                      <div ref={australianOpenRef}>
                        <BetFilter 
                          title="Australian Open"
                          selectionUpdate={AustralianOpenUpdate}
                          leagueId={6878}
                          className="tennis-page"
                          isDrawable={false}
                          endDays={3}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    {USOpen && (
                      <div ref={usOpenRef}>
                        <BetFilter 
                          title="US Open"
                          selectionUpdate={USOpenUpdate}
                          leagueId={6881}
                          className="tennis-page"
                          isDrawable={false}
                          endDays={3}
                          onScroll={handleScroll}
                        />
                      </div>
                    )}
                    </div>
                  </div>
                </Form>
              </div>

            </div>
            <>
            <Modal show={showModal} onHide={handleModalClose} className='friends-popup' centered>
              <Modal.Body>
                <div className="content">
                  <div className="loading">
                    <small className='sm-close-text'>Are you sure you want to close the <span style={{color: "#8461dc"}}>{modalContent}</span>? <br></br>This action will delete all your game selections.</small>
                  </div>
                  <div className='selection-btns'>
                    <Button className="yes" onClick={handleModalConfirm}>Yes</Button>
                    <Button className="no" onClick={handleModalClose}>No</Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            </>
          </div>
        </Layout>
      );

    case 2:
      return (
        <SportBet
          prevStep={prevStep}
          ChampionsLeagueData={ChampionsLeagueData}
          EuropaLeagueData={EuropaLeagueData}
          EnglandLeagueData={EnglandLeagueData}
          GermanyLeagueData={GermanyLeagueData}
          SpainLeagueData={SpainLeagueData}
          BrazilLeagueData={BrazilLeagueData}
          ArgentinaLeagueData={ArgentinaLeagueData}
          IndiaLeagueData={IndiaLeagueData}
          NBAData={NBAData}
          EuroLeagueData={EuroLeagueData}
          RussiaVTBLeagueData={RussiaVTBLeagueData}
          SpainACBLeagueData={SpainACBLeagueData}
          TurkeyTBSLLeagueData={TurkeyTBSLLeagueData}
          BrazilNBBLeagueData={BrazilNBBLeagueData}
          ChinaCBALeagueData={ChinaCBALeagueData}
          NHLData={NHLData}
          AHLData={AHLData}
          KHLData={KHLData}
          AustralianOpenData={AustralianOpenData}
          USOpenData={USOpenData}

          ChampionsLeagueCount={ChampionsLeagueCount}
          EuropaLeagueCount={EuropaLeagueCount}
          EnglandLeagueCount={EnglandLeagueCount}
          GermanyLeagueCount={GermanyLeagueCount}
          SpainLeagueCount={SpainLeagueCount}
          BrazilLeagueCount={BrazilLeagueCount}
          ArgentinaLeagueCount={ArgentinaLeagueCount}
          IndiaLeagueCount={IndiaLeagueCount}
          NBACount={NBACount}
          EuroLeagueCount={EuroLeagueCount}
          RussiaVTBLeagueCount={RussiaVTBLeagueCount}
          SpainACBLeagueCount={SpainACBLeagueCount}
          TurkeyTBSLLeagueCount={TurkeyTBSLLeagueCount}
          BrazilNBBLeagueCount={BrazilNBBLeagueCount}
          ChinaCBALeagueCount={ChinaCBALeagueCount}
          NHLCount={NHLCount}
          AHLCount={AHLCount}
          KHLCount={KHLCount}
          AustralianOpenCount={AustralianOpenCount}
          USOpenCount={USOpenCount}

          uid={uid}
        />
      );

    default:
      return null;
  }
};

export default SportsFilter;