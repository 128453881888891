import {useContext, useEffect, useState} from 'react';
import { MainContext } from '../../context';
import { useWeb3React } from "@web3-react/core";
import { metaMask } from './metamask';
import axios from 'axios';
import { ethers } from "ethers";
import toast from "react-hot-toast";
import { coinbaseWallet } from './coinbaseWallet';
import connecting from "../../assets/animation/connecting";
import Lottie from "react-lottie";
import metalogo from "../../assets/icons/MetaMask.png";
import coinbaselogo from "../../assets/icons/coinsbase.png";
import UrlConfig from "../../utils/ApiConfig";
import { Translate } from "react-auto-translate";

const connectOptions = {
    loop: true,
    autoplay: true,
    animationData: connecting,
  };

function ReConnect() {
  const { firebaseUser } = useContext(MainContext);
  const [balanceInUSD, setBalanceInUSD] = useState(0);
  const { account, provider } = useWeb3React();
  const [balance, setBalance] = useState('');
  const pathname = window.location.pathname;
  const [walletName, setwalletName] = useState();
  const isWalletConnected = localStorage.getItem('isWalletConnected');
  const [connected, setConnected] = useState(false);
  const context = useContext(MainContext);

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted
    // Get wallet provider from firebase
    if (!context.firebaseUser || !context.token) return;
    const { baseStats } = context;
    if (baseStats) {
        const fireProvider = baseStats.userDoc.widProvider;
        console.log(fireProvider);
        if (isMounted) {
            setwalletName(fireProvider);
        }
    }

    // Check wallet connection
    if (window.ethereum) {
        window.ethereum.request({ method: 'eth_accounts' }).then(handleAccountsChanged).catch(console.error);
        function handleAccountsChanged(accounts) {
            if (accounts.length === 0) {
                localStorage.setItem('isWalletConnected', false);
                } 
            }
        }

    const getMaticUsdPrice = async (matic) => {
        const res = await axios.get(UrlConfig.maticUsdPriceUrl);
        if (res.data && isMounted) {
            const curentPrice = res.data.market_data.current_price.usd;
            setBalanceInUSD((curentPrice * matic).toFixed(2));
        }
    };

    const getWalletBalance = async () => {
        if (account) {
            localStorage.setItem('isWalletConnected', true);
            const value = await provider?.getBalance(account);
            if (value && isMounted) {
                setBalance(Number(ethers.utils.formatEther(value)).toFixed(2));
                getMaticUsdPrice(Number(ethers.utils.formatEther(value)));
            }
            document.cookie = `walletid= ${account}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
            localStorage.setItem('walletid', account);
        } else {
            localStorage.setItem('isWalletConnected', false);
        }
    };
    if (!account) {
        localStorage.setItem('isWalletConnected', false);
    } else if (account) {
        localStorage.setItem('isWalletConnected', true);
        getWalletBalance();
    }
    return () => {
        isMounted = false; // Cleanup: mark component as unmounted
    };

    //End of useEffect
}, [account, provider, firebaseUser]);

        async function handleConnectWallet(walletType) {
          walletType = walletType || walletName; // Default to walletName if walletType is not provided
          try {
              if (walletType === 'MetaMask') {
                  // Activate MetaMask Wallet
                  await metaMask.activate({
                      chainId: 137, // Polygon Mainnet
                      chainName: "Polygon Mainnet",
                      nativeCurrency: { name: 'Pol', symbol: 'POL', decimals: 18 },
                      rpcUrls: ["https://polygon-rpc.com/"],
                      blockExplorerUrls: ["https://polygonscan.com/"]
                  });
                  localStorage.setItem('isWalletConnected', true);
                  setConnected(true);
              }
              if (walletType === 'Coinbase') {
                  // Activate Coinbase Wallet
                  coinbaseWallet.activate({
                      chainId: 137, // Polygon Mainnet
                      chainName: "Polygon Mainnet",
                      nativeCurrency: {
                          name: 'Pol',
                          symbol: 'POL',
                          decimals: 18,
                      },
                      rpcUrls: ["https://polygon-rpc.com/"],
                      blockExplorerUrls: ["https://polygonscan.com/"]
                  });
                  localStorage.setItem('isWalletConnected', true);
                  setConnected(true);
              } 
          } catch (error) {
              if (error.message.includes('installed')) {
                  // If MetaMask OR Coinbase is not installed, show a toast
                  toast.error(
                      <span>
                      <Translate>Please installed </Translate>
                          {walletName}
                      <Translate> to continue</Translate>
                      </span>, 
                      {
                          duration: 3000,
                          style: { maxWidth: 800, fontSize: "14px", borderRadius: "15px" }
                      });
              }
              if (error.code === -32002) {
                toast.loading ( 
                  <span>
                    {walletName}
                  <Translate> request pending..</Translate>
                  </span>,  
                {
                  duration: 3000,
                  style: {
                      maxWidth: 800,
                      fontSize: "14px",
                      borderRadius: "15px",
                  },
                });
              }
          }
        }

          // Automatically connect wallet after refresh
            useEffect(() => {
                    if (walletName === 'MetaMask' && !account) {
                        handleConnectWallet('MetaMask');  // Automatically connect MetaMask
                    } else if (walletName === 'Coinbase' && !account) {
                        handleConnectWallet('Coinbase');  // Automatically connect Coinbase
                    }
            }, [walletName, account]);

        if (pathname === "/profile") {
            return (
              <>
                {connected && isWalletConnected === "false" && (
                  <div className="connectOptions">
                    <Lottie options={connectOptions} height={250} width={250} />
                  </div>
                )}
                {isWalletConnected === "true" ? (
                  <div>
                    <div>
                      <small><b className={walletName}>{walletName}</b></small><br />
                      <small style={{ fontSize: "0.95em" }}>
                        <span style={{ color: '#8461dc' }}>
                          POL<span style={{ fontSize: "10px" }}>(MATIC)</span>
                        </span> {balance}
                      </small><br />
                      <small style={{ fontSize: "0.95em" }}>
                        <span style={{ color: '#8461dc' }}>USD:</span> {balanceInUSD}
                      </small>
                    </div>
                  </div>
                ) : (
                  <div className="container-list">
                    <ul className="list">
                    {(walletName === "MetaMask") && (
                        <li className="list-item">
                            <button 
                            className="wallet-btn wallet-btn-profile"
                            onClick={() => handleConnectWallet('MetaMask')}>
                            <div>
                                <img src={metalogo} className="metamasl-logo profile-icon-wallet" alt="MetaMask" />
                            </div>
                            <div className="button-content">
                                <span className="wallet-text-line wallet-t-re">MetaMask</span>
                            </div>
                            </button>
                        </li>
                    )}
                    {(walletName === "Coinbase") && (
                      <li className="list-item">
                        <button 
                          className="wallet-btn wallet-btn-profile"
                          onClick={() => handleConnectWallet('Coinbase')}>
                          <div>
                            <img src={coinbaselogo} className="metamasl-logo profile-icon-wallet" alt="Coinbase" />
                          </div>
                          <div className="button-content">
                            <span className="wallet-text-line wallet-t-re">Coinbase</span>
                          </div>
                        </button>
                      </li>
                    )}
                    </ul>
                  </div>
                )}
              </>
            );
          } else {
            // Render only wallet buttons if not on the profile
            return (
              <div className="container-list">
                <ul className="list">
                {(walletName === "MetaMask") && (
                  <li className="list-item">
                    <button 
                      className="wallet-btn"
                      onClick={() => handleConnectWallet('MetaMask')}>
                      <div>
                        <img src={metalogo} className="metamasl-logo profile-icon-wallet" alt="MetaMask" />
                      </div>
                      <div className="button-content">
                        <span className="wallet-text-line wallet-t-re">MetaMask</span>
                      </div>
                    </button>
                  </li>
                )}
                {(walletName === "Coinbase") && (
                  <li className="list-item">
                    <button 
                      className="wallet-btn"
                      onClick={() => handleConnectWallet('Coinbase')}>
                      <div>
                        <img src={coinbaselogo} className="metamasl-logo profile-icon-wallet" alt="Coinbase" />
                      </div>
                      <div className="button-content">
                        <span className="wallet-text-line wallet-t-re">Coinbase</span>
                      </div>
                    </button>
                  </li>
                )}
                </ul>
              </div>
            );
          }
}

export default ReConnect;