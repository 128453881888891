import React, { Component } from "react";
import Layout from "../layout/layout";
import FriendList from "./friendlist";
import BlockList from "./blocklist";
import PeopleMayKnow from "./peoplemayknow";
import Loader from "../loader/loader";
import { Redirect } from "react-router-dom";
import "./friends.css";
import { MainContext } from "../../context";

export class Friends extends Component {
	static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      //User personal data
      pending: true,
      redirect: null,
      uid: null,
      signedin: false,
      nick: "",
      coins: 999,
      idToken: "",
      countryCode: "",
    };
  }
  observerUNSUB;

  async componentDidMount() {
	const context = this.context;    
       
	const { baseStats } = context
        if (baseStats) {
          this.setState({
            pending: false,
            signedin: true,
            uid: context.firebaseUser.uid,
            idToken: context.token,
            nick: context.firebaseUser.displayName,
            friends: this.frTable,
            coins: baseStats.total.coins,
            countryCode: baseStats.userDoc.countryCode,
          });
        }
     
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (this.state.pending) {
      return <Loader />;
    }

    return (
      <Layout>
        <div className="friends-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <FriendList
                  uid={this.state.uid}
                  signedin={this.state.signedin}
                  nick={this.state.nick}
                  redirect={this.state.redirect}
                  coins={this.state.coins}
                  pending={this.state.pending}
                  idToken={this.state.idToken}
                  observerUNSUB={this.observerUNSUB}
                />

                {/* <BlockList
                  uid={this.state.uid}
                  signedin={this.state.signedin}
                  nick={this.state.nick}
                  redirect={this.state.redirect}
                  coins={this.state.coins}
                  pending={this.state.pending}
                  idToken={this.state.idToken}
                  observerUNSUB={this.observerUNSUB}
                /> */}

                <PeopleMayKnow
                  uid={this.state.uid}
                  signedin={this.state.signedin}
                  nick={this.state.nick}
                  redirect={this.state.redirect}
                  coins={this.state.coins}
                  pending={this.state.pending}
                  idToken={this.state.idToken}
                  observerUNSUB={this.observerUNSUB}
                  countryCode={this.state.countryCode}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Friends;
