import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Translate } from "react-auto-translate";
import { Link, useParams } from "react-router-dom";
import { getAsset } from "../../../collection";
import Layout from "../../layout/layout";

const NftDetails = () => {
  const { tokenId } = useParams();
  const [nftData, setNftData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAsset(
        "POLYGON",
        "0x48FF40070eAefBEf26e1cEF07Cd202643C02E9e1",
        tokenId
      );
      setNftData(res.nft);
    };
    if (tokenId) fetchData();
  }, [tokenId]);
  return (
    <Layout>
    <div className="nft-detail-page">
      <div className="container">
        <div className="nft-detail-grid">
          <div className="nft-detail-image">
            <img src={nftData?.image_url} alt="" />
          </div>
          <div className="nft-detail-box">
            <p><Translate>LasVegasPandas</Translate></p>
            <h2>{nftData?.name}</h2>
            <div className="nft-info-link">
              <a
                href={`https://opensea.io/assets/matic/0x48ff40070eaefbef26e1cef07cd202643c02e9e1/${tokenId}`}
                target="_blank"
                rel="noreferrer"
              >
                <Translate>Open Sea</Translate>
              </a>
            </div>
            <div className="nft-created-by">
              <a
                href="https://opensea.io/collection/lasvegaspandas"
                target="_blank"
                rel="noreferrer"
              >
                <h6>
                  <Translate>Owned By</Translate> <span>LasVegas</span>
                </h6>
              </a>
            </div>
            <div className="owner-count">
              <h5>{nftData?.description}</h5>
            </div>
            <div className="nft-current-price">
              {/*<p>Current Price</p>
              <h2>0.0339</h2>*/}
              <div className="nft-current-action inline-btn">
                <Link to={"/marketplace/nft"}>
                  <Button className="btn-dark">
                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </Button>
                </Link>
  
                <Button
                  className="btn btn-warning"
                  onClick={() =>
                    window.open(
                      `https://opensea.io/assets/matic/0x48ff40070eaefbef26e1cef07cd202643c02e9e1/${tokenId}`,
                      "_blank"
                    )
                  }
                >
                  <Translate>Buy Now</Translate>
                </Button>
              </div>
            </div>
          </div>
        </div>
  
        <div className="nft-traits-section">
          <h2><Translate>TRAITS</Translate></h2>
          <div className="nft-traits-grid">
            {nftData?.traits?.map((trait) => (
              <div className="nft-traits-grid-box" key={trait.trait_type}>
                <div className="nft-traits-grid-head">
                  <h6><Translate>{trait.trait_type}</Translate></h6>
                </div>
                <h5>{trait.value}: {trait.trait_count}</h5>
              </div>
            ))}
          </div>
        </div>
  
        <div className="nft-collection-section">
          <h4><Translate>COLLECTION</Translate></h4>
          <div className="nft-collection-banner">
            <img
              src="https://lh3.googleusercontent.com/usmcexBGu4dMsiVjYMxAAKHOc5q30xucTzfScWRu6-czQ5EWq5LvpIxGv7i3R7ckwtG73xEcQt6yaeM7WCmLghivxBX9MZlO3Zz-=h600"
              alt="WEJE Collection"
            />
          </div>
          <div className="nft-owner-detail">
            <img
              src="https://lh3.googleusercontent.com/W2UfLnfx08cd4AMbg9-L0d_7ZNeEaqCLXbq5Rfri-5wRQr6PbcFESjq_KRxz-gVXdUu9q3cCqtQGRo-7Sn9B8X3qaEc7HDizdPR6=s150"
              alt=""
            />
            <h5>WeJe_com</h5>
            <h6>
              <Translate>Owned By</Translate> <span>WeJe_com</span>
            </h6>
          </div>
          <div className="owner-count">
            <h5>
              <Translate>
                Currently, the owners statistic and traded volume shown by OpenSea
                are inaccurate for this collection. The real number including
                staked Rollbots is over 3k and majori...
              </Translate>
            </h5>
          </div>
          <div className="nft-info-link">
            <a
              href="https://opensea.io/collection/lasvegaspandas"
              target="_blank"
              rel="noreferrer"
            >
              <Translate>More Info</Translate>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  
  );
};

export default NftDetails;
