import React, { useState } from "react";
import { Link } from "react-router-dom";
import Chat from "../chat/chat";
import { Translate } from "react-auto-translate";
import "./footer.css";

const Footer = ({ handleIsQue, handleStartTimer }) => {
  const [open, setOpen] = useState();

  const handleClick = (e) => {
    setOpen(e);
  };

  const pathname = window.location.pathname;

    if (pathname === "/" || pathname === "/help-center") {
        return (
            <div className="footer">
            <div className="container">
                <div className="footer-top ">
                <div className="privacy-links">
                    <Link to="/privacy-policy"><Translate>Privacy Policy</Translate></Link>
                    <Link to="/terms-of-use"><Translate>Term and Conditions</Translate></Link>
                </div>
                <div className="google-btns">
                </div>
                <div className="copyright-text text-center">
                    <p><a href="https://t.me/wejedefi" target="_blank">
                    <i className="fa fa-telegram heteic"></i>
                    </a> © 2024 <Link to="/">WeJe</Link> <Translate>All Rights Reserved</Translate></p>
                </div>
                </div>
            </div>
            </div>
        );
    } else if (pathname === "/profile") {
        return (
            <div className="footer">
            <div className="container">
                <div className="footer-top ">
                <div className="google-btns">
                </div>
                <div className="copyright-text text-center">
                    <p>© 2024 <Link to="/profile">WeJe</Link> <Translate>All Rights Reserved</Translate></p>
                </div>
                </div>
            </div>
            <Chat handleClick={handleClick} open={open} handleIsQue={handleIsQue} handleStartTimer={handleStartTimer} />
            </div>
        );
    } else if (pathname === "/sports-tickets/open") {
        return (
            <div className="footer">
            </div>
        );
    } else {
        return (
            <div className="footer">
            <div className="container">
                <div className="footer-top ">
                <div className="google-btns">
                </div>
                <div className="copyright-text text-center">
                    <p>© 2024 <Link to="/profile">WeJe</Link> <Translate>All Rights Reserved</Translate></p>
                </div>
                </div>
            </div>
            </div>
        );
    }
};

export default Footer;
