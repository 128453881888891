import React, { Component } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import firebase from "../../firebase";
import { Redirect } from "react-router-dom";
import Lottie from "react-lottie";
import market from '../../assets/animation/market';
import panda from "../../assets/animation/SVG-panda.svg";
import { Translate } from "react-auto-translate";
import "./market.css";
import Bronze from '../../assets/images/avatar/avatarFrameV2-Bronze.png';
import Silver from '../../assets/images/avatar/avatarFrameV2-Silver.png';
import Platinum from '../../assets/images/avatar/avatarFrameV2-Platinum.png';
import Diamond from '../../assets/images/avatar/avatarFrameV2-Diamond.png';
import Layout from "../layout/layout";
import { MainContext } from "../../context";

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: market,
  };

export class PlaceMarket extends Component {
	static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      //User Modals show hide
      showAvatar: false,
      showNFT: false,
      redirect: "",
      //User personal data
      avatar: this.props.avatar,
      uid: this.props.uid,
      nick: this.props.nick,
      coins: this.props.coins,
      idToken: this.props.idToken,
      stats: {},
    };
  }

  async componentDidMount() {
	const { firebaseUser: user, baseStats, token} = this.context;
    let img = user.photoURL;
        if (
          img ===
          "https://storage.cloud.google.com/mycool-net-app.appspot.com/default-avatar/default-avatar_homePageLogo.png"
        ) {
          const hdd = firebase.storage();
          const gsRef = hdd.refFromURL(img);
           gsRef.getDownloadURL().then((image) => (img = image));
        }
        if (baseStats) {
          this.setState({
            idToken: token,
            pending: false,
            signedin: true,
            uid: user.uid,
            nick: user.displayName,
            avatar: img,
            lastSign: user.metadata.lastSignInTime,
            NvalidLogins: this.state.NvalidLogins + 1,
            stats: {
              Level: baseStats.Level,
              Hplayed_friends: baseStats.Hplayed_friends,
              wins: baseStats.total.win,
              losses: baseStats.total.loose,
              friends: baseStats.social.friends,
              followers: baseStats.social.followers,
              following: baseStats.social.following,
              games: baseStats.total.games,
              coins: baseStats.inGame
                ? "Calculating balance.."
                : baseStats.total.coins,
              winLoseRation: baseStats.total.wl_ratio,
            },
          });
        }
  }

  openAvatar() {
    this.setState({
      showAvatar: !this.state.showAvatar,
    });
  }

  openNFT() {
    this.setState({
      showNFT: !this.state.showNFT,
    });
  }


  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <Layout>
      <div className="marketplace-page" id="market">
        <div className="container">
          <div className="row">
    
            <div className="col-md-6 mb-4">
              <div
                className="card"
                onClick={() =>
                  this.setState({ redirect: "/marketplace/nft" })
                }>
                <div className="card-body">
                  <div className="profile-box d-flex flex-column align-items-center text-center">
                    <img
                      src={panda}
                      alt="imagetag"
                      className="rounded-circle"
                    />
                    <div className="profile-box">
                      <h6 className="font-size-sm">
                        <Translate>NFTs collection</Translate>
                      </h6>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div className="col-md-6 mb-4">
              <div className="card" onClick={() => this.openAvatar()}>
                <div className="card-body">
                  <div className="profile-box d-flex flex-column align-items-center text-center">
                    <img
                      src={`https://storage.googleapis.com/mycool-net-app.appspot.com/permanent-items/avatarFrameV2-Bronze.png`}
                      alt="imagetag"
                      className="rounded-circle"
                    />
                    <div className="profile-box">
                      <h6 className="font-size-sm">
                        <Translate>Avatar frame</Translate>
                      </h6>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div className="col-md-6 mb-4">
              <div className="card">
                <div className="card-body card-lock">
                  <i
                    className="fa fa-lock"
                    aria-hidden="true"
                    style={{ color: "rgb(178, 34, 34)" }}
                  ></i>
                  <div className="profile-box d-flex flex-column align-items-center text-center">
                    <div>
                      <Lottie
                        options={animationOptions}
                        height={200}
                        width={150}
                        alt="imagetag"
                        className="rounded-circle"
                      />
                    </div>
                    <div className="profile-box">
                      <h6 className="font-size-sm">
                        <Translate>Win Animation</Translate>
                      </h6>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div className="col-md-6 mb-4">
              <div className="card">
                <div className="card-body card-lock">
                  <i
                    className="fa fa-lock"
                    aria-hidden="true"
                    style={{ color: "rgb(178, 34, 34)" }}
                  ></i>
                  <div className="profile-box d-flex flex-column align-items-center text-center">
                    <div>
                      <Lottie
                        options={animationOptions}
                        height={200}
                        width={150}
                        alt="imagetag"
                        className="rounded-circle"
                      />
                    </div>
                    <div className="profile-box">
                      <h6 className="font-size-sm">
                        <Translate>Lose Defence</Translate>
                      </h6>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
          </div>
    
          <Modal
            show={this.state.showNFT}
            onHide={() => this.openNFT()}
            centered
            className="market-popup friends-popup" >
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate>NFTs collection</Translate>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-md-6 mb-4">
                <div className="card">
                  <div className="card-body">
                    <div className="profile-box d-flex flex-column align-items-center text-center">
                      <img
                        src={panda}
                        alt="panda"
                        className="nft-collection"
                      />
                      <div className="profile-box">
                        <br />
                      </div>
                      <div className="buy-btn">
                        <Button className="btn btn-gold item-locked _disabled" >
                          <i className="fa fa-lock"></i>
                          <Translate>Panda</Translate>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </Modal.Body>
          </Modal>
    
          <Modal
            show={this.state.showAvatar}
            onHide={() => this.openAvatar()}
            centered
            className="market-popup friends-popup purchase-popup" >
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate>Special Avatars</Translate>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="col-md-12">
                <div className="tablelist-content">
                  <div className="tablelist-table">
                    <Table
                      striped
                      bordered
                      hover
                      variant="dark"
                      responsive
                      className="market-table" >
                      <tbody>
                        <tr>
                          <th className="img-th">
                            <img
                              className="img-market"
                              src={this.state.avatar}
                              alt="user avatar"
                            />
                          </th>
                          <th className="market-th">
                            <Translate>Level-1</Translate>
                          </th>
                          <th className="market-th">
                            <Translate>Level-2</Translate>
                          </th>
                          <th className="market-th">
                            <Translate>Level-3</Translate>
                          </th>
                          <th className="market-th">
                            <Translate>Level-4</Translate>
                          </th>
                        </tr>
                        <tr>
                          <td className="">
                            <Translate>Avatar</Translate>
                          </td>
                          <td className="item-locked" >
                            <div className="show-item" >
                              <i className="fa fa-lock lock-level fa-2x"></i>
                              <img
                                className="img-market"
                                src={Bronze}
                                alt="bronza"
                              />
                            </div>
                          </td>
                          <td className="item-locked" >
                            <div className="show-item" >
                              <i className="fa fa-lock lock-level fa-2x"></i>
                              <img
                                className="img-market"
                                src={Silver}
                                alt="bronza"
                              />
                            </div>
                          </td>
                          <td className="item-locked" >
                            <div className="show-item" >
                              <i className="fa fa-lock lock-level fa-2x"></i>
                              <img
                                className="img-market"
                                src={Platinum}
                                alt="bronza"
                              />
                            </div>
                          </td>
                          <td className="item-locked" >
                            <div className="show-item" >
                              <i className="fa fa-lock lock-level fa-2x"></i>
                              <img
                                className="img-market"
                                src={Diamond}
                                alt="bronza"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Layout>    
    );
  }
}

export default PlaceMarket;
