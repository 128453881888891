import React, { useRef, useState, useEffect, useMemo } from "react";
import { Translate } from "react-auto-translate";
import "./video.css";

const VideoComponent = ({ videoType, onClose }) => {
    const videoRef = useRef(null);
    const [isVisible, setIsVisible] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);

    const getVideoSource = useMemo(() => {
        if (!videoType) return null;
        const language = localStorage.getItem("language") || "en";
        try {
            return require(`../../assets/videos/${language}/${videoType}.mp4`).default;
        } catch (error) {
            console.error("Error loading video:", error);
            setErrorLoading(true);
            return null;
        }
    }, [videoType]);

    useEffect(() => {
        if (videoType && videoRef.current && getVideoSource) {
            setIsLoading(true);
            videoRef.current.src = getVideoSource;
            videoRef.current.muted = false;
            videoRef.current.play().catch((error) => {
                console.error("Video playback failed:", error);
                setErrorLoading(true);
            });
        }
    }, [getVideoSource, videoType]);

    const handleClose = (event) => {
        event.stopPropagation();
        setIsVisible(false);
        onClose(); 
    };

    const toggleFullscreen = () => {
        if (!isFullscreen) {
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            } else if (videoRef.current.webkitRequestFullscreen) {
                videoRef.current.webkitRequestFullscreen();
            } else if (videoRef.current.msRequestFullscreen) {
                videoRef.current.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
        setIsFullscreen(!isFullscreen);
    };

    if (!isVisible || !videoType) return null;

    return (
        <div className={`register-bg-video ${!isLoading ? "show-border" : ""} ${isFullscreen ? "fullscreen" : ""}`}>
            {isLoading && (
                <div className="video-loader">
                    <Translate>Loading...</Translate>
                </div>
            )}
            {errorLoading ? (
                <div className="error-message">
                    <Translate>Try again shortly</Translate>
                </div>
            ) : (
                <video
                    ref={videoRef}
                    playsInline
                    autoPlay
                    loop
                    controls={false}
                    style={{ display: isLoading ? "none" : "block" }}
                    onLoadedData={() => setIsLoading(false)} // Set loading to false when video is loaded
                />
            )}
            <button className={`close-button ${!isLoading ? "show" : ""}`} onClick={handleClose}>
                &times;
            </button>
            <div className={`fullscreen-button ${!isLoading ? "show" : ""}`} onClick={toggleFullscreen}>
                {isFullscreen ? <i className="fa fa-compress" aria-hidden="true"></i> : <i className="fa fa-expand" aria-hidden="true"></i>}
                <span className="sr-only">{isFullscreen ? "Exit Fullscreen" : "Fullscreen"}</span>
            </div>
        </div>
    );
};

export default VideoComponent;
