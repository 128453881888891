import React from 'react';
import { Link,  } from 'react-router-dom';
import Lottie from "react-lottie";
import error404 from "../../assets/animation/404.json";
import { Translate } from "react-auto-translate";
import "./index.css";

const Lottie404 = {
    loop: true,
    autoplay: true,
    animationData: error404,
  };

const NotFoundPage = () => {
  return <div className="container">
        <div className="error-wrapper">
                <div>
                <Lottie options={Lottie404}  width={900} />
                </div>
                <div className="copyright-text text-center">
                    <p>
                    © 2024 <Link to="/">WeJe</Link> <Translate>All Rights Reserved</Translate>
                    </p>
                </div>
            </div>
        </div>;
}

export default NotFoundPage;

