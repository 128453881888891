import Card from "./Card";

const CardListing = ({ list, createBuyOrder, createMoralisBuyOrder }) => {
  return (
    <div className="nft-cards">
      <div className="row">
        {list && list.length
          ? list.map((item, i) => (
              <Card
                nftData={item}
                tokenId={item.identifier}
                key={item.identifier}
                createBuyOrder={createBuyOrder}
                createMoralisBuyOrder={createMoralisBuyOrder}
              />
            ))
          : ""}
      </div>
    </div>
  );
};

export default CardListing;
