import React from "react";
import { Translate } from "react-auto-translate";
import "./report.css";
import { Spinner, Button, Form } from "react-bootstrap";

const BlockUserPopUp = ({ nickname, handleBlock, handleClose,spinnerLoading }) => {
  return (
          <>
	            <Form onSubmit={handleBlock}>
                  <div className="block">
                    <small>
                      <Translate>Blocking</Translate>{" "}
                      <span style={{ color: "#8461dc" }} >
                        @{nickname.toUpperCase()}
                      </span>{" "}
                      <Translate>on</Translate> <span style={{ color: "#8461dc" }}>WeJe</span>{" "}
                      <Translate>
                        will completely blind that person within your profile and
                        theirs, they will not come up in your network. <br></br>{" "}
                        When a user is blocked you will be able to delete him
                        permanently.</Translate>
                    </small>
                    <br></br>
                    <Form.Label>
                    <Translate>Block</Translate>{" "}
                      <b style={{ color: "#8461dc" }}>
                        @{nickname.toUpperCase()}?
                      </b>
                    </Form.Label>
                    <br></br>
                    <Button
                      type="submit"
                      className="btn-dark"
                      disabled={spinnerLoading} >
                       {spinnerLoading ? <Spinner animation="border" /> : <Translate>Block</Translate> }
                    </Button>
                  </div>
            </Form>
	        </>
  );
};
export default BlockUserPopUp;
