import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from "react-bootstrap";
import Layout from "../layout/layout";
import Select from "react-select";
import toast from "react-hot-toast";
import { Redirect, Link } from "react-router-dom";
import Lottie from "react-lottie";
import { useWeb3React } from "@web3-react/core";
import firebase from "../../firebase";
import axios from "axios";
import { ethers } from "ethers";
import CryptoNOT from "../cryptoNOT/index";
import loadericon from "../../assets/animation/loader.webp";
import coin from "../../assets/images/header/coin.png";
import info from "../../assets/images/icons/info_30.png";
import walletIcon from "../../assets/images/popups/wallet-logo.png";
import UrlConfig from "../../utils/ApiConfig";


function BackgammonCreateTable() {

    const [gameType, setgameType] = useState("Open table");
    const BackgammonArray = [
            { value: "Invite friend", label: "Invite friend" },
            { value: "Open table", label: "Open table" },
    ];
    const playersArray = [
            { value: "Alex", label: "Alex", id: "hND3QIvC8LZ4W8APkhKDjsZiLrF2" },
            { value: "Adi", label: "Adi", id: "hND3QIvC8LZ4W8APkhKDjsZiLrF2" },
    ];
    const timeoutArray = [
            { value: "30", label: "30 second" },
            { value: "40", label: "40 second" },
    ];
    const [playerSelected, setplayerSelected] = useState([]);
    const [nickName, setnickName] = useState("");
    const [gameName, setgameName] = useState('');
    const [wallet_min, setwallet_min] = useState(100);
    const [multiplayer, setmultiplayer] = useState(false);
    const [errorMessage, seterrorMessage] = useState('');
    const [pickerSchedule, setpickerSchedule] = useState(false);
    const [selectDate, setselectDate] = useState("no");
    const [video_hide, setvideo_hide] = useState(false);
    const [audio_hide, setaudio_hide] = useState(false);
    const [is_Audio, setis_Audio] = useState(false);
    const [media, setmedia] = useState("no-media");
    const [public_game, setpublic_game] = useState(true);
    const [show_public, setshow_public] = useState(false);
    const [is_Video, setis_Video] = useState(false);
    const [loading, setloading] = useState(false);
    const [lobbyLink, setlobbyLink] = useState('');
    const [textlobbyLink, settextlobbyLink] = useState('');
    const [message, setmessage] = useState('');
    const [uid, setuid] = useState('');
    const [idToken, setidToken] = useState(localStorage.getItem("xtkn"));
    const { account, provider } = useWeb3React();
    const [deviceId] = useState(localStorage.getItem("deviceId"));
    const [balance, setBalance] = useState('');
    const [balanceInUSD, setBalanceInUSD] = useState(0);
    const [i_sound] = useState(localStorage.getItem("i_sound"));
    const [walletConnected, setwalletConnected] = useState(localStorage.getItem("isWalletConnected"));

    useEffect(() => {
        const getMaticUsdPrice = async(matic) => {
          const res = await axios.get(UrlConfig.maticUsdPriceUrl);
          if(res.data){
            const curentPrice = res.data.market_data.current_price.usd
            setBalanceInUSD(curentPrice * matic);
          }
        }
        const getBalance = async() => {
        if(account){
          const value = await provider?.getBalance(account);
            if(value)
            console.log("vale", value.toString());
            setBalance(Number(ethers.utils.formatEther(value)).toFixed(5));
            getMaticUsdPrice(Number(ethers.utils.formatEther(value)));
            setwalletConnected(true);
            setmessage("");
        } else {
            setwalletConnected(false);
            setmessage("Crypto wallet is not connected");
        }
      }
      getBalance()
    }, [account, provider])

    useEffect(() => {
        const checkIsLoggedIn = async () => {
          firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
              const us = firebase.auth().currentUser;
              const token = await firebase.auth().currentUser.getIdToken(true);
              setuid(us.uid);
              setidToken(token);
              setnickName(us.displayName);
            }
          });
        };
        if (localStorage.getItem("xtkn") && localStorage.getItem("deviceId"))
          checkIsLoggedIn();
      }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validate();
        if (isValid) {
            console.log("uid: " + uid);
            console.log("idToken: " + idToken);
            console.log("nickName: " + nickName);
            console.log("xtkn: " + localStorage.getItem("xtkn"));
            console.log("device: " + deviceId);
            console.log("account: " + account);
            console.log("provider: " + provider);
            console.log("balance: " + balance);
            console.log("balanceInUSD: " + balanceInUSD);
            console.log("i_sound: " + i_sound);
            console.log("gameName: " + gameName);
            console.log("public: " + public_game);
            console.log("adminStart: " + false);
            console.log("media: " + media);
            console.log("is_Audio: " + is_Audio);
            console.log("is_Video: " + is_Video);
            alert("api call");
        }
    };

    function validate() {
        let error = "";
        if ( gameType === "Invite friend" && playerSelected.length < 1 ) { 
            error = "Please select a player"
        } if (balance === "0.00000") {
            error = "MATIC tokens on Polygon network is not enough";
            toast.error("MATIC tokens on Polygon network is not enough", {
                id: "toast-error",
                duration: 6000,
                style: {
        		maxWidth: 800,
  				fontSize: "14px",
  				borderRadius: "15px",
           		},
              });
        }
        if (error) {
            setmessage(error);
          return false;
        } else {
          return true;
        }
    };

  const handleChange = (event) => {
    if (event.value === 'Invite friend') {
        setgameType('Invite friend');
        setpublic_game(true);
        setshow_public(true);
        setmultiplayer(true);
    } if (event.value === 'Open table') {
        setgameType('Open table');
        setpublic_game(true);
        setshow_public(false);
        setmultiplayer(false);
    }
  };

  function handleGameName(e) {
    const re = /^[a-zA-Z0-9_]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
        setgameName(e.target.value);
    }
  }

  function hendleTimeSelect(event) {
    alert(event);
  }

  function updatesMinBet(event) {
    setwallet_min(event.target.value);
    alert(event.target.value);
  }

  function toggleAudio (e) {
    if (e.target.checked) {
      this.setState({
        media: "audio",
        media_video_hide: true,
      });
      toast.success(
        <div className="wallet-image">
          Game with Voice.
        </div>,
        {
          id: "toast-info",
          duration: 4000,
          style: {
        	maxWidth: 800,
  		    fontSize: "14px",
  		    borderRadius: "15px",
           },
        }
      );
    } else {
      this.setState({
        media: "no-media",
        media_video_hide: false,
      });
    }
  };

  function toggleVideo(e) {
    if (e.target.checked) {
        setis_Video(e.target.checked);
        setmedia("video");
        setaudio_hide(true);
        toast.success (
            <div className="wallet-image">
              Game with Voice
            </div>,
            {
              id: "toast-info",
              duration: 4000,
              style: {
        	    maxWidth: 800,
  		        fontSize: "14px",
  		        borderRadius: "15px",
                },
            }
          );
    } else {
        setmedia("no-media");
        setaudio_hide(false);
      }
  };

  function hendleplayerSelect(event) {
    setplayerSelected(event);
  }
  
  const customStyles = {
    option: (provided) => ({
      ...provided,
      borderBottom: "1px solid #2d2d32",
      color: "#ddd",
      backgroundColor: "#191b25",
    }),
    control: () => ({
      border: "1px solid #51525f",
      borderRadius: ".25rem",
      display: "flex",
      padding: "2px 10px",
      backgroundColor: "transparent",
      color: "#fff",
    }),

    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: "#8461dc",
        color: "#fff",
      },
    }),
  };

  return (
    <>
    {!walletConnected ? (
		<CryptoNOT />
	) : (
 <Layout>
        <div className="bet-weje">
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <div className="bet-weje-content">
                  <h2 className="backgammon-title">
                    Backgammon <small>{gameType}</small>
                  </h2>
                        <img
                          src=""
                          alt="Backgammon"
                          className="Backgammon-game"
                        />
                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicFirst">
                          <Form.Label>Select a Game</Form.Label>
                          <Select
                            name="game"
                            options={BackgammonArray}
                            styles={customStyles}
                            onChange={handleChange}
                            defaultValue={{
                              value: "Open table",
                              label: "Open table",
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicFirst">
                          <Form.Label>Game Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter game name"
                            name="name"
                            autoComplete="off"
                            value={gameName}
                            onChange={handleGameName}
                            pattern="^[a-zA-Z0-9_]+$"
                            required
                          />
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        {gameType === "Invite friend" ? (
                          <Form.Group controlId="formBasicFirst">
                            <Select
                              id="SelectPlayer"
                              name="player"
                              options={playersArray}
                              styles={customStyles}
                              closeMenuOnSelect={true}
                              onChange={hendleplayerSelect}
                            />
                          </Form.Group>
                        ) : (
                          <Form.Group controlId="formBasicFirst">
                            <Form.Label>
                              <label>
                                <small>Wait for a player to join</small>{" "}
                              </label>
                            </Form.Label>
                          </Form.Group>
                        )}
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicFirst">
                          <Form.Label>Game Round Timeout</Form.Label>
                          <Select
                            name="player"
                            options={timeoutArray}
                            styles={customStyles}
                            onChange={hendleTimeSelect}
                            defaultValue={{ value: "30", label: "30 second" }}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group
                          controlId="formBasicFirst"
                          className="coin-input" >
                          <Form.Label>Polygon <small>MATIC</small></Form.Label>
                          <div className="tooltipHover">
                            <img src={walletIcon} alt="info" className="iconInfo" />
                            <small className="tooltiptext matic-text">
                                MATIC Balance
                            </small>
                          </div>
                          <div className="your-wallet">
                                <img src={walletIcon} alt="info" className="PolygonCoins" />
                            <p className="your-wallet-p">
                              {balance}   
                            </p>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-sm-5 coins">
                        <Form.Group
                          controlId="formBasicFirst"
                          className="coin-input" >
                          <img src={coin} alt="coin" />
                          <Form.Label>Polygon <small>BET</small></Form.Label>
                          <div className="tooltipHover">
                                <img src={walletIcon} alt="info" className="iconInfo" />
                            <small className="tooltiptext">
                                 MATIC tokens in each round
                            </small>
                          </div>
                          <Form.Control
                            type="number"
                            min="100"
                            placeholder="Enter Coins"
                            name="betMin"
                            className="betMin"
                            autoComplete="off"
                            value={wallet_min}
                            onChange={updatesMinBet}
                            onSelect={updatesMinBet}
                            required
                          />
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <div className="invite-radio">
                          <div className="filter-input radio">
                            <input
                              type="radio"
                              id="invitation"
                              name="invitation"
                              value="null"
                              defaultChecked
                            />
                            <label className="radio-label">
                              Sent invitation now
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="invite-radio">
                          {!audio_hide ? (
                            <>
                              <div className="filter-input checkbox">
                                <input
                                  type="checkbox"
                                  id="audio"
                                  name="media"
                                  onChange={toggleAudio}
                                />
                                <div className="tooltipHover">
                                  <img
                                    src={info}
                                    alt="info"
                                    className="iconInfo"
                                  />
                                  <small className="tooltiptext">
                                    Advanced voice interaction{" "}
                                  </small>
                                </div>
                                <label className="radio-label">
                                    Voice &nbsp;
                                </label>
                                <i
                                  style={{ color: "#8461dc" }}
                                  className="fa fa-headphones came"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </>
                          ) : null}

                          {!video_hide ? (
                            <>
                              <div className="filter-input checkbox">
                                <input
                                  type="checkbox"
                                  id="video"
                                  name="media"
                                  onChange={toggleVideo}
                                />
                                <div className="tooltipHover">
                                  <img
                                    src={info}
                                    alt="info"
                                    className="iconInfo"
                                  />
                                  <small className="tooltiptext">
                                    Video Live streaming{" "}
                                  </small>
                                </div>
                                <label className="radio-label">
                                  Video &nbsp;
                                </label>
                                <i
                                  style={{ color: "#8461dc" }}
                                  className="fa fa-video-camera came"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-12">
                        {loading ? (
                          <div id="cover-spin">
                            <img
                              src={loadericon}
                              alt="loader"
                              id="imageLoader"
                            />
                            <p>
                              <small>Creating your Table..</small>
                            </p>
                          </div>
                        ) : null}
                        <div className="errorBoxMessage">
                          <p className="Error">{message}</p>
                          <a
                            style={{ color: "#8461dc" }}
                            href={lobbyLink} >
                                {textlobbyLink}
                          </a>
                        </div>

                        <div className="login-button">
                          <Link to={"/profile"}>
                            <Button className="btn-dark">Cancel</Button>
                          </Link>
                          <Button
                            type="submit"
                            className="l-btn btn-gold btn-play"
                            disabled={loading} >
                            {loading ? (
                              <Spinner animation="border" />
                            ) : (
                              "Play"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )}
</>
  );
};

export default BackgammonCreateTable;